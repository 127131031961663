import axios from "axios";

const cancelBatchRegistration = async (id, note) => {
    console.log("DEBUG/cancelBatchRegistration:")
    console.log(id)

    return new Promise((resolve, reject) => {
        axios.put
            (process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ADMIN_PLANTS_BATCH_CANCEL + id,
                {
                    status: 'unlisted',
                    notes: note,
                },
                {
                    headers: {
                        "Authorization": "Bearer " + process.env.REACT_APP_API_SEC_BEARER,
                        "Content-Type": "application/json",
                    }
                },
            )
            .then(response => {
                console.log("cancelBatchRegistration.response:")
                console.log(response)
                resolve(response)
            })
            .catch(error => {
                console.log("cancelBatchRegistration.error:")
                console.log(error)
                resolve(error)
            })
    })
}

export default cancelBatchRegistration