import React from 'react'

const NotificationError = ({setMessage, message}) => {

    return (
        <div className="notification is-danger" style={!message ? { display: 'none' } : { display: 'block' }}>
            <button className="delete" onClick={() => setMessage(null)}></button>
            Error: {message}
        </div>
    )
}
export default NotificationError