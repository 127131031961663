import axios from "axios";

const ToolRetrieve = async () => {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_TOOLS, {
            headers: {
                'Authorization': 'Bearer 4b58c6b1d51f0b45c5426d250324a6c49bb81c5e893b877ebe917f8bf5098a17'
            }
        }).then(async res => {
            resolve(res.data.data)
        }).catch(error =>{
            reject(error)
        })
    })
}

export default ToolRetrieve