import React, { useState, useEffect } from 'react'
import checkBottle from '../../services/plants/bottles/getBottleByIdentifierUpdate'

function StepReviewPage(props) {

    const [explants, setExplants] = useState()
    const [newExplants, setNewExplants] = useState()
    const [loading, toggleLoading] = useState(true)

    const totalDormantReport = [...props.totalReportedDormant];
    const currentDormantReport = props.numberDormantReported;

    const totalCallusReport = [...props.totalReportedCallus];
    const currentCallustReport = props.numberCallusReported;

    const currentShoot = props.state.shoots[0];


    useEffect(() => {
        const add = (accumulator, a) => {
            return accumulator + a;
        }

        const compileReports = () => {

            if (currentDormantReport) {
                totalDormantReport.push(currentDormantReport)
                props.setNumberDormantReported(null)
                props.setTotalReportedDormant(totalDormantReport)
            }
            if (currentCallustReport) {
                totalCallusReport.push(parseInt(currentCallustReport))
                props.setNumberCallusReported(null)
                props.setTotalReportedCallus(totalCallusReport)
                const sumOfReport = totalCallusReport.reduce(add, 0);
                props.setTotalReportedCallusCount(sumOfReport)

            }
        }
        console.log('total callus', props.totalReportedCallusCount)
        compileReports()
        // eslint-disable-next-line  
    }, [props])


    useEffect(() => {

        const getNewBatch = async () => {
            let bottleScan = await checkBottle(props.state.shoots[0].plant_bottle_code)
            if (bottleScan?.data?.error_code === 0) {
                setNewExplants(bottleScan?.data?.data)
                toggleLoading(false)
            }
        }
        getNewBatch();
        // eslint-disable-next-line  
    }, [props])

    useEffect(() => {
        setExplants(props.state.shoots)
    }, [props])

    return (
        <div className="content container">

            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h1 className="title has-text-centered">Update Plant Stage</h1>

            <div className="is-flex-direction-column">
                {!loading ?
                    <div>
                        {(props.state.transferCollection || props.state.transferRootCollection) &&
                            <div>
                                <h4 className="title is-4">The following ex plant/s will be updated to {newExplants[0].pci_s !== 'R' ? `S${newExplants[0].pci_s}` : newExplants[0].pci_s}</h4>
                                <div className="is-flex">
                                    <div>
                                        {
                                            explants.map((plant) => {
                                                return (
                                                    <div key={plant.id} className="is-flex-direction-column">
                                                        <div>
                                                            <p>
                                                                <span>{plant.pci} ➝
                                                    </span> <br></br>
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div>
                                        {
                                            newExplants.map((newPlant) => {
                                                return (
                                                    <div key={newPlant.id} className="is-flex-direction-column">
                                                        <div>
                                                            <p>
                                                                <span>
                                                                    {newPlant.pci}
                                                                </span> <br></br>
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        {totalDormantReport.length > 0 &&
                            <div style={{ marginTop: '1rem' }}>
                                <span>The following ex plant/s will be reported as dormant</span><br />
                                <span>{currentShoot.pci}</span>
                            </div>
                        }

                        {props.totalReportedCallusCount > 0 ?
                            <div style={{ marginTop: '1rem' }}>
                                {`${props.totalReportedCallusCount} callus reported`}
                            </div>
                            :
                            <div style={{ marginTop: '1rem' }}>No Callus Reported</div>
                        }
                    </div>
                    :
                    <div>Loading..</div>
                }
            </div>
            <div className="buttons">
                <button
                    onClick={() => {
                        props.jump(1)
                    }
                    }
                    className="button is-info is-light is-large"
                >Update More Ex Plants</button>
                <button
                    onClick={() => {
                        props.next()
                    }
                    }
                    className="button is-info is-light is-large"
                >Go To Inoculation</button>
                <button className="button is-info is-light is-large"
                    onClick={props.prev}
                >Go Back</button>
            </div>
        </div>
    )
}

export default StepReviewPage;