import React from 'react'
import LabsManualConfirmationPage from '../../components/Labs/LabsManualConfirmationPage'
import Button from '../../components/Button/Button'


const StepManualFinalConfirmation = (props) => {

    const finalData = props.finalData

    return (
        <div className="container">
            <LabsManualConfirmationPage finalData={finalData} successMessage={'Ex Plants has successfully beed added to the barcode system'} />
            <p>&nbsp;</p>
            <div className="buttons">
                <Button
                    onClick={() => props.jump(1)}
                    label={'Register More Plants'}
                    className={'button is-primary'}
                />
                <Button
                    onClick={props.prev}
                    label={'Back'}
                    className={'button is-primary'}
                />
            </div>
        </div>
    )
}

export default StepManualFinalConfirmation
