import React from 'react'
// import { Link } from "react-router-dom";

const LabsReportContamination = (props) => {

    props.validateSession()

    return (
        <div className="container">
        
            <p>&nbsp;</p>
            <h1 className="title">Rescure Plants From Fungus</h1>

            <nav className="level">
                {/* <div className="level-right">
                    <p className="level-item">
                        <Link to={{
                            pathname: '/farms/create'
                        }} className="button is-success">Upload CSV</Link>
                    </p>

                    <p className="level-item"><strong>All</strong></p>
                </div> */}

                <div className="level-left">
                    <div className="level-item">
                        <p className="subtitle is-5">
                            <strong>x</strong> records
                        </p>
                    </div>
                    <div className="level-item">
                        <div className="field has-addons">
                            <p className="control">
                                <input className="input" type="text" placeholder="Find a user" />
                            </p>
                            <p className="control">
                                <button className="button">
                                    Search
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </nav>


            <div className='list'>
                <ul>
                    <li>List</li>
                </ul>
            </div>
        
        
        </div>
    )
}

export default LabsReportContamination
