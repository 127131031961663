// FinalStep.js
import React from "react";

function StepProtocolConfirmation(props) {
    console.log("props: ", props)
    return (
        <div className="content container">
            <p>&nbsp;</p>
            <p>&nbsp;</p>

            <div className="columns is-centered">
                <h1 className="title">{props.state.protocol}</h1>
                <br/>
            </div>
            <div className="columns is-centered">
                {props.state.protocol === "Standard Protocol" ?
                    <ul>
                        <li>RINSE PURIFIED WATER TO REMOVE FOREIGN MATTER</li>
                        <li>SOAK IN LIQUID SOAP WITH 2-3 DROPS OF TWEEN 20. SHAKE TIME 10 MIN</li>
                        <li>RINSE RUNNING TAP WATER 5MIN UNTIL BUBBLES DISAPPEAR</li>
                        <li>RINSE STERILE PURIFIED WATER 2-3 TIMES</li>
                        <li>SOAK IN 40% SOLUTION, 2-3 DROPS OF TWEEN 20, SHAKE 10MIN</li>
                        <li>RINSE WITH STERILE PURIFIED WATER 4-5 TIMES UNTIL BUBBLES DISAPPEAR</li>
                    </ul> :
                    <ul>
                        <li>RINSE PURIFIED WATER TO REMOVE FOREIGN MATTER</li>
                        <li>SOAK IN LIQUID SOAP WITH 2-3 DROPS OF TWEEN 20. SHAKE TIME 10 MIN</li>
                        <li>RINSE RUNNING TAP WATER 5MIN UNTIL BUBBLES DISAPPEAR</li>
                        <li>RINSE STERILE PURIFIED WATER 2-3 TIMES</li>
                        <li>SOAK IN 60% SOLUTION, 2-3 DROPS OF TWEEN 20, SHAKE 10MIN</li>
                        <li>RINSE WITH STERILE PURIFIED WATER 4-5TIMES UNTIL BUBBLES DISAPPEAR</li>
                    </ul>
                }
            </div>
            <div className="buttons">
                <button className="button is-info is-light is-large" onClick={props.prev}>Previous</button>
                <button className="button is-info is-light is-large" onClick={props.next}>Confirm</button>
            </div>

        </div>
    );
}

export default StepProtocolConfirmation;