import React from 'react'
import LabsManualTableBody from './LabsManualTableBody'
import LabsRawTableBody from './LabsRawTableBody'
import LabsPlantProductionTableBody from './LabsPlantProductionTableBody'
import LabsPlantProductionAverageTableBody from './LabsPlantProductionAverageTableBody'
import LabsPlantProductionEstimateTableBody from './LabsPlantProductionEstimateTableBody'
import LabsStatisticsRecoveryTableBody from './LabsStatisticsRecoveryTableBody'

const LabsTableView = ({
    type,
    displayText,
    loading,
    headers,
    data,
    next,
    setSelectedData }) => {

    return (
        <div className="table-container">
            <table className="table">
                <thead>
                    <tr>
                        {headers.map((label) => {
                            return (
                                <th key={label}>{label}</th>
                            )
                        })}
                    </tr>
                </thead>
                {
                    type === 'manual' && <LabsManualTableBody setSelectedData={setSelectedData} next={next} data={data} displayText={displayText} type={type} />
                }
                {
                    type === 'raw' && <LabsRawTableBody data={data} displayText={displayText} type={type} />
                }
                {
                    type === 'plant-production' && <LabsPlantProductionTableBody loading={loading} data={data} displayText={displayText} type={type} />
                }
                {
                    type === 'plant-production-average' && <LabsPlantProductionAverageTableBody loading={loading} data={data} displayText={displayText} type={type} />
                }
                {
                    type === 'plant-production-estimate' && <LabsPlantProductionEstimateTableBody loading={loading} data={data} displayText={displayText} type={type} />
                }
                {
                    type === 'recovery' && <LabsStatisticsRecoveryTableBody />
                }
            </table>
        </div>
    )
}

export default LabsTableView
