// FinalStep.js
import React from "react";
import { Link } from "react-router-dom";
import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"

const StepFinalConfirmation = (props) => {
    console.log(props)
    return (
        <div className="container">

            <p>&nbsp;</p>
            <NotificationSuccess message= {!(props.state.transferCollection || props.state.transferRootCollection) ? "Successfully created batch record" : "Successfully upated batch record"}/>
            <h1 className="title">Thanks for confirming</h1>
            <h1 className="subtitle">Please proceed to the inoculation room</h1>
            <h2 className="subtitle">After successful inoculation, please log in again before transferring to culture room</h2>


            <div className="buttons">
                <Link to="/labs/dashboard" className="button is-success">Back to Labs</Link>
            </div>

        </div>
    );
}

export default StepFinalConfirmation;