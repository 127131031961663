import React from 'react'
import { Link } from "react-router-dom";

const LabsConfirmationPage = ({ data }) => {

    return (
        <div className="container">
            {data.map((line, i) => {
                return (
                    <h3 key={i} className="subtitle has-text-centered">{line}</h3>
                )
            })}
            <div className="buttons">
                <Link to="/labs/dashboard" className="button is-info is-light is-large">Back to Labs</Link>
            </div>
        </div>
    )
}

export default LabsConfirmationPage
