import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import getSchedulerByTemplate from "../../services/activities/scheduler/getSchedulerByTemplate"
import deleteTemplate from "../../services/activities/templates/deleteTemplate"
import './ActivityScheduler.scss'

import NotificationError from "../../components/NotificationError/NotificationError"
import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"

const ActivityScheduler = (props) => {

    console.log("props:")
    console.log(props)

    const [apiResponse, setApiResponse] = useState("");
    // const [loadingState, setLoadingState] = useState(true);
    const [results, setResults] = useState([])
    const [loadedData, setLoadedData] = useState(false)

    useEffect(() => {
        const getSchedulerList = async () => {
            let result = await getSchedulerByTemplate(props.location.state.templateType)
            console.log("DEBUG/RESULT:", result)
            setResults(result)
            setLoadedData(true)
        }

        getSchedulerList()
    }, [props.location.state.templateType]);

    const HandleResponse = (res) => {

        console.log("HandleResponse.res:")
        console.log(res)
        console.log(res.status)

        if (res.status === 200) {
            setApiResponse("success")
        } else {
            setApiResponse("error")
        }

    }

    const updateDeletedResult = (id) => {
        setResults(results.filter(result => result.id !== id));
    }

    // const handleDelete = (e) => {
    //     e.preventDefault();

    //     console.log("DEBUG/e: ", e)

    //     // processBackend()

    // }

    // const processBackend = async () => {
    //     let request = {
    //         id: props.location.state.id,
    //         templateType: templateType,
    //         activityType: activityType,
    //         name: name,
    //         description: description,
    //         sequence: sequence,
    //         schedule: schedule,
    //         timeStart: timeStart,
    //         timeDuration: timeDuration,
    //         initial: initial,
    //     }
        
    //     console.log("DEBUG/request: ", request)

    //     try {
    //         let res = await putTemplate(request)
    //         HandleResponse(res)
    //     } catch (error) {
    //         HandleResponse(error);
    //     }
    // }

    return (
        <div className="container">

            <p>&nbsp;</p>

            <h1 className="title">Template Scheduled List for {props.location.state.templateType}</h1>
            {/* <h2 className="subtitle">View activity by user</h2> */}

            <nav className="level">
                <div className="level-right">
                    <Link to="/activities/templates/create" className="button is-success">Create New Template</Link>
                </div>

                <div className="level-left">
                    <div className="level-item">
                        <p className="subtitle is-5">
                            <strong>123</strong> records
                        </p>
                    </div>
                    <div className="level-item">
                        <div className="field has-addons">
                            <p className="control">
                                <input className="input" type="text" placeholder="Find a activity" />
                            </p>
                            <p className="control">
                                <button className="button">
                                    Search
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </nav>

            <div className='list'>

                {apiResponse === "success" && <NotificationSuccess setMessage={setApiResponse} message= "Successfully processed your request"/>}
                {apiResponse === "error" && <NotificationError setMessage={setApiResponse} message="There was an error processing your request." />}

                <ul>
                    {loadedData === true ?
                        results.map((result, index) => {
                            return (
                                <div className='list-item' key={index}>
                                    <div className="box">
                                        <article className="media">
                                            <div className="media-left">
                                                <div className="buttons">
                                                    <Link to={{
                                                        pathname: '/activities/scheduler/edit', state: {
                                                            id: result.id,
                                                            templateType: result.template_type,
                                                            activityType: result.activity_type,
                                                            name: result.name,
                                                            description: result.description,
                                                            sequence: result.sequence,
                                                            schedule: result.schedule,
                                                            timeStart: result.time_start,
                                                            timeDuration: result.time_duration,
                                                            initial: result.initial,
                                                        }
                                                    }} className="button is-link">Edit</Link>
                                                    <button
                                                    onClick={async () =>
                                                        await deleteTemplate(result.id).then(res => {
                                                            HandleResponse(res)
                                                            updateDeletedResult(result.id);
                                                        }).catch(err => {
                                                            HandleResponse(err);
                                                        })
                                                    }
                                                    type="button"
                                                    className="button is-danger is-light">Delete</button>
                                                </div>
                                            </div>
                                            <div className="media-content">
                                                <div className="content">
                                                    <h3>{result.name}</h3> {result.description}<br />
                                                    Sequence: {result.sequence}<br />
                                                    Schedule: {result.schedule}<br />
                                                    Time Start: {result.time_start}<br />
                                                    Time Duration: {result.time_duration}<br />
                                                    Initial: {result.initial}

                                                </div>
                                                <nav className="level is-mobile">
                                                    <div className="level-left">
                                                    </div>
                                                </nav>
                                            </div>
                                        </article>
                                    </div>

                                </div>
                            )
                        }) : <div className="sections"><div className="section-header">Loading..</div></div>}
                </ul>
            </div>



        </div>
    )
}

export default ActivityScheduler
