import React, { useEffect, useState } from 'react'
import Select from 'react-select'

const SearchComponent = ({
    userOptions,
    motherOptions,
    value,
    setEditData,
    editData,
    editField
}) => {

    const [selectOptions, setSelectOptions] = useState()
    const [userSelectOptions, setUserSelectOptions] = useState()
    const [defaultValue, setDefaultValue] = useState()
    const [defaultUserValue, setDefaultUserValue] = useState()

    useEffect(() => {
        if (selectOptions) {
            let selected = selectOptions.find((el) =>
                el.label === value
            )
            let defaultVal = {
                'value': '',
                'label': ''
            }
            selected ? setDefaultValue(selected) : setDefaultValue(defaultVal)
            console.log('selected value ', selected)
        }
        else if (userSelectOptions) {
            let selected = userSelectOptions.find((el) =>
                el.label === value
            )
            let defaultVal = {
                'value': '',
                'label': ''
            }
            selected ? setDefaultUserValue(selected) : setDefaultUserValue(defaultVal)
            console.log('selected user value ', selected)
        }
    }, [selectOptions, userSelectOptions, value])

    useEffect(() => {
        if (motherOptions) {
            var compileMothers = []
            motherOptions.forEach(element => {
                compileMothers.push({
                    'value': element.id,
                    'label': element.unique_code
                })
            });
            setSelectOptions(compileMothers)
        }
        else if (userOptions) {

            var compileUsers = []
            userOptions.forEach(element => {
                compileUsers.push({
                    'value': element.id,
                    'label': element.first_name + ' ' + element.last_name
                })
            });
            setUserSelectOptions(compileUsers)
            // setSelectOptions(compileMothers)
        }
        // console.log(compileMothers)
    }, [motherOptions, userOptions])

    const updateData = async (e) => {
        let value = e.value
        let updatedData = editData
        updatedData[`${editField}`] = value
        updatedData[`${editField}_name`] = e.label
        setEditData(updatedData)
    }

    return (
        <div
        // className={formClassName}
        >
            {defaultValue &&
                <Select
                    defaultValue={defaultValue}
                    // isMulti
                    onChange={(e) => {
                        updateData(e)
                    }}
                    options={selectOptions}
                />
            }

            {defaultUserValue &&
                <Select
                    defaultValue={defaultUserValue}
                    // isMulti
                    onChange={(e) => {
                        updateData(e)
                    }}
                    options={userSelectOptions}
                />
            }
        </div>
    )
}

export default SearchComponent
