import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { useJwt } from "react-jwt";
import { Cookies } from 'react-cookie';

import auditTrail from "../../services/auditTrail ";
import checkBottle from '../../services/plants/bottles/getBottleByIdentifierUpdate'
import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"
import NotificationError from "../../components/NotificationError/NotificationError"
import ModalComponent from "../../components/ModalComponent/ModalComponent"

function StepBottleScan(props) {

    const cookies = new Cookies();
    const authToken = cookies.get('authSession')
    const signed_user_hash = useJwt(authToken).decodedToken?.data?.hash

    useEffect(() => {
        if (props.passedBarcode) {
            setCode(props.passedBarcode)
        }

    }, [props])

    useEffect(() => {
        if (signed_user_hash)
            props.setState('userHash', signed_user_hash)
        // eslint-disable-next-line  
    }, [signed_user_hash])
    const handleAuditTrail = async (shoots) => {

        props.setShootsData(JSON.stringify(shoots));
        // localStorage.setItem(`${signed_user_hash}-shootsData`, JSON.stringify(props.state.shoots));
        let auditData = {
            type: "plant_registration",
            user_hash: signed_user_hash,
            activity: "Started: Update Plant Stage Step 1-2",
            data: JSON.stringify({})
        }
        await auditTrail(auditData).then(() => {
            props.next()
        })
    }

    const [code, setCode] = useState(props.state?.update_code ? props.state.update_code : '')
    const [successfulScan, setSuccessfulScan] = useState()
    const [apiResponse, setApiResponse] = useState('')

    const [reScan, toggleRescan] = useState(false)
    const [failedScan, setFailedScan] = useState(false)
    const loadingText = 'Loading..';
    const [loading, isLoading] = useState(false)

    useEffect(() => {
        window.addEventListener('keydown', e => {
            if (document.getElementById('scanResultArea')) {
                document.getElementById('scanResultArea').style.display = 'block'
            }
            if (document.getElementById('barcode')) {
                document.getElementById('barcode').setAttribute('type', 'text')
                document.getElementById('barcode').focus()
            }
        })
    }, [])

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (code) {
                isLoading(true);
                let bottleScan = await checkBottle(code)
                isLoading(false);
                if (bottleScan?.data?.error_code === 0) {
                    if (props.shootsData) {
                        let prevShootsData = JSON.parse(props.shootsData)
                        if (prevShootsData[0].pci_m !== bottleScan?.data?.data[0].pci_m || prevShootsData[0].pci_b !== bottleScan?.data?.data[0].pci_b) {
                            setApiResponse('The Ex Plant in this bottle is not from the same batch or mother plant source and cannot be trasferred together. Please transfer these plants at another time!')
                            setSuccessfulScan(false)
                        }
                        else {
                            props.setState('update_code', code)
                            setApiResponse('Barcode found!')
                            props.setState("shoots", bottleScan?.data?.data);
                            handleAuditTrail(bottleScan?.data?.data)
                            setSuccessfulScan(true)
                        }
                    }
                    else {
                        props.setState('update_code', code)
                        setApiResponse('Barcode found!')
                        setSuccessfulScan(true)
                        props.setState("shoots", bottleScan?.data?.data);
                        handleAuditTrail(bottleScan?.data?.data)
                    }

                }
                else {
                    setApiResponse('Barcode has no transfer stage yet')
                    setFailedScan(true)
                    setSuccessfulScan(false)
                }
            }
        }, 500)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line  
    }, [code])

    const divStyle = {
        display: 'none'
    };
    return (
        <div className="content container">

            <p>&nbsp;</p>
            <p>&nbsp;</p>
            {loading && <ModalComponent display={loadingText}></ModalComponent>}
            {apiResponse && successfulScan ? <NotificationSuccess setMessage={setApiResponse} message={apiResponse} /> : <NotificationError setMessage={setApiResponse} message={apiResponse && `${apiResponse} [${code}]`} />}
            <h1 className="title has-text-centered">Update Plant Stage</h1>

            {
                failedScan &&

                <div className="columns is-centered">
                    <button className="button is-info is-large"
                        onClick={() => {
                            setFailedScan(!failedScan)
                            toggleRescan(!reScan)
                            setCode('')
                            setApiResponse(null)
                            document.getElementById('barcode').focus()
                        }
                        }
                    // disabled={stepDone}
                    >Scan Again</button>
                </div>
            }
            <h4 className="title is-4">Scan next bottle for transfer update</h4>
            <div id="scanResultArea" style={divStyle}>
                <p>Scanned Code:
                <input
                        autoFocus
                        value={code}
                        id="barcode"
                        onChange={(e) => setCode(e.target.value)}
                        className="input"
                        type="hidden"
                    // disabled={stepDone}
                    ></input></p>
                <div>
                    <Link to="/labs/dashboard" className="button is-info is-light is-large">Go Back</Link>
                    {/* <button className="button is-info is-light"
                        onClick={() => {
                            !props.state.shootsData ? handleAuditTrail() : props.next();
                        }}
                        disabled={!successfulScan}
                    >Next</button> */}
                </div>
            </div>
        </div>
    )
}

export default StepBottleScan;