import axios from "axios";

const updateActivity = async (req, activityHash) => {
    console.log("DEBUG/updateActivity:")
    console.log(req)

    return new Promise((resolve, reject) => {
        axios.put(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ACTIVITIES_LIST + '/' + activityHash,
                {
                    user_hash: req.userHash,
                    farm_id: req.farmId,
                    type: req.activityType,
                    title: req.title,
                    description: req.description,
                    start_datetime: req.start_datetime,
                    end_datetime: req.end_datetime,
                    all_day: req.allDay,
                    notes: req.notes
                },
                {
                    headers: {
                        "Authorization": "Bearer " + process.env.REACT_APP_API_SEC_BEARER,
                        "Content-Type": "application/json",
                    }
                },
            )
            .then(response => {
                console.log("updateActivity.response:")
                console.log(response)
                resolve(response)
            })
            .catch(error => {
                console.log("updateActivity.error:")
                console.log(error)

                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    console.log('Error', error.message);
                }

                reject(error.message)
            })
    })
}

export default updateActivity