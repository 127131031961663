import React from 'react'
import InputField from '../InputField/InputField'
import SearchComponent from '../SearchComponent/SearchComponent'

const LabsEditForm = ({
    motherOptions,
    userOptions,
    editData,
    setEditData }) => {

    const initalEditData = editData


    const inputWidth = {
        width: '5%'
    };

    return (
        <div>
            <div className="field is-horizontal">
                <div className="field-body">
                    <div className="field">
                        <label className="label">New Batch Number</label>
                        <InputField
                            placeholder={'New Batch Number'}
                            value={editData.new_batch_no}
                            className="control is-expanded"
                            setEditData={setEditData}
                            editData={initalEditData}
                            editField={'new_batch_no'}
                        />
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-body">
                    <div className="field">
                        <label className="label">Old Batch Number</label>
                        <InputField
                            placeholder={'Old Batch Number'}
                            value={editData.old_batch_no}
                            className="control is-expanded"
                            setEditData={setEditData}
                            editData={initalEditData}
                            editField={'old_batch_no'}
                        />
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-body">
                    <div className="field">
                        <label className="label">Inoculator</label>
                        <SearchComponent
                            value={editData.name_inoculator}
                            userOptions={userOptions}
                            setEditData={setEditData}
                            editData={initalEditData}
                            editField={'name_inoculator'}
                        />
                        {/* <InputField
                            placeholder={'Inoculator'}
                            value={editData.name_inoculator}
                            className="control is-expanded"
                            setEditData={setEditData}
                            editData={initalEditData}
                            editField={'name_inoculator'}
                        /> */}
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-body">
                    <div className="field">
                        <label className="label">Number of Ex Plants</label>
                        <InputField
                            placeholder={'Number of Ex Plants'}
                            value={editData.ex_plants}
                            className="control is-expanded"
                            setEditData={setEditData}
                            editData={initalEditData}
                            editField={'ex_plants'}
                        />
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-body">
                    <div className="field">
                        <label className="label">Current Stage</label>
                        <InputField
                            placeholder={'Current Stage'}
                            value={editData.current_stage}
                            className="control is-expanded"
                            setEditData={setEditData}
                            editData={initalEditData}
                            editField={'current_stage'}
                        />
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-body">
                    <div className="field">
                        <label className="label">Last Inoculation Date</label>
                        <InputField
                            placeholder={'Last Inoculation Date'}
                            // value={editData.current_stage}
                            className="control is-expanded"
                        />
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-body">
                    <div className="field">
                        <label className="label">Next Transfer Week</label>
                        <InputField
                            placeholder={'Next Transfer Week'}
                            value={editData.next_transfer_week}
                            className="control is-expanded"
                            setEditData={setEditData}
                            editData={initalEditData}
                            editField={'next_transfer_week'}
                        />
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-body">
                    <div className="field">
                        <label className="label">Mother Plant Source(New)</label>
                        {motherOptions &&
                            <SearchComponent
                                value={editData.mother_plant_source}
                                motherOptions={motherOptions}
                                setEditData={setEditData}
                                editData={initalEditData}
                                editField={'mother_plant_source'}
                            />}
                        {/* <InputField
                            placeholder={'Mother Plant Source(New)'}
                            value={editData.mother_plant_source}
                            className="control is-expanded"
                            setEditData={setEditData}
                            editData={initalEditData}
                            editField={'mother_plant_source'}
                        /> */}
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-body">
                    <div className="field">
                        <label className="label">Node Number</label>
                        <InputField
                            placeholder={'Node Number'}
                            className="control is-expanded"
                            value={editData?.nodes}
                            setEditData={setEditData}
                            editData={initalEditData}
                            editField={'nodes'}
                        />
                    </div>
                </div>
            </div>

            <label className="label">Barcode Number</label>
            <div className="field is-horizontal">
                <div className="field-body">
                    {/* <div className="field"> */}
                    <InputField
                        disabled={true}
                        value={process.env.REACT_APP_BARCODE_INITIAL}
                        formStyle={inputWidth}
                    />
                    {/* </div> */}
                    <div className="field">
                        <InputField
                            value={editData?.plant_bottle_code}
                            placeholder={'Barcode Number'}
                            className="control is-expanded"
                            setEditData={setEditData}
                            editData={initalEditData}
                            editField={'plant_bottle_code'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LabsEditForm
