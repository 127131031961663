import React, { useState, useEffect } from "react";
import { Steps, Step } from "react-step-builder";
import StepsCulture from "./StepsCulture"
import StepCancelRegistration from "./StepCancelRegistration"
import StepContinueRegistration from "./StepContinueRegistration"
import StepPlantCreation from "./StepPlantCreation"
import StepExplantFinalConfimation from "./StepExplantFinalConfimation"
import StepUpdateInstructions from "./UpdateSteps/StepUpdateInstructions"
import StepUpdateBottleScan from "./UpdateSteps/StepUpdateBottleScan"
import StepUpdateBottleCheck from "./UpdateSteps/StepUpdateBottleCheck"
import StepNumOfShoots from "../LabsBatch/StepNumOfShoots"
import StepUpdateConfirm from "./UpdateSteps/StepUpdateConfirm"
import StepUpdateFinalConfirmation from "./UpdateSteps/StepUpdateFinalConfirmation"
import StepSelectNode from "./StepSelectNode"
import getTypesByKey from "../../services/types/getTypesByKey"

const LabsPendingPlantList = (props) => {
    const [continueBatch, setContinueBatch] = useState();
    const [explantCounter, setExplantCounter] = useState(0);
    const [latestExPlant, setLatestExplant] = useState();
    const [goBack, setGoBack] = useState(false);
    const [registerAgain, setRegisterAgain] = useState(false);
    const [registerData, setRegisterData] = useState()

    const [updateData, setUpdateData] = useState([]);
    const [newShoots, setNewShoots] = useState([]);
    const updateRegistrationId = props?.pendingPlants[0].registration_id
    const [updateCounter, setUpdateCounter] = useState(0);

    const [nodeTypes, setNodeTypes] = useState();
    const [validBottleCode, setValidBottleCode] = useState();

    const numOfShoots = props?.pendingPlants[0].num_of_shoots;

    const getNodes = async () => {
        let nodes = await getTypesByKey('plants_nodes')
        setNodeTypes(nodes)
    }
    useEffect(() => {

        getNodes()
    }, []);

    console.log('update data', props)

    return (
        props.type !== "update" ?
            <Steps>

                <Step component={StepsCulture} pendingPlants={props?.pendingPlants} setBatchStatus={setContinueBatch} />
                {continueBatch ? <Step didGoBack={goBack} setGoBack={setGoBack} registerAgain={registerAgain} setLatestPlant={setLatestExplant} latestPlant={latestExPlant} setValidBottleCode={setValidBottleCode} setRegisterData={setRegisterData} component={StepContinueRegistration} /> : <Step component={StepCancelRegistration} />}
                <Step setLatestPlant={setLatestExplant} latestPlant={latestExPlant} setRegisterData={setRegisterData} validBottleCode={validBottleCode} nodeTypes={nodeTypes} component={StepSelectNode} />
                <Step registerData={registerData} setRegisterAgain={setRegisterAgain} explantCounter={explantCounter} setExplantCounter={setExplantCounter} setGoBack={setGoBack} numOfShoots={numOfShoots} component={StepPlantCreation} />
                <Step component={StepExplantFinalConfimation} />
                {/* {registerAgain ? <Step component={StepContinueRegistration} /> : <Step component={StepCancelRegistration} />} */}
            </Steps>
            :
            <Steps>
                <Step
                    component={StepsCulture}
                    pendingPlants={props?.pendingPlants}
                    setBatchStatus={setContinueBatch} />
                {continueBatch ?
                    <Step component={StepUpdateInstructions} />
                    :
                    <Step component={StepCancelRegistration} />}
                <Step
                    component={StepUpdateBottleScan}
                    setUpdateData={setUpdateData}
                    updateData={updateData} />
                <Step
                    component={StepUpdateBottleCheck}
                    updateData={updateData}
                    setUpdateData={setUpdateData} />
                <Step
                    component={StepNumOfShoots}
                    updateRegistrationId={updateRegistrationId}
                    updateData={updateData}
                    setUpdateData={setUpdateData}
                    newShoots={newShoots}
                    setNewShoots={setNewShoots}
                    updateCounter={updateCounter}
                    setUpdateCounter={setUpdateCounter}
                />
                <Step
                    component={StepUpdateConfirm}
                    newShoots={newShoots}
                    updateData={updateData}
                    updateCounter={updateCounter}
                    updateRegistrationId={updateRegistrationId} />
                <Step
                    component={StepUpdateFinalConfirmation}
                    newShoots={newShoots}
                    updateData={updateData}
                    updateCounter={updateCounter}
                    updateRegistrationId={updateRegistrationId} />
                {/* {continueBatch ? <Step didGoBack={goBack} setGoBack={setGoBack} registerAgain={registerAgain} setLatestPlant={setLatestExplant} latestPlant={latestExPlant} component={StepContinueRegistration} /> : <Step component={StepCancelRegistration} />}
                <Step setRegisterAgain={setRegisterAgain} explantCounter={explantCounter} setExplantCounter={setExplantCounter} setGoBack={setGoBack} component={StepPlantCreation} />
                <Step component={StepExplantFinalConfimation} /> */}
            </Steps>

    )
}

export default LabsPendingPlantList