import React, { useState } from 'react'
import postUsers from "../../services/users/postUsers"
import Select from 'react-select'
import userTypes from "../../constants/userTypes"
import './Users.scss'

const UsersCreate = (props) => {

    props.validateSession()

    const [apiResponse, setApiResponse] = useState("");

    const [mobileCountryCode, setMobileCountryCode] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [organization, setOrganization] = useState("");
    const [barangay, setBarangay] = useState("");
    const [province, setProvince] = useState("");
    const [yearsFarming, setYearsFarming] = useState("");
    const [userType, setUserType] = useState("");
    const [userEmail, setUserEmail] = useState("");

    const [mobileCountryCodeErr, setMobileCountryCodeErr] = useState({});
    const [mobileNumberErr, setMobileNumberErr] = useState({});
    const [firstNameErr, setFirstNameErr] = useState({});
    const [lastNameErr, setLastNameErr] = useState({});
    const [organizationErr, setOrganizationErr] = useState({});
    const [barangayErr, setBarangayErr] = useState({});
    const [provinceErr, setProvinceErr] = useState({});
    const [yearsFarmingErr, setYearsFarmingErr] = useState({});
    const [userTypeErr, setUserTypeErr] = useState({});
    const [userEmailErr, setUserEmailErr] = useState({});

    const formValidation = () => {
        const mobileCountryCodeErr = {}
        const mobileNumberErr = {}
        const firstNameErr = {}
        const lastNameErr = {}
        const organizationErr = {}
        const barangayErr = {}
        const provinceErr = {}
        const yearsFarmingErr = {}

        let isValid = true

        if (mobileCountryCode.trim().length !== 2) {
            mobileCountryCodeErr.invalidLength = "Mobile country code must be two (2) digits."
            isValid = false
        }

        if (mobileCountryCode.isInteger === false) {
            mobileCountryCodeErr.invalidDigit = "Must contain digits only."
            isValid = false
        }

        if (mobileNumber.trim().length !== 10) {
            mobileNumberErr.invalidLength = "Mobile number must be ten (10) digits."
            isValid = false
        }

        if (firstName === "") {
            firstNameErr.required = "This field is required."
            isValid = false
        }

        if (lastName === "") {
            lastNameErr.required = "This field is required."
            isValid = false
        }

        if (organization === "") {
            organizationErr.required = "This field is required."
            isValid = false
        }

        if (barangay === "") {
            barangayErr.required = "This field is required."
            isValid = false
        }

        if (province === "") {
            provinceErr.required = "This field is required."
            isValid = false
        }

        if (yearsFarming === "") {
            yearsFarmingErr.required = "This field is required."
            isValid = false
        }

        if (userType === "") {
            userTypeErr.required = "This field is required."
            isValid = false
        }

        if (userEmail === "") {
            userEmailErr.required = "This field is required."
            isValid = false
        }

        setMobileCountryCodeErr(mobileCountryCodeErr)
        setMobileNumberErr(mobileNumberErr)
        setFirstNameErr(firstNameErr)
        setLastNameErr(lastNameErr)
        setOrganizationErr(organizationErr)
        setBarangayErr(barangayErr)
        setProvinceErr(provinceErr)
        setYearsFarmingErr(yearsFarmingErr)
        setUserTypeErr(userTypeErr)
        setUserEmailErr(userEmailErr)
        return isValid
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const isValid = formValidation()

        if (isValid) {
            console.log("Form Valid: Processing on Backend")

            processBackend()

            // if (res.status == 200) {
            // window.location.href = "/users"
            // props.router.push("/users")
            // }

            e.preventDefault();
        } else {
            console.log("Form Invalid: Please check form")
        }
    }

    const processBackend = async () => {
        try {
            let res = await postUsers(mobileCountryCode, mobileNumber, firstName, lastName, organization, barangay, province, yearsFarming, userType.value, userEmail)
            HandleResponse(res)
        } catch (error) {
            HandleResponse(error);
        }
    }

    const HandleResponse = (res) => {

        console.log("HandleResponse.res:")
        console.log(res)
        console.log(res.status)

        if (res.status === 200) {
            setApiResponse("success")
        } else {
            setApiResponse("error")
        }

    }

    const ResponseSuccess = () => {
        return (
            <div className="notification is-success">
                <button className="delete"></button>
                Successfully processed your request.
            </div>
        )
    }

    const ResponseError = () => {
        const [close, setClose] = useState(false);

        return (
            <div className="notification is-danger" style={close ? { display: 'none' } : { display: 'block' }}>
                <button className="delete" onClick={() => setClose(true)}></button>
                There was an error processing your request.
            </div>
        )
    }

    return (
        <div className="container">
            <p>&nbsp;</p>

            <h1 className="title">Users</h1>
            <h2 className="subtitle">Create New User</h2>

            {apiResponse === "success" && <ResponseSuccess />}
            {apiResponse === "error" && <ResponseError />}

            <form onSubmit={handleSubmit}>

                <div className="field">
                    <label className="label">User Type</label>
                    <Select
                        // defaultValue={defaultValue}
                        // isMulti
                        onChange={(e) => {
                            setUserType(e)
                        }}
                        options={userTypes}
                    />
                    {Object.keys(userTypeErr).map((key) => {
                        return <p className="help is-danger">{userTypeErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Mobile Country Code</label>
                    <div className="control">
                        <input name="mobileCountryCode" className="input" type="text" placeholder="Mobile Country Code"
                            value={mobileCountryCode}
                            onChange={e => setMobileCountryCode(e.target.value)} />
                    </div>
                    {Object.keys(mobileCountryCodeErr).map((key) => {
                        return <p className="help is-danger">{mobileCountryCodeErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Mobile Number</label>
                    <div className="control">
                        <input name="mobileNumber" className="input" type="text" placeholder="Mobile Number"
                            value={mobileNumber}
                            onChange={e => setMobileNumber(e.target.value)} />
                    </div>
                    {Object.keys(mobileNumberErr).map((key) => {
                        return <p className="help is-danger">{mobileNumberErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Email Address</label>
                    <div className="control">
                        <input name="mobileNumber" className="input" type="email" placeholder="Email Address"
                            value={userEmail}
                            onChange={e => setUserEmail(e.target.value)} />
                    </div>
                    {Object.keys(userEmailErr).map((key) => {
                        return <p className="help is-danger">{userEmailErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">First Name</label>
                    <div className="control">
                        <input name="firstName" className="input" type="text" placeholder="First Name"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)} />
                    </div>
                    {Object.keys(firstNameErr).map((key) => {
                        return <p className="help is-danger">{firstNameErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Last Name</label>
                    <div className="control">
                        <input name="lastName" className="input" type="text" placeholder="Last Name"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)} />
                    </div>
                    {Object.keys(lastNameErr).map((key) => {
                        return <p className="help is-danger">{lastNameErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Organization</label>
                    <div className="control">
                        <input name="organization" className="input" type="text" placeholder="Organization"
                            value={organization}
                            onChange={e => setOrganization(e.target.value)} />
                    </div>
                    {Object.keys(organizationErr).map((key) => {
                        return <p className="help is-danger">{organizationErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Barangay</label>
                    <div className="control">
                        <input name="barangay" className="input" type="text" placeholder="Barangay"
                            value={barangay}
                            onChange={e => setBarangay(e.target.value)} />
                    </div>
                    {Object.keys(barangayErr).map((key) => {
                        return <p className="help is-danger">{barangayErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Province</label>
                    <div className="control">
                        <input name="province" className="input" type="text" placeholder="Province"
                            value={province}
                            onChange={e => setProvince(e.target.value)} />
                    </div>
                    {Object.keys(provinceErr).map((key) => {
                        return <p className="help is-danger">{provinceErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Years Farming</label>
                    <div className="control">
                        <input name="yearsFarming" className="input" type="text" placeholder="Years Farming"
                            value={yearsFarming}
                            onChange={e => setYearsFarming(e.target.value)} />
                    </div>
                    {Object.keys(yearsFarmingErr).map((key) => {
                        return <p className="help is-danger">{yearsFarmingErr[key]}</p>
                    })}
                </div>

                <div className="field is-grouped">
                    <div className="control">
                        <button className="button is-link" type="submit">Create New User</button>
                    </div>
                    <div className="control">
                        <button className="button is-link is-light">Cancel</button>
                    </div>
                </div>

            </form>



        </div>
    )
}

export default UsersCreate
