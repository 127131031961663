import React, { useState, useEffect, useCallback } from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'

import './ActivityTemplates.scss'

import postProcessor from "../../services/activities/postProcessor"
import getSchedulerLists from "../../services/activities/scheduler/getSchedulerList"
import getFarmLists from "../../services/farms/getFarmList"
import getUsers from "../../services/users/getUsers"

import NotificationError from "../../components/NotificationError/NotificationError"
import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"

const ActivityGenerateForm = (props) => {

    props.validateSession()

    console.log("props:")
    console.log(props)

    const [apiResponse, setApiResponse] = useState("");

    const [templateType, setTemplateType] = useState("");
    const [farm, setFarm] = useState("");
    const [userHash, setUserHash] = useState();
    const [startDate, setStartDate] = useState(new Date());

    const [schedulerLists, setSchedulerLists] = useState([])
    const [farmLists, setFarmLists] = useState([])
    const [userLists, setUserLists] = useState([])

    // const [templateTypeErr, setTemplateTypeErr] = useState({});

    const handleCalendarClose = () => console.log("Calendar closed");
    const handleCalendarOpen = () => console.log("Calendar opened");

    const [displayFarm, setDisplayFarm] = useState();

    useEffect(() => {
        const getSchedulerList = async () => {
            let result = await getSchedulerLists()
            console.log("DEBUG/schedulerLists:", result)

            var listAll = []
            result.forEach(el => {
                listAll.push({
                    value: el.template_type,
                    label: el.template_type
                })
            })

            setSchedulerLists(listAll)
        }

        getSchedulerList()
    }, []);

    useEffect(() => {
        setDisplayFarm('')
        const getFarmList = async () => {
            let result = await getFarmLists()
            console.log("DEBUG/getFarmLists:", result)

            var listAll = []
            result.filter((farm) => farm.user_hash === userHash).forEach(el => {
                listAll.push({
                    value: el.id,
                    label: el.farm_name + " by (" + el.full_name + ", " + el.organization + ")"
                })
            })

            if (userHash)
                setFarmLists(listAll)
        }

        getFarmList()
    }, [userHash]);

    useEffect(() => {
        const getUserList = async () => {
            let result = await getUsers()

            var listAll = []
            result.forEach(el => {
                listAll.push({
                    value: el.hash,
                    label: "" + el.mobile_country_code + "-" + el.mobile_number + " - " + el.first_name + " " + el.last_name + " (" + el.organization + ")"
                })
            })

            setUserLists(listAll)
        }

        getUserList()
    }, []);

    const setSelectedFarm = useCallback((selected) => {
        setFarm(selected)
        setDisplayFarm(farmLists.find((val) => val.value === selected))
    },
        [farmLists],
    )

    const formValidation = () => {
        // const templateTypeErr = {}

        let isValid = true

        // if (templateType === "") {
        //     templateTypeErr.required = "This field is required."
        //     isValid = false
        // }

        // setTemplateTypeErr(templateTypeErr)
        return isValid
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const isValid = formValidation()

        if (isValid) {
            console.log("Form Valid: Processing on Backend")

            processBackend()

            // if (res.status == 200) {
            // window.location.href = "/users"
            // props.router.push("/users")
            // }

            e.preventDefault();
        } else {
            console.log("Form Invalid: Please check form")
        }
    }

    const processBackend = async () => {
        let request = {
            templateType: templateType,
            user_hash: userHash,
            farm_id: farm,
            start_datetime: startDate
        }

        request.start_datetime = new Date(startDate.getTime() + Math.abs(startDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);

        console.log("DEBUG/request: ", request)

        try {
            let res = await postProcessor(request)
            HandleResponse(res)
        } catch (error) {
            HandleResponse(error);
        }
    }

    const HandleResponse = (res) => {

        console.log("HandleResponse.res:")
        console.log(res)
        console.log(res.status)

        if (res.status === 200) {
            setApiResponse("success")
        } else {
            setApiResponse("error")
        }

    }

    return (
        <div className="container">
            <p>&nbsp;</p>

            <h1 className="title">Generate Activity</h1>
            <h2 className="subtitle">Used for generating scheduled activities for a specific user.</h2>

            {apiResponse === "success" && <NotificationSuccess setMessage={setApiResponse} message="Successfully processed your request" />}
            {apiResponse === "error" && <NotificationError setMessage={setApiResponse} message="There was an error processing your request." />}

            <form onSubmit={handleSubmit}>

                <div className="field">
                    <label className="label">Which Templates</label>
                    <div className="control">
                        <Select
                            // defaultValue={templateType}
                            onChange={e => setTemplateType(e.value)}
                            options={schedulerLists} />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Which User</label>
                    <div className="control">
                        <Select
                            // defaultValue=""
                            onChange={e => setUserHash(e.value)}
                            options={userLists} />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Which Farm</label>
                    <div className="control">
                        <Select
                            // defaultValue=""
                            isDisabled={!userHash}
                            value = {displayFarm}
                            onChange={e => setSelectedFarm(e.value)}
                            options={farmLists} />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Start Date</label>
                    <div className="control">
                        <DatePicker
                            selected={startDate}
                            // selected={date}
                            onChange={
                                date => setStartDate(date)
                            }
                            onCalendarClose={handleCalendarClose}
                            onCalendarOpen={handleCalendarOpen}
                            dateFormat="yyyy/MM/dd"
                        />
                    </div>
                </div>

                <div className="field is-grouped">
                    <div className="control">
                        <button className="button is-link" type="submit">Generate</button>
                    </div>
                </div>

            </form>



        </div>
    )
}

export default ActivityGenerateForm
