import React, { useState } from 'react'
import postTemplate from "../../services/activities/templates/postTemplate"
// import activityTypes  from "../../constants/activityTypes"
// import Select from 'react-select'
import './ActivityTemplates.scss'

import NotificationError from "../../components/NotificationError/NotificationError"
import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"
// import { Description, Schedule } from '@material-ui/icons'
// import { faHome } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select'

import activityTypes from "../../constants/activityTypes"

const ActivityTemplatesForm = (props) => {

    props.validateSession()

    console.log("props:")
    console.log(props)

    const [apiResponse, setApiResponse] = useState("");

    const [templateType, setTemplateType] = useState("");
    const [activityType, setActivityType] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [sequence, setSequence] = useState("");
    const [schedule, setSchedule] = useState("");
    const [timeStart, setTimeStart] = useState("");
    const [timeDuration, setTimeDuration] = useState("");
    const [initial, setInitial] = useState("");
    const [templateTypeErr, setTemplateTypeErr] = useState({});
    const [activityTypeErr, setActivityTypeErr] = useState({});
    const [nameErr, setNameErr] = useState({});
    const [descriptionErr, setDescriptioneErr] = useState({});
    const [sequenceErr, setSequenceErr] = useState({});
    const [scheduleErr, setScheduleErr] = useState({});
    const [timeStartErr, setTimeStartErr] = useState({});
    const [timeDurationErr, setTimeDurationErr] = useState({});
    const [initialErr, setInitialErr] = useState({});

    const formValidation = () => {
        const templateTypeErr = {}
        const activityTypeErr = {}
        const nameErr = {}
        const descriptionErr = {}
        const sequenceErr = {}
        const scheduleErr = {}
        const timeStartErr = {}
        const timeDurationErr = {}
        const initialErr = {}

        let isValid = true

        if (templateType === "") {
            templateTypeErr.required = "This field is required."
            isValid = false
        }

        if (activityType === "") {
            activityTypeErr.required = "This field is required."
            isValid = false
        }

        if (name === "") {
            nameErr.required = "This field is required."
            isValid = false
        }

        if (description === "") {
            descriptionErr.required = "This field is required."
            isValid = false
        }

        if (sequence === "") {
            sequenceErr.required = "This field is required."
            isValid = false
        }

        if (schedule === "") {
            scheduleErr.required = "This field is required."
            isValid = false
        }

        if (timeStart === "") {
            timeStartErr.required = "This field is required."
            isValid = false
        }

        if (timeDuration === "") {
            timeDurationErr.required = "This field is required."
            isValid = false
        }

        if (initial === "") {
            initialErr.required = "This field is required."
            isValid = false
        }


        setTemplateTypeErr(templateTypeErr)
        setActivityTypeErr(activityTypeErr)
        setNameErr(nameErr)
        setDescriptioneErr(descriptionErr)
        setSequenceErr(sequenceErr)
        setScheduleErr(scheduleErr)
        setTimeStartErr(timeStartErr)
        setTimeDurationErr(timeDurationErr)
        setInitialErr(initialErr)
        return isValid
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const isValid = formValidation()

        if (isValid) {
            console.log("Form Valid: Processing on Backend")

            processBackend()

            // if (res.status == 200) {
            // window.location.href = "/users"
            // props.router.push("/users")
            // }

            e.preventDefault();
        } else {
            console.log("Form Invalid: Please check form")
        }
    }

    const processBackend = async () => {
        let request = {
            templateType: templateType,
            activityType: activityType.value,
            name: name,
            description: description,
            sequence: sequence,
            schedule: schedule,
            timeStart: timeStart,
            timeDuration: timeDuration,
            initial: initial,
        }

        console.log("DEBUG/request: ", request)

        try {
            let res = await postTemplate(request)
            HandleResponse(res)
        } catch (error) {
            HandleResponse(error);
        }
    }

    const HandleResponse = (res) => {

        console.log("HandleResponse.res:")
        console.log(res)
        console.log(res.status)

        if (res.status === 200) {
            setApiResponse("success")
        } else {
            setApiResponse("error")
        }

    }

    return (
        <div className="container">
            <p>&nbsp;</p>

            <h1 className="title">Activity Template</h1>
            <h2 className="subtitle">Create or Edit</h2>

            {/* {apiResponse === "success" && <NotificationSuccess setMessage={setApiResponse} message= "Successfully processed your request"/>}
            {apiResponse === "error" && <NotificationError setMessage={setApiResponse} message="There was an error processing your request." />} */}

            <form onSubmit={handleSubmit}>

                <div className="field">
                    <label className="label">Template Name</label>
                    <div className="control">
                        <input name="templateType" className="input" type="text" placeholder="Template Name"
                            value={templateType}
                            onChange={e => setTemplateType(e.target.value)} />
                    </div>
                    {Object.keys(templateTypeErr).map((key) => {
                        return <p className="help is-danger">{templateTypeErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Activity Type</label>
                    <div className="control">
                        <Select
                            value={activityType}
                            onChange={(val) => setActivityType(val)}
                            options={activityTypes} />
                    </div>
                    {Object.keys(activityTypeErr).map((key) => {
                        return <p className="help is-danger">{activityTypeErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Activity Name</label>
                    <div className="control">
                        <input name="name" className="input" type="text" placeholder="Activity Name"
                            value={name}
                            onChange={e => setName(e.target.value)} />
                    </div>
                    {Object.keys(nameErr).map((key) => {
                        return <p className="help is-danger">{nameErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Description</label>
                    <div className="control">
                        <input name="description" className="input" type="text" placeholder="Description"
                            value={description}
                            onChange={e => setDescription(e.target.value)} />
                    </div>
                    {Object.keys(descriptionErr).map((key) => {
                        return <p className="help is-danger">{descriptionErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Sequence</label>
                    <div className="control">
                        <input name="sequence" className="input" type="text" placeholder="Sequence"
                            value={sequence}
                            onChange={e => setSequence(e.target.value)} />
                    </div>
                    {Object.keys(sequenceErr).map((key) => {
                        return <p className="help is-danger">{sequenceErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Schedule</label>
                    <div className="control">
                        <input name="schedule" className="input" type="text" placeholder="Schedule"
                            value={schedule}
                            onChange={e => setSchedule(e.target.value)} />
                    </div>
                    {Object.keys(scheduleErr).map((key) => {
                        return <p className="help is-danger">{scheduleErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Time Start</label>
                    <div className="control">
                        <input name="timeStart" className="input" type="text" placeholder="Time Start"
                            value={timeStart}
                            onChange={e => setTimeStart(e.target.value)} />
                    </div>
                    {Object.keys(timeStartErr).map((key) => {
                        return <p className="help is-danger">{timeStartErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Time Duration</label>
                    <div className="control">
                        <input name="timeDuration" className="input" type="text" placeholder="Time Duration"
                            value={timeDuration}
                            onChange={e => setTimeDuration(e.target.value)} />
                    </div>
                    {Object.keys(timeDurationErr).map((key) => {
                        return <p className="help is-danger">{timeDurationErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Initial</label>
                    <div className="control">
                        <label className="radio">
                            <input onChange={e => setInitial('1')} type="radio" name="initial" /> Yes
        </label>
                        <label className="radio">
                            <input onChange={e => setInitial('0')} type="radio" name="initial" /> No
        </label>
                    </div>
                    {Object.keys(initialErr).map((key) => {
                        return <p className="help is-danger">{initialErr[key]}</p>
                    })}
                </div>

                {apiResponse === "success" && <NotificationSuccess setMessage={setApiResponse} message="Successfully processed your request" />}
                {apiResponse === "error" && <NotificationError setMessage={setApiResponse} message="There was an error processing your request." />}

                <div className="field is-grouped">
                    <div className="control">
                        <button className="button is-link" type="submit">Create or Update Template</button>
                    </div>
                    {/* <div className="control">
                        <button className="button is-link is-light">Cancel</button>
                    </div> */}
                </div>

            </form>



        </div>
    )
}

export default ActivityTemplatesForm
