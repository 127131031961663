import axios from "axios";

const getSchedulerList = async (templateType) => {
    console.log("DEBUG/getSchedulerList:")
    console.log(templateType)

    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ACTIVITIES_SCHEDULER + "/list/", {
            headers: {
                'Authorization': 'Bearer 4b58c6b1d51f0b45c5426d250324a6c49bb81c5e893b877ebe917f8bf5098a17'
            }
        }).then(async res => {
            console.log("service.getSchedulerList():")
            console.log(res)
            var result = res.data.data
            resolve(result)
        }).catch(err => {
            console.log("service.getSchedulerList():")
            console.log(err)
            reject(err)
        })
    })
}

export default getSchedulerList
