import React, { useState, useEffect } from 'react'
import { Steps, Step } from "react-step-builder";
import StepBottleScan from "./StepBottleScan"
import StepStageTransfer from "./StepStageTransfer"
import StepReportCallus from "./StepReportCallus"
import StepReviewPage from "./StepReviewPage"
import StepConfirmPage from "./StepConfirmPage"
import StepReportDormant from "./StepReportDormant"

import StepLaminarFlow from "../LabsBatch/StepLaminarFlow"
import StepLabAids from "../LabsBatch/StepLabAids"
import StepProtocolSelection from "../LabsBatch/StepProtocolSelection"
import StepProtocolConfirmation from "../LabsBatch/StepProtocolConfirmation"
import StepFinalReview from "../LabsBatch/StepFinalReview"
import StepFinalConfirmation from "../LabsBatch/StepFinalConfirmation"
import LabsPendingPlantList from "../LabsPendingPlantList/LabsPendingPlantList"
import retrievePendingUpdatePlants from "../../services/plants/retrievePendingUpdatePlants"


import getTypesByProgram from "../../services/types/getTypesByProgram";
import { useJwt } from "react-jwt";
import { Cookies } from 'react-cookie';

const LabsUpdateStage = (props) => {

    const cookies = new Cookies();
    const authToken = cookies.get('authSession')
    const signed_user_hash = useJwt(authToken).decodedToken?.data?.hash
    const [shootsData, setShootsData] = useState()
    const [numberCallusReported, setNumberCallusReported] = useState(null)
    const [numberDormantReported, setNumberDormantReported] = useState(null)

    const [loadedData, setLoadedData] = useState(false)
    const [pendingPlants, setPendingPlants] = useState([])
    const [callusSelection, setCallusSelection] = useState([])
    const [dormantSelection, setDormantSelection] = useState([])

    const [totalReportedDormant, setTotalReportedDormant] = useState([])
    const [totalReportedCallus, setTotalReportedCallus] = useState([])
    const [totalReportedCallusCount, setTotalReportedCallusCount] = useState(0)

    const [passedBarcode, setPassedBarcode] = useState();

    props.validateSession()

    const divideSelections = (data, n) => {
        let divide = data.slice(0, 9).reduce(
            (r, e, i) => (i % n ? r[r.length - 1].push(e) : r.push([e])) && r,
            []
        ); // group array by three
        return divide
    }

    useEffect(() => {

        const getTypeKey = async () => {
            let allTypes = await getTypesByProgram('plant-register-update')
            // let howManyCallus = await getTypesByKey('plants_how_many_callus');
            // let howManyDormant = await getTypesByKey('plants_how_many_dormant');
            let howManyCallus = await allTypes.filter((data) => data.key === "plants_how_many_callus");
            let howManyDormant = await allTypes.filter((data) => data.key === "plants_how_many_dormant");
            const n = 3;

            console.log('dormant', howManyDormant)
            let dividedCallusResult;
            let dividedDormantResult;
            if (howManyCallus)
                dividedCallusResult = divideSelections(howManyCallus, n)

            if (howManyDormant)
                dividedDormantResult = divideSelections(howManyDormant, n)

            setCallusSelection(dividedCallusResult);
            setDormantSelection(dividedDormantResult);
        };

        const getPendingPlants = async () => {
            let result = await retrievePendingUpdatePlants(signed_user_hash)
            let filteredResult = result ? result?.filter(res => res.status === 'pending-update' && res.notes !== 'complete with registration step 1-2') : []
            // console.log(filteredResult)
            setPendingPlants(filteredResult)
            setLoadedData(true)
        }
        if (signed_user_hash) {
            getPendingPlants()
            getTypeKey();
        }
    }, [signed_user_hash]);

    useEffect(() => {
        console.log(numberCallusReported)
    }, [numberCallusReported])

    useEffect(() => {
        if (props?.location?.state?.passedBarcode) {
            setPassedBarcode(props.location.state.passedBarcode)
        }
    }, [props, signed_user_hash])

    return (
        loadedData ?
            (pendingPlants?.length > 0 ?
                <div>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <div className="box">
                        <LabsPendingPlantList pendingPlants={pendingPlants} type="update" />
                    </div></div>
                :
                <div>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <div className="box">
                        <Steps>
                            <Step setShootsData={setShootsData} shootsData={shootsData} passedBarcode={passedBarcode} component={StepBottleScan} />
                            <Step component={StepStageTransfer} />
                            <Step setNumberDormantReported={setNumberDormantReported}
                                setNumberCallusReported={setNumberCallusReported}
                                numberDormantReported={numberDormantReported}
                                setTotalReportedDormant={setTotalReportedDormant}
                                setTotalReportedCallus={setTotalReportedCallus}
                                totalReportedDormant={totalReportedDormant}
                                totalReportedCallus={totalReportedCallus}
                                numberCallusReported={numberCallusReported}
                                setTotalReportedCallusCount={setTotalReportedCallusCount}
                                totalReportedCallusCount={totalReportedCallusCount}
                                component={StepReviewPage} />
                            <Step totalReportedDormant={totalReportedDormant}
                                totalReportedCallus={totalReportedCallus}
                                totalReportedCallusCount={totalReportedCallusCount}
                                numberCallusReported={numberCallusReported}
                                component={StepConfirmPage} />
                            <Step component={StepLaminarFlow} />
                            <Step component={StepLabAids} />
                            <Step component={StepProtocolSelection} />
                            <Step component={StepProtocolConfirmation} />
                            <Step numberDormantReported={numberDormantReported} signed_user_hash = {signed_user_hash} numberCallusReported={numberCallusReported} component={StepFinalReview} type='update' />
                            <Step component={StepFinalConfirmation} />

                            <Step callusSelection={callusSelection} setNumberCallusReported={setNumberCallusReported} shootsData={shootsData} component={StepReportCallus} />
                            <Step dormantSelection={dormantSelection} setNumberDormantReported={setNumberDormantReported} shootsData={shootsData} component={StepReportDormant} />
                        </Steps>
                    </div>
                </div>)
            : <div><p>&nbsp;</p><p>&nbsp;</p>Loading..</div>
    )
}

export default LabsUpdateStage
