import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'

import getCountries from "../../services/countries/getCountries"
import postMother from "../../services/plants/mother/postMother"

import NotificationError from "../../components/NotificationError/NotificationError"
import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"

import './LabsMotherPlant.scss'

const LabsMotherPlantRegister = (props) => {

    props.validateSession()

    const [apiResponse, setApiResponse] = useState("");
    const [name, setName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState(new Date() );
    const [originCountry, setOriginCountry] = useState("");
    const [getCountryList, setCountryLists] = useState([])

    const [nameErr, setNameErr] = useState({});
    const [originCountryErr, setOriginCountryErr] = useState({});

    useEffect(() => {
        const getCountryList = async () => {
            let result = await getCountries()

            // console.log("getCountryList:", result)

            var listAll = []
            result.forEach(el => {
                listAll.push({
                    value: el.id,
                    label: "" + el.nicename + ""
                })
            })

            setCountryLists(listAll)
        }

        getCountryList()
    }, []);

    const formValidation = () => {
        const nameErr = {}
        const originCountryErr = {}

        let isValid = true

        if (name === "") {
            nameErr.required = "This field is required."
            isValid = false
        }


        if (originCountry === "") {
            originCountryErr.required = "This field is required."
            isValid = false
        }

        setNameErr(nameErr)
        setOriginCountryErr(originCountryErr)
        return isValid
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const isValid = formValidation()

        if (isValid) {
            console.log("Form Valid: Processing on Backend")

            processBackend()

            // if (res.status == 200) {
            // window.location.href = "/users"
            // props.router.push("/users")
            // }

            e.preventDefault();
        } else {
            console.log("Form Invalid: Please check form")
        }
    }

    const processBackend = async () => {
        let request = {
            name: name,
            originCountry: originCountry,
            dateOfBirth: dateOfBirth
        }

        request.dateOfBirth = new Date(dateOfBirth.getTime() + Math.abs(dateOfBirth.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);
        console.log("processBackend/request: ", request)

        try {
            let res = await postMother(request)
            HandleResponse(res)
        } catch (error) {
            HandleResponse(error);
        }
    }

    const HandleResponse = (res) => {

        console.log("HandleResponse.res:")
        console.log(res)
        console.log(res.status)

        if (res.status === 200) {
            setApiResponse("success")
        } else {
            setApiResponse("error")
        }

    }

    return (
        <div className="container">

            <p>&nbsp;</p>
            <h1 className="title">Register Mother Plant</h1>
            <h2 className="subtitle">Create or Edit</h2>

            {apiResponse === "success" && <NotificationSuccess setMessage={setApiResponse} message="Successfully processed your request" />}
            {apiResponse === "error" && <NotificationError setMessage={setApiResponse} message="There was an error processing your request." />}

            <form onSubmit={handleSubmit}>

                <div className="field">
                    <label className="label">Name</label>
                    <div className="control">
                        <input name="name" className="input" type="text" placeholder="Title"
                            value={name}
                            onChange={e => setName(e.target.value)} />
                    </div>
                </div>
                {Object.keys(nameErr).map((key) => {
                    return <p className="help is-danger">{nameErr[key]}</p>
                })}

                <div className="field">
                    <label className="label">Date of Birth</label>
                    <div className="control">
                        <DatePicker
                            selected={dateOfBirth}
                            // selected={date}
                            onChange={
                                date => setDateOfBirth(date)
                            }
                            dateFormat="yyyy/MM/dd"
                        />
                    </div>
                    {/* {Object.keys(startDateErr).map((key) => {
                        return <p className="help is-danger">{startDateErr[key]}</p>
                    })} */}
                </div>

                <div className="field">
                    <label className="label">Originating Country</label>
                    <div className="control">
                        <Select
                            // defaultValue=""
                            onChange={e => setOriginCountry(e.value)}
                            options={getCountryList} />
                    </div>
                </div>
                {Object.keys(originCountryErr).map((key) => {
                    return <p className="help is-danger">{originCountryErr[key]}</p>
                })}

                <div className="field is-grouped">
                    <div className="control">
                        <button className="button is-link" type="submit">Create Mother Plant</button>
                    </div>
                    {/* <div className="control">
                        <button className="button is-link is-light">Cancel</button>
                    </div> */}
                </div>

            </form>


        </div>
    )
}

export default LabsMotherPlantRegister