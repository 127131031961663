import React, { useState, useEffect, useCallback } from 'react'
import postActivity from "../../services/activities/postActivity"
import getFarmLists from "../../services/farms/getFarmList"
import getUsers from "../../services/users/getUsers"

import NotificationError from "../../components/NotificationError/NotificationError"
import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"
// import { Description, Schedule } from '@material-ui/icons'
// import { faHome } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DatePicker from 'react-datepicker'
import Select from 'react-select'
import activityTypes from "../../constants/activityTypes"

import './Activities.scss'

const ActivitesAddForm = (props) => {

    props.validateSession()

    console.log("props:")
    console.log(props)

    console.log(activityTypes)

    const [apiResponse, setApiResponse] = useState("");

    const [farm, setFarm] = useState("");
    const [userHash, setUserHash] = useState();
    const [farmLists, setFarmLists] = useState([])
    const [userLists, setUserLists] = useState([])

    const [activityType, setActivityType] = useState("onboarding");
    const [title, setTitle] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [allDay, setAllDay] = useState("0");

    console.log(setAllDay)

    const [defaultActivityType] = useState(activityTypes.find((type) => type.value === activityType))

    const [activityTypeErr, setActivityTypeErr] = useState({});
    const [titleErr, setTitleErr] = useState({});
    const [startDateErr, setStartDateErr] = useState({});
    const [endDateErr, setEndDateErr] = useState({});
    const [allDayErr, setAllDayErr] = useState({});

    const [displayFarm, setDisplayFarm] = useState();

    // const initialValues = ['1', '0']

    useEffect(() => {
        setDisplayFarm('')
        const getFarmList = async () => {
            let result = await getFarmLists()
            console.log("DEBUG/getFarmLists:", result)

            var listAll = []
            result.filter((farm) => farm.user_hash === userHash).forEach(el => {
                console.log('elements', el)
                listAll.push({
                    value: el.id,
                    label: el.farm_name + " by (" + el.full_name + ", " + el.organization + ")"
                })
            })

            setFarmLists(listAll)
        }
        if (userHash)
            getFarmList()
    }, [userHash]);

    useEffect(() => {
        const getUserList = async () => {
            let result = await getUsers()

            var listAll = []
            result.forEach(el => {
                listAll.push({
                    value: el.hash,
                    label: "" + el.mobile_country_code + "-" + el.mobile_number + " - " + el.first_name + " " + el.last_name + " (" + el.organization + ")"
                })
            })

            setUserLists(listAll)
        }

        getUserList()
    }, []);

    const setSelectedFarm = useCallback((selected) => {
        console.log(selected)
        setFarm(selected)
        setDisplayFarm(farmLists.find((val) => val.value === selected))
    },
        [farmLists],
    )

    const handleCalendarClose = () => console.log("Calendar closed");
    const handleCalendarOpen = () => console.log("Calendar opened");

    const formValidation = () => {
        const templateTypeErr = {}
        const titleErr = {}
        const startDateErr = {}
        const endDateErr = {}
        const allDayErr = {}

        let isValid = true

        if (activityType === "") {
            templateTypeErr.required = "This field is required."
            isValid = false
        }

        if (title === "") {
            titleErr.required = "This field is required."
            isValid = false
        }

        if (startDate === "") {
            startDateErr.required = "This field is required."
            isValid = false
        }

        if (endDate === "") {
            endDateErr.required = "This field is required."
            isValid = false
        }

        if (allDay === "") {
            allDayErr.required = "This field is required."
            isValid = false
        }


        setActivityTypeErr(templateTypeErr)
        setTitleErr(titleErr)
        setStartDateErr(startDateErr)
        setEndDateErr(endDateErr)
        setAllDayErr(allDayErr)
        return isValid
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const isValid = formValidation()

        if (isValid) {
            console.log("Form Valid: Processing on Backend")

            processBackend()

            // if (res.status == 200) {
            // window.location.href = "/users"
            // props.router.push("/users")
            // }

            e.preventDefault();
        } else {
            console.log("Form Invalid: Please check form")
        }
    }

    const processBackend = async () => {
        let request = {
            userHash: userHash,
            farmId: farm,
            activityType: activityType,
            title: title,
            startDate: startDate,
            endDate: endDate,
            allDay: allDay,
        }

        request.start_datetime = new Date(startDate.getTime() + Math.abs(startDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);
        request.end_datetime = new Date(endDate.getTime() + Math.abs(endDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);

        console.log("DEBUG/request: ", request)

        try {
            let res = await postActivity(request)
            HandleResponse(res)
        } catch (error) {
            HandleResponse(error);
        }
    }

    const HandleResponse = (res) => {

        console.log("HandleResponse.res:")
        console.log(res)
        console.log(res.status)

        if (res.status === 200) {
            setApiResponse("success")
        } else {
            setApiResponse("error")
        }

    }

    return (
        <div className="container">
            <p>&nbsp;</p>

            <h1 className="title">Create Single Activity</h1>
            <h2 className="subtitle">Create or Edit</h2>

            {apiResponse === "success" && <NotificationSuccess setMessage={setApiResponse} message="Successfully processed your request" />}
            {apiResponse === "error" && <NotificationError setMessage={setApiResponse} message="There was an error processing your request." />}

            <form onSubmit={handleSubmit}>

                <div className="field">
                    <label className="label">Which User</label>
                    <div className="control">
                        <Select
                            // defaultValue=""
                            onChange={e => setUserHash(e.value)}
                            options={userLists} />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Which Farm</label>
                    <div className="control">
                        <Select
                            isDisabled={!userHash}
                            value = {displayFarm}
                            onChange={e => setSelectedFarm(e.value)}
                            options={farmLists} />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Activity Type</label>
                    <div className="control">
                        {
                            defaultActivityType ? <Select
                                // defaultValue={defaultActivityType}
                                onChange={(val) => setActivityType(val.value)}
                                options={activityTypes} /> : ''}
                    </div>
                    {Object.keys(activityTypeErr).map((key) => {
                        return <p className="help is-danger">{activityTypeErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Title</label>
                    <div className="control">
                        <input name="title" className="input" type="text" placeholder="Title"
                            value={title}
                            onChange={e => setTitle(e.target.value)} />
                    </div>
                    {Object.keys(titleErr).map((key) => {
                        return <p className="help is-danger">{titleErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Start Date</label>
                    <div className="control">
                        <DatePicker
                            selected={startDate}
                            // selected={date}
                            onChange={
                                date => setStartDate(date)
                            }
                            onCalendarClose={handleCalendarClose}
                            onCalendarOpen={handleCalendarOpen}
                            dateFormat="yyyy/MM/dd"
                        />
                    </div>
                    {Object.keys(startDateErr).map((key) => {
                        return <p className="help is-danger">{startDateErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">End Date</label>
                    <div className="control">
                        <DatePicker
                            selected={endDate}
                            // selected={date}
                            onChange={
                                date => setEndDate(date)
                            }
                            onCalendarClose={handleCalendarClose}
                            onCalendarOpen={handleCalendarOpen}
                            dateFormat="yyyy/MM/dd"
                        />
                    </div>
                    {Object.keys(endDateErr).map((key) => {
                        return <p className="help is-danger">{endDateErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">All Day</label>

                    <div className="control">

                    </div>
                    <div className="control">
                        <label className="radio">
                            <input checked="1" type="radio" name="allDay" value="1" /> Yes
                        </label>
                        <label className="radio">
                            <input type="radio" name="allDay" value="0" /> No
                        </label>
                    </div>
                    {Object.keys(allDayErr).map((key) => {
                        return <p className="help is-danger">{allDayErr[key]}</p>
                    })}
                </div>

                {/* {apiResponse === "success" && <NotificationSuccess setMessage={setApiResponse} message="Successfully processed your request" />}
                {apiResponse === "error" && <NotificationError setMessage={setApiResponse} message="There was an error processing your request." />} */}

                <div className="field is-grouped">
                    <div className="control">
                        <button className="button is-link" type="submit">Create</button>
                    </div>
                    {/* <div className="control">
                        <button className="button is-link is-light">Cancel</button>
                    </div> */}
                </div>

            </form>



        </div>
    )
}

export default ActivitesAddForm
