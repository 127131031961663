import React, { useEffect, useState } from 'react'
import { Steps, Step } from "react-step-builder";
import StepBottleScan from '../LabsReportMortality/StepBottleScan';
import StepScanResult from './StepScanResult'
import ModalComponent from "../../components/ModalComponent/ModalComponent"
import LabsReportMortality from "../LabsReportMortality/LabsReportMortality"
// import { Link } from "react-router-dom";

const LabsReportContamination = (props) => {


    const [loading, isLoading] = useState(false)
    const [finishedScan, toggleFinishedScan] = useState(false)
    const [barCode, setBarcode] = useState('')
    const loadingText = 'Loading..'
    const [scanResult, setScanResult] = useState()
    const [plantPci, setPlantPci] = useState('')
    const [plantNode, setPlantNode] = useState('')

    props.validateSession()

    useEffect(() => {
        console.log('check bottle qq', scanResult)
        if (scanResult?.length > 0) {
            setPlantPci(scanResult[1].exPlant[0].pci)
            setPlantNode(scanResult[1].exPlant[0].node)
            toggleFinishedScan(true)
        }
    }, [scanResult])

    return (
        <div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h1 className="title has-text-centered">Check Bottle</h1>
            <div className="box">
                {loading && <ModalComponent display={loadingText}></ModalComponent>}
                <Steps>
                    <Step
                        isLoading={isLoading}
                        toggleFinishedScan={toggleFinishedScan}
                        finishedScan={finishedScan}
                        setScanResult={setScanResult}
                        barCode={barCode}
                        setBarcode={setBarcode}
                        stepLabel={'Scan Bottle Barcode'}
                        scanType={'checkBottle'}
                        component={StepBottleScan} />
                    <Step
                        code={barCode}
                        pci={plantPci}
                        node={plantNode}
                        component={StepScanResult}
                    />
                    <Step
                        bottleCode={barCode}
                        component={LabsReportMortality}
                    />
                </Steps>
            </div>
        </div>
    )
}

export default LabsReportContamination
