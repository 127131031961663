import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import getTemplates from "../../services/activities/templates/getTemplates"
import './ActivityTemplates.scss'

const ActivityTemplates = () => {

    const [results, setResults] = useState([])
    const [loadedData, setLoadedData] = useState(false)
    useEffect(() => {
        const getTemplate = async () => {
            let result = await getTemplates()
            console.log("DEBUG/RESULT:", result)
            setResults(result)
            setLoadedData(true)
        }

        getTemplate()
    }, []);

    return (
        <div className="container">

            {/* <Button color="primary">My Bulma button</Button> */}

            <p>&nbsp;</p>

            <h1 className="title">List Of All Templates</h1>
            <h2 className="subtitle">Parent template that has individual actions</h2>

            <nav className="level">
                <div className="level-right">
                    <Link to="/activities/templates/create" className="button is-success">Create New Template</Link>
                </div>

                <div className="level-left">
                    <div className="level-item">
                        <p className="subtitle is-5">
                            <strong>{results.length}</strong> records
                        </p>
                    </div>
                    <div className="level-item">
                        <div className="field has-addons">
                            <p className="control">
                                <input className="input" type="text" placeholder="Find a activity" />
                            </p>
                            <p className="control">
                                <button className="button">
                                    Search
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </nav>

            <div className='list'>
                <ul>
                    {loadedData === true ?
                        results.map((result, index) => {
                            return (
                                <div className='list-item' key={index}>
                                    <div className="box">
                                            <article className="media">
                                                <div className="media-left">
                                                    <Link to={{ pathname: '/activities/scheduler', state: {
                                                        templateType: result.template_type,
                                                        }}} className="button is-link">View Actions</Link>
                                                </div>
                                                <div className="media-content">
                                                    <div className="content">
                                                        {result.template_type}
                                                    </div>
                                                    <nav className="level is-mobile">
                                                        <div className="level-left">
                                                        </div>
                                                    </nav>
                                                </div>
                                            </article>
                                        </div>

                                </div>
                            )
                        }) : <div className="sections"><div className="section-header">Loading..</div></div>}
                </ul>
            </div>



        </div>
    )
}

export default ActivityTemplates
