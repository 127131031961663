import axios from "axios";

const transferRootStage = async (code, hash) => {
    console.log("DEBUG/transferRootStage:")
    console.log(code)

    return new Promise((resolve, reject) => {
        axios.put
            (process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ADMIN_PLANTS_TRANSFER_ROOT + code,
                {
                    user_hash: hash
                },
                {
                    headers: {
                        "Authorization": "Bearer " + process.env.REACT_APP_API_SEC_BEARER,
                        "Content-Type": "application/json",
                    }
                },
            )
            .then(response => {
                console.log("transferPlantStage.response:")
                console.log(response)
                resolve(response)
            })
            .catch(error => {
                console.log("transferPlantStage.error:")
                console.log(error)
                resolve(error)
            })
    })
}

export default transferRootStage