import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import getLessons from "../../services/handbooks/lessons/getLessons"
import getImage from "../../services/handbooks/image/getImage"
import deleteLesson from "../../services/handbooks/lessons/deleteLesson"
import imagePlaceholder from "../../assets/images/placeholders/image_placeholder.png"

import './HandbookLessons.scss'

const HandbookLessons = (props) => {

    const [loadingState, setLoadingState] = useState(true);
    const [lessons, setLessons] = useState();
    const [handbookHash] = useState(props.location.state.handbookHash)
    const [handbookTitle] = useState(props.location.state.handbookTitle)

    useEffect(() => {
        const getLesson = async () => {
            let lessons = await getLessons(handbookHash)
            if (lessons.length > 0) {
                setLessons(lessons)
            }
            setLoadingState(false)
        }
        getLesson();
    }, [handbookHash]);

    const retrieveImage = async (id) => {
        let image_link = await getImage(id)
        
        if (image_link)
            return image_link
    }

    useEffect(() => {
        if(lessons?.length > 0){
            lessons.forEach((lesson) => {
                retrieveImage(JSON.parse(lesson.content)[0].media_reference).then((imageLink) =>{
                    var DOM_img = document.createElement("img");
                    var DOM_video = document.createElement("video");
                    if(imageLink.s3_location && imageLink.mime_type.includes('image')){
                        console.log(imageLink.mime_type)
                        DOM_img.src = imageLink.s3_location;
                        document.getElementById(`image-${JSON.parse(lesson.content)[0].media_reference}-${lesson.id}`).appendChild(DOM_img)
                    }
                    else if(imageLink.s3_location && imageLink.mime_type.includes('video')){
                        console.log(imageLink.mime_type)
                        DOM_video.src = imageLink.s3_location
                        document.getElementById(`image-${JSON.parse(lesson.content)[0].media_reference}-${lesson.id}`).appendChild(DOM_video)
                    }
                    else{
                        DOM_img.src = imagePlaceholder;
                        document.getElementById(`image-null-${lesson.id}`).appendChild(DOM_img)
                    }
                    
                })
            })
        }
    }, [lessons]);

    return (
        <div className='container'>

            <p>&nbsp;</p>

            <h1 className="title">Handbook Lessons</h1>
            <h2 className="subtitle">List of Lessons for {handbookTitle}</h2>

            <nav className="level">
                <div className="level-right">
                    <div className="field is-grouped">
                        <div className="buttons">
                            <Link to={{ pathname: "/handbooks/lessons/create", state: { handbookHash: handbookHash, handbookTitle: handbookTitle } }} className="button is-primary">Create Lesson</Link>
                            <Link to={{ pathname: "/handbooks/tools" }} className="button is-info is-light">View Tools</Link>
                        </div>
                    </div>
                </div>
                <div className="level-left">
                    <div className="level-item">
                        <p className="subtitle is-5">
                            <strong>{lessons?.length}</strong> Record/s
                        </p>
                    </div>
                    <div className="level-item">
                        <div className="field has-addons">
                            <p className="control">
                                <input className="input" type="text" placeholder="Find a lesson" />
                            </p>
                            <p className="control">
                                <button className="button" disabled>
                                    Search
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </nav>



            <ul>
                {!loadingState ? <div>
                    <h1>List of lessons for {handbookTitle}</h1>
                    {lessons ? lessons.map(((lesson, key) => {
                        return (
                            <div className='list-item' key={key}>
                                <div className="box">
                                    <article className="media">
                                        <div className="media-left">
                                            <div className="buttons">

                                                <Link to={{ pathname: `/handbooks/lessons/update/${lesson.id}`, state: { lesson, handbookTitle: handbookTitle } }} className="button is-link">Edit</Link>
                                                <button
                                                    onClick={async () =>
                                                        await deleteLesson(lesson.id).then(res => {
                                                            setLoadingState(true)
                                                            if (res) {
                                                                window.location.reload(false);
                                                            }
                                                        })
                                                    }
                                                    type="button"
                                                    className="button is-danger is-light">Delete</button>
                                            </div>
                                        </div>
                                        <div className="media-content">
                                            <div className="content">
                                                <h2>{lesson.title}</h2>
                                            </div>
                                            <nav className="level is-mobile">
                                                <div className="level-left">
                                                </div>
                                            </nav>
                                        </div>
                                        <div className="media-right">
                                            <figure className="image is-64x64" id = {`image-${JSON.parse(lesson.content)[0].media_reference}-${lesson.id}`}>
                                            </figure>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        )

                    })) : <div><h4>No lesson created for this section yet</h4></div>}
                </div>
                    :
                    <div><h4>Loading..</h4>
                    </div>}

            </ul>
        </div>
    )
}

export default HandbookLessons