import axios from "axios";

const retrieveRawData = async () => {
    console.log("DEBUG/retrieveRawData:")
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ADMIN_STATISTICS_RAW_DATA, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_API_SEC_BEARER}`
            }
        }).then(async res => {
            console.log("service.retrieveRawData():")
            console.log(res)
            var result = res.data.data
            resolve(result)
        }).catch(err => {
            console.log("service.retrieveRawData():")
            console.log(err)
            resolve(false)
        })
    })
}

export default retrieveRawData