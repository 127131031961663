import axios from "axios";

const auditTrail  = async (data) => {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_HOST + process.env.REACT_APP_AUDIT_TRAIL,
            {
                type: data.type,
                user_hash: data.user_hash,
                activity: data.activity,
                data: data.data,
            },
            {
                headers: {
                    'Authorization': 'Bearer 4b58c6b1d51f0b45c5426d250324a6c49bb81c5e893b877ebe917f8bf5098a17'
                }
            }).then(async res => {
                resolve(res)
            }).catch(err => {
                console.log("service.auditTrail():")
                console.log(err)
                resolve(err)
            })
    })
}

export default auditTrail 
