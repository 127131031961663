// Step1.js
import React, { useState } from 'react'
import { useJwt } from "react-jwt";
import { Cookies } from 'react-cookie';

import auditTrail from "../../services/auditTrail ";

function StepsCulture(props) {

    console.log('qqq',props)

    const cookies = new Cookies();
    const authToken = cookies.get('authSession')
    const signed_user_hash = useJwt(authToken).decodedToken?.data?.hash

    const [results] = useState(props?.pendingPlants)
    const handleAuditTrail = async (audit_type, data = null) => {
        let auditData = {
            type: "plant_registration",
            user_hash: signed_user_hash,
            activity: audit_type === 'continue' ? "Started: Continue Batch Registration on Step 2-2" : "Started: Cancel Batch Registration",
            data: JSON.stringify(results[0])
        }
        await auditTrail(auditData)
    }

    return (

        <div className="container">

            <p>&nbsp;</p>
            {props.pendingPlants[0].status !== 'pending-update' ? <h1 className="title">Continue Batch Registration</h1> : <h1 className="title">Continue Update Plant Stage</h1>}
            {/* <h2 className="subtitle"></h2> */}

            {results?.length > 1 ?
                <div className='buttons'>
                    <ul>{
                        results?.map((result, index) => {
                            return (
                                <div key={index}>
                                    <button className="button is-info is-large" onClick={() => {
                                        props.setBatchStatus(true)
                                        props.setState('batchData', result)
                                        props.next()
                                    }
                                    }>Continue</button>
                                    <button className="button is-danger is-large" onClick={() => {
                                        props.setBatchStatus(false)
                                        props.setState('registrationId', result.registration_id)
                                        props.next()
                                    }
                                    }>Cancel</button>
                                    {props.pendingPlants[0].registration_type !== 'pending-update' ? <span>
                                        {index + 1} {result.date_created.slice(0, 10)}: New Batch ({result.propagation_method})
                                    </span> : ''}
                                </div>
                            )
                        })}
                    </ul>
                </div> :
                <div>
                    {props.pendingPlants[0].status !== 'pending-update' && <h2 className="subtitle">New Batch ({props.pendingPlants[0].propagation_method})</h2>}
                    <br></br>
                    <div className="columns is-centered">
                        <button className="button is-info is-large" onClick={() => {
                            handleAuditTrail('continue');
                            props.setBatchStatus(true)
                            props.setState('batchData', results[0])
                            props.next()
                        }
                        }> {props.pendingPlants[0].status !== 'pending-update' ? "Continue Batch Registration" : "Continue Update Plant Stage"}</button>
                    </div>
                </div>

            }

            <p>&nbsp;</p>
            {results?.length === 1 && <div className="buttons">
                <button className="button button is-danger is-light is-medium" onClick={() => {
                    handleAuditTrail('cancel');
                    props.setBatchStatus(false)
                    props.setState('registrationId', results[0].registration_id)
                    props.next()
                }}>
                    {props.pendingPlants[0].status !== 'pending-update' ? 'Cancel Batch Registration' : 'Cancel Update Plant Stage'}

                </button>
            </div>}

        </div>
    );
}

export default StepsCulture;