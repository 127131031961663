import React, { useEffect, useState } from 'react'

const LabsPlantProductionTableBody = ({
    displayText,
    loading,
    headers,
    data,
    next,
    setSelectedData,
    type,
    tableStyle }) => {

    const [totalDays, setTotalDays] = useState()
    useEffect(() => {
        if (data) {
            const sum = data.reduce((partial_sum, a) => partial_sum + a.days_in_stage, 0);
            setTotalDays(sum)
        }
    }, [data])

    return (
        <>
            {
                !loading && data ?
                    data?.map((info, i) => {
                        return (
                            <tbody key={info.id}>
                                <tr>
                                    <td>{info.date_of_inoculation.slice(0, 10)}</td>
                                    <td>{info.batch_number}</td>
                                    <td>S{info.stage}</td>
                                    <td>{info.days_in_stage}</td>
                                </tr>
                            </tbody>
                        )

                    })
                    :
                    <tbody>
                        <tr>
                            <td>{displayText}</td>
                        </tr>
                    </tbody>
            }
            {
                !loading && data ?
                    <tbody>
                        <tr>
                            <td>Total Production Time</td>
                            <td></td>
                            <td></td>
                            <td>{totalDays}</td>
                        </tr>
                    </tbody>
                    :
                    null
            }
        </>
    )
}

export default LabsPlantProductionTableBody
