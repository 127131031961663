
import React from 'react'
import './Footer.scss'

const Footer = () => {



    return (
        <footer className="footer mt-*6">
            <p>&nbsp;</p>
            <div className="content has-background-light has-text-centered">
                <p>
                    &copy; 2021 <strong>VD-Website Manager</strong>
                </p>
            </div>
        </footer>
    )
}

export default Footer