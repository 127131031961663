import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import LabsBarcodeScannerField from '../../components/Labs/LabsBarcodeScannerField'
import checkBottle from '../../services/plants/bottles/getBottleByIdentifier'
import checExPlantBottle from '../../services/plants/bottles/getBottleByIdentifierUpdate'
import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"
import NotificationError from "../../components/NotificationError/NotificationError"

function StepBottleScan(props) {

    const [apiResponse, setApiResponse] = useState('')
    const [successfulScan, setSuccessfulScan] = useState()

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (props.barCode && !props.finishedScan) {
                props.isLoading(true)
                var bottleScan
                if (props.scanType === 'checkBottle') {
                    bottleScan = await checkBottle(props.barCode)
                } else {
                    bottleScan = await checExPlantBottle(props.barCode)
                }
                if (bottleScan[1]?.exPlant?.length > 0 || bottleScan?.data?.data?.length > 0) {
                    props.isLoading(false)
                    props.scanType === 'checkBottle' ? props.setScanResult(bottleScan) : props.setScanResult(bottleScan?.data?.data)
                    setApiResponse('Barcode found!')
                    setSuccessfulScan(true)
                    props.next()
                }
                else {
                    props.isLoading(false)
                    setApiResponse('Barcode has no transfer stage yet')
                    setSuccessfulScan(false)
                }
            }
        }, 500)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line  
    }, [props.barCode])

    const triggerRescan = () => {
        props.toggleFinishedScan(false)
        props.setBarcode('')
    }

    return (
        <div className="content container">

            {apiResponse && successfulScan ? <NotificationSuccess setMessage={setApiResponse} message={apiResponse} /> : <NotificationError setMessage={setApiResponse} message={apiResponse && `${apiResponse} [${props.barCode}]`} />}
            <h4 className="title is-4">Scan Bottle Barcode</h4>
            {!props.finishedScan ? <LabsBarcodeScannerField barCode={props.barCode} setBarcode={props.setBarcode} />
                :
                <div>
                    <h4>Scanned Code: {props.barCode}</h4><br />
                    <div className="columns is-centered">
                        <button className="button is-info is-large"
                            onClick={() => triggerRescan()
                            }
                        >Scan Again</button>
                    </div>
                </div>}

            <div>
                <Link to="/labs/dashboard" className="button is-info is-light is-large">Go Back</Link>
            </div>
        </div>
    )
}

export default StepBottleScan;