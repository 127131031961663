import React from 'react'

const DataDisplay = ({ label, data }) => {

    return (
        <div>
            {label} : {data}
        </div>
    )
}

export default DataDisplay
