import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import getQuizzes from "../../services/handbooks/quiz/retrieve"
import deleteQuiz from "../../services/handbooks/quiz/delete"

const HandbookQuizList = (props) => {

    console.log(props)
    const handbookID = useState(props.location.state?.handbookID)
    const [loadedData, setLoadedData] = useState(false)
    const [quizzes, setQuizzes] = useState([])


    useEffect(() => {
        const getHandbookQuizzes = async () => {
            let fetchQuiz = await getQuizzes(props.location.state?.handbookID)
            setQuizzes(fetchQuiz)
            setLoadedData(true)
        }
        console.log('called')
        getHandbookQuizzes();
    }, [props]);

    return (
        <div className="container">
            <p>&nbsp;</p>
            <h1 className="title">Handbook Quizzes</h1>
            <h2 className="subtitle">List of Quizzes for {props.location.state?.handbookTitle}</h2>

            <nav className="level">
                <div className="level-right">
                    <div className="field is-grouped">
                        <div className="control">
                            <Link to={{ pathname: "/handbooks/quiz/create", state: { handbookID: handbookID[0], handbookTitle: props.location.state?.handbookTitle } }} className="button is-link is-light">Create Quiz</Link>
                        </div>
                    </div>
                </div>
                <div className="level-left">
                    <div className="level-item">
                        <p className="subtitle is-5">
                            <strong>{quizzes?.length}</strong> Record/s
                        </p>
                    </div>
                    <div className="level-item">
                        <div className="field has-addons">
                            <p className="control">
                                <input className="input" type="text" placeholder="Find a tool" />
                            </p>
                            <p className="control">
                                <button className="button">
                                    Search
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </nav>

            <div className='list'>
                <ul>
                    {loadedData ? <div>
                        {quizzes ? quizzes.map(((quiz, key) => {
                            return (
                                <div className='list-item' key={key}>
                                    <div className="box">
                                        <article className="media">
                                            <div className="media-left">
                                                <div className="field is-grouped">
                                                    <div className="control">
                                                        <Link className="button is-link is-light" to={{ pathname: "/handbooks/quiz/update", state: { updateData: quiz } }}>Edit</Link>
                                                    </div>
                                                    <div className="control">
                                                        <button
                                                            onClick={async () =>
                                                                await deleteQuiz(quiz.id).then(res => {
                                                                    setLoadedData(false)
                                                                    if (res) {
                                                                        window.location.reload(false);
                                                                    }
                                                                })
                                                            }
                                                            type="button"
                                                            className="button is-danger is-light">Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="media-content">
                                                <div className="content">
                                                    <h2>{quiz.title}</h2>
                                                </div>
                                                <nav className="level is-mobile">
                                                    <div className="level-left">
                                                    </div>
                                                </nav>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            )

                        })) : <div><h4>No quiz created for this section yet</h4></div>}
                    </div>
                        :
                        <div><h4>Loading..</h4>
                        </div>}

                </ul>
            </div>
        </div>
    )
}

export default HandbookQuizList