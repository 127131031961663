import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import getActivitiesByFarmUser from "../../services/activities/getActivitiesByFarmUser"
import deleteActivity from "../../services/activities/deleteActivity"
import NotificationError from "../../components/NotificationError/NotificationError"
import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"
import Moment from 'react-moment';
import './Activities.scss'

const RetrieveByFarmUser = (props) => {

    console.log("DEBUG///XXX;", props)

    const [results, setResults] = useState([])
    const [loadedData, setLoadedData] = useState(false)
    
    const [farmId] = useState(props.location.state.farmId);
    const [userHash] = useState(props.location.state.userHash);

    const [apiResponse, setApiResponse] = useState("");


    const getFarmLists = async (farmId, userHash) => {
        let result = await getActivitiesByFarmUser(farmId, userHash)
        setResults(result)
        setLoadedData(true)
    }

    useEffect(() => {

        getFarmLists(farmId, userHash)
    }, [farmId, userHash]);

    return (
        <div className="container">

            {/* <Button color="primary">My Bulma button</Button> */}

            <p>&nbsp;</p>

            <h1 className="title">Activities | By Farm</h1>
            <h2 className="subtitle">View events by farm</h2>

            {apiResponse === "success" && <NotificationSuccess setMessage={setApiResponse} message= "Successfully processed your request"/>}
            {apiResponse === "error" && <NotificationError setMessage={setApiResponse} message="There was an error processing your request." />}

            <nav className="level">
                <div className="level-right">
                    {/* <p className="level-item"><a href="/#" className="button is-success">New Single Activity</a></p>
                    <p className="level-item">
                        <a href="/#" className="button is-success">Generate Activities</a>
                    </p> */}

                    {/* <p className="level-item"><strong>All</strong></p>
                    <p className="level-item"><a href="/#">Active</a></p>
                    <p className="level-item"><a href="/#">Pending</a></p>
                    <p className="level-item"><a href="/#">Completed</a></p> */}
                </div>

                <div className="level-left">
                    <div className="level-item">
                        <p className="subtitle is-5">
                            <strong>{results.length}</strong> record/s
                        </p>
                    </div>
                    <div className="level-item">
                        <div className="field has-addons">
                            <p className="control">
                                <input className="input" type="text" placeholder="Find activity" />
                            </p>
                            <p className="control">
                                <button className="button">
                                    Search
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </nav>

            <div className='list'>
                <ul>
                    {loadedData === true ?
                        results.map((result, index) => {
                            return (
                                <div className='list-item' key = {index}>
                                    <div className="box">
                                            <article className="media">
                                                <div className="media-left">
                                                    <div className="buttons">
                                                        <Link to={{
                                                            pathname: '/activities/events/update/' + result.hash
                                                        }} className="button is-link">Update</Link>
                                                        <button className="button is-danger" onClick={async () =>
                                                        await deleteActivity(result.hash).then(res => {
                                                            setLoadedData(false)
                                                            if (res) {
                                                                if (res.status === 200) {
                                                                    setApiResponse("success")
                                                                } else {
                                                                    setApiResponse("error")
                                                                }

                                                                getFarmLists(farmId, userHash)
                                                            }
                                                        })
                                                    } >Delete</button>
                                                    </div>
                                                    
                                                </div>
                                                <div className="media-content">
                                                    <div className="content">
                                                        <p>
                                                            <strong>{result.title}</strong>
                                                            <br />
                                                            <small>
                                                                <span>{result.description}</span>
                                                                Start: {result.start_datetime} (<Moment durationFromNow ago>{result.start_datetime}</Moment>)<br />
                                                                End: {result.end_datetime}(<Moment durationFromNow ago>{result.end_datetime}</Moment>)<br />
                                                                All-Day: {result.all_day}<br />
                                                                Status: {result.status}<br />
                                                                Created: <Moment durationFromNow ago>{result.date_created}</Moment><br />
                                                            </small>
                                                        </p>
                                                    </div>
                                                    <nav className="level is-mobile">
                                                        <div className="level-left">
                                                        </div>
                                                    </nav>
                                                </div>
                                                <div className="media-right">
                                                    <figure className="image is-64x64">
                                                        <img src="https://bulma.io/images/placeholders/128x128.png" alt="Profile" />
                                                    </figure>
                                                </div>
                                            </article>
                                        </div>

                                </div>
                            )
                        }) : <div className="sections"><div className="section-header">Loading..</div></div>}
                </ul>
            </div>



        </div>
    )
}

export default RetrieveByFarmUser
