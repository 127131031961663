import React, { useState, useEffect } from 'react'
import { useJwt } from "react-jwt";
import { Cookies } from 'react-cookie';

import checkBottle from '../../../services/plants/bottles/getBottleByIdentifierUpdate'
import NotificationSuccess from "../../../components/NotificationSuccess/NotificationSuccess"
import NotificationError from "../../../components/NotificationError/NotificationError"

function StepUpdateBottleScan(props) {

    const cookies = new Cookies();
    const authToken = cookies.get('authSession')
    const signed_user_hash = useJwt(authToken).decodedToken?.data?.hash

    useEffect(() => {
        if (signed_user_hash)
            props.setState('userHash', signed_user_hash)
        // eslint-disable-next-line  
    }, [signed_user_hash])

    const [code, setCode] = useState(props.state?.update_code ? props.state.update_code : '')
    const [successfulScan, setSuccessfulScan] = useState()
    const [apiResponse, setApiResponse] = useState('')

    const [reScan, toggleRescan] = useState(false)
    const [failedScan, setFailedScan] = useState(false)

    useEffect(() => {
        window.addEventListener('keydown', e => {
            if (document.getElementById('scanResultArea')) {
                document.getElementById('scanResultArea').style.display = 'block'
            }
            if (document.getElementById('barcode')) {
                document.getElementById('barcode').setAttribute('type', 'text')
                document.getElementById('barcode').focus()
            }
        })
    }, [])

    console.log(props, props?.updateData?.length)
    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (code) {
                let bottleScan = await checkBottle(code)
                if (bottleScan?.data?.error_code === 0) {
                    if (props?.updateData?.length > 0) {
                        let updateData = {"updateData": bottleScan?.data?.data}
                        props.setUpdateData([...props?.updateData, updateData]);
                        setApiResponse('Barcode found!')
                        setSuccessfulScan(true)
                        props.next()
                    }
                    else {
                        let updateData = {"updateData":bottleScan?.data?.data}
                        props.setUpdateData([updateData]);
                        setApiResponse('Barcode found!')
                        setSuccessfulScan(true)
                        props.next()
                    }
                }
                else {
                    setApiResponse('Barcode has no transfer stage yet')
                    setFailedScan(true)
                    setSuccessfulScan(false)
                }
            }
        }, 500)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line  
    }, [code])

    const divStyle = {
        display: 'none'
    };
    return (
        <div className="content container">

            <p>&nbsp;</p>
            <p>&nbsp;</p>
            {apiResponse && successfulScan ? <NotificationSuccess setMessage={setApiResponse} message={apiResponse} /> : <NotificationError setMessage={setApiResponse} message={apiResponse && `${apiResponse} [${code}]`} />}
            <h1 className="title has-text-centered">Update Plant Stage</h1>

            {
                failedScan &&

                <div className="columns is-centered">
                    <button className="button is-info is-large"
                        onClick={() => {
                            setFailedScan(!failedScan)
                            toggleRescan(!reScan)
                            setCode('')
                            setApiResponse(null)
                            document.getElementById('barcode').focus()
                        }
                        }
                    // disabled={stepDone}
                    >Scan Again</button>
                </div>
            }
            <h4 className="title is-4">Scan next bottle for transfer update</h4>
            <div id="scanResultArea" style={divStyle}>
                <p>Scanned Code:
                <input
                        autoFocus
                        value={code}
                        id="barcode"
                        onChange={(e) => setCode(e.target.value)}
                        className="input"
                        type="hidden"
                    // disabled={stepDone}
                    ></input></p>
                {/* <div>
                    <Link to="/labs/dashboard" className="button is-info is-light is-large">Go Back</Link>
                </div> */}
            </div>
        </div>
    )
}

export default StepUpdateBottleScan;