import React, { useEffect, useState } from 'react'
import LabsEditForm from '../../components/Labs/LabsEditForm'
import Button from '../../components/Button/Button'


import getMotherList from '../../services/plants/mother/getMotherList'
import getUsers from "../../services/users/getUsers"
import manualUpdatePlant from '../../services/plants/manualUpdatePlant'



const StepPlantDisplay = (props) => {

    const [editData, setEditData] = useState(props.selectedData)

    const [motherOptions, setMotherOptions] = useState()
    const [userOptions, setUserOptions] = useState()


    useEffect(() => {
        const retrieveMotherList = async () => {
            let mothers = await getMotherList()
            setMotherOptions(mothers)
        }
        const retrieveAllUsers = async () => {
            let users = await getUsers()
            setUserOptions(users)
            console.log('users', users)
        }
        retrieveMotherList();
        retrieveAllUsers();
    }, [])

    const submitEditData = async () => {

        const hash = userOptions.find(user => `${user.first_name} ${user.last_name}` === editData?.name_inoculator || editData?.name_inoculator_name).hash
        editData.userHash = hash

        if (typeof editData.mother_plant_source === 'string' || typeof editData.name_inoculator === 'string') {
            let mother = motherOptions.find(mother => mother.unique_code === editData.mother_plant_source || editData.mother_plant_source_name)
            let user = userOptions.find(user => `${user.first_name} ${user.last_name}` === editData.name_inoculator || editData.name_inoculator_name)
            editData.mother_plant_source_name = editData.mother_plant_source
            editData.name_inoculator_name = editData.name_inoculator
            editData.mother_plant_source = mother.id
            editData.name_inoculator = user.id
        }

        let editRequest = await manualUpdatePlant(editData)
        if (editRequest)
            props.next()
    }

    return (
        <div>
            <LabsEditForm motherOptions={motherOptions} userOptions={userOptions} editData={editData} setEditData={setEditData} />
            <p>&nbsp;</p>
            <div className="buttons">
                <Button
                    onClick={() => submitEditData()}
                    label={'Confirm and continue'}
                    className={'button is-primary'}
                />
                <Button
                    onClick={props.prev}
                    label={'Back'}
                    className={'button is-primary'}
                />
            </div>
        </div>
    )
}

export default StepPlantDisplay
