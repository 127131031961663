import axios from "axios";

const createSection = async (editorContent, title, handbookHash, newRecord, timeNeeded, when, notes) => {
    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_LESSONS,
                {
                    handbook_hash: handbookHash,
                    lesson_title: title,
                    title: title,
                    duration:timeNeeded,
                    content: editorContent,
                    image_id: newRecord.image_id,
                    filename: newRecord.filename,
                    when: when,
                    notes: notes,
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + process.env.REACT_APP_API_SEC_BEARER
                    }
                },
            )
            .then(response => {
                console.log('create lesson response', response)
                resolve(response.data.data)
            })
            .catch(error => {
                alert(error)
                console.log("login error", error);
                reject(error)
            });
    })
}

export default createSection