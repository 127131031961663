import axios from "axios";

const getTypesByProgram = async (program) => {
    console.log("DEBUG/getTypesByProgram:")
    console.log(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_TYPES + `/program/${program}`)

    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_TYPES + `/program/${program}`, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_API_SEC_BEARER}`
            }
        }).then(async res => {
            console.log("service.getTypesByProgram():")
            console.log(res)
            var result = res.data.data
            resolve(result)
        }).catch(err => {
            console.log("service.getTypesByProgram():")
            console.log(err)
            reject(err)
        })
    })
}

export default getTypesByProgram