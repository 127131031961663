import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import NotificationError from "../../components/NotificationError/NotificationError"
import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"
import getMotherList from "../../services/plants/mother/getMotherList"
import deleteMother from "../../services/plants/mother/deleteMother"

import './LabsMotherPlant.scss'

const LabsMotherPlantList = (props) => {

    const [apiResponse, setApiResponse] = useState("");
    const [results, setResults] = useState([])
    const [loadedData, setLoadedData] = useState(false)

    const getList = async () => {
        let result = await getMotherList()
        console.log("getMotherList:", result)
        setResults(result)
        setLoadedData(true)
    }

    useEffect(() => {
        getList()
    }, []);

    return (
        <div className="container">

            <p>&nbsp;</p>

            <h1 className="title">Mother Plant</h1>
            <h2 className="subtitle">Information on mother plants</h2>

            {apiResponse === "success" && <NotificationSuccess setMessage={setApiResponse} message="Successfully processed your request" />}
            {apiResponse === "error" && <NotificationError setMessage={setApiResponse} message="There was an error processing your request." />}

            <nav className="level">
                <div className="level-right">
                    <p className="level-item">
                        <Link to="/labs/motherplant/create" className="button is-success">New Mother Plant</Link>
                    </p>
                </div>

                <div className="level-left">
                    <div className="level-item">
                        <p className="subtitle is-5">
                            <strong>{results.length}</strong> records
                        </p>
                    </div>
                    <div className="level-item">
                        <div className="field has-addons">
                            <p className="control">
                                <input className="input" type="text" placeholder="Find activity" />
                            </p>
                            <p className="control">
                                <button className="button">
                                    Search
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </nav>

            <div className='list'>
                <ul>
                    {loadedData === true ?
                        results.map((result, index) => {
                            return (
                                <div className='list-item' key={index}>
                                    <div className="box">
                                        <article className="media">
                                            <div className="media-left">
                                                <div className="buttons">
                                                    <Link to={{
                                                        pathname: '/labs/motherplant/edit', state: {
                                                            motherPlantId: result.id,
                                                            name: result.name,
                                                            uniqueCode: result.unique_code,
                                                            originCountry: result.origin_country,
                                                            originCountryNice: result.country_nice,
                                                            dateOfBirth: result.date_of_birth
                                                        }
                                                    }} className="button is-link">Edit</Link>

                                                    <button
                                                        onClick={async () =>
                                                            await deleteMother(result.id).then(res => {
                                                                console.log("deleteMother: ", res)

                                                                setLoadedData(false)
                                                                if (res) {
                                                                    if (res.status === 200) {
                                                                        setApiResponse("success")
                                                                    } else {
                                                                        setApiResponse("error")
                                                                    }
                                                                    getList()
                                                                }
                                                            })
                                                        }
                                                        type="button" className="button is-danger is-light">Delete</button>
                                                </div>

                                            </div>
                                            <div className="media-content">
                                                <div className="content">
                                                    <p>
                                                        <strong>{result.name}</strong>
                                                        <br />
                                                        <small>
                                                            Id: {result.id}<br />
                                                            {result.unique_code && `Unique Code: ${result.unique_code}`} {result.unique_code && <br />}
                                                            Origin Country: {result.country_nice}<br />
                                                            Created: <Moment durationFromNow ago>{result.date_created}</Moment><br />
                                                        </small>
                                                    </p>
                                                </div>
                                                <nav className="level is-mobile">
                                                    <div className="level-left">
                                                    </div>
                                                </nav>
                                            </div>
                                            <div className="media-right">
                                                <figure className="image is-64x64">
                                                    <img src="https://bulma.io/images/placeholders/128x128.png" alt="Profile" />
                                                </figure>
                                            </div>
                                        </article>
                                    </div>

                                </div>
                            )
                        }) : <div className="sections"><div className="section-header">Loading..</div></div>}
                </ul>
            </div>

        </div>
    )
}

export default LabsMotherPlantList
