import axios from "axios";

const getBottleByIdentifierUpdate = async (identifier) => {
    console.log("DEBUG/getBottleByIdentifier:")
    console.log(identifier)

    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ADMIN_EXPLANT_REGISTRATION + "/" + identifier, {
            headers: {
                'Authorization': 'Bearer 4b58c6b1d51f0b45c5426d250324a6c49bb81c5e893b877ebe917f8bf5098a17'
            }
        }).then(async res => {
            console.log("service.getBottleByIdentifier():")
            console.log(res)
            resolve(res)
        }).catch(err => {
            console.log("service.getBottleByIdentifier():")
            resolve(false)
        })
    })
}

export default getBottleByIdentifierUpdate