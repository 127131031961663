import axios from "axios";

const updateBatch = async (data, id) => {
    console.log("DEBUG/updateBatch:")

    return new Promise((resolve, reject) => {
        axios.put(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ADMIN_PLANTS_BATCH_REGISTER + `/${id}`,
            {
                user_hash: data.signed_user_hash,
                lab_aid: data.lab_aid,
                laminar_flow: data.laminar_flow,
                protocol: data.protocol,
                status: 'pending-update'
            },
            {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_API_SEC_BEARER}`
                }
            }).then(async res => {
                console.log("service.updateBatch():")
                resolve(res)
            }).catch(err => {
                console.log("service.updateBatch():")
                console.log(err)
                resolve(false)
            })
    })
}

export default updateBatch