import React, { useEffect } from 'react'

const LabsBarcodeScannerField = ({
    barCode,
    setBarcode }) => {

    useEffect(() => {
        window.addEventListener('keydown', e => {
            if (document.getElementById('scanResultArea')) {
                document.getElementById('scanResultArea').style.display = 'block'
            }
            if (document.getElementById('barcode')) {
                document.getElementById('barcode').setAttribute('type', 'text')
                document.getElementById('barcode').focus()
            }
        })
    }, [])

    const divStyle = {
        display: 'none'
    };

    return (
        <div id="scanResultArea" style={divStyle}>
            <p>Scanned Code:
                <input
                    autoFocus
                    value={barCode}
                    id="barcode"
                    onChange={(e) => setBarcode(e.target.value)}
                    className="input"
                    type="hidden"
                ></input></p>
        </div>
    )
}

export default LabsBarcodeScannerField
