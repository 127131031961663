import axios from "axios";

const postTemplate = async (req) => {
    console.log("DEBUG/postTemplate:")
    console.log(req)

    return new Promise((resolve, reject) => {
        axios.post
            (process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ACTIVITIES_SCHEDULER + "/template/" + req.templateType,
                {
                    activity_type: req.activityType,
                    name: req.name,
                    description: req.description,
                    keywords: "",
                    instructions: req.instructions,
                    sequence: req.sequence,
                    schedule: req.schedule,
                    time_start: req.timeStart,
                    time_duration: req.timeDuration,
                    initial: req.initial,
                },
                {
                    headers: {
                        "Authorization": "Bearer " + process.env.REACT_APP_API_SEC_BEARER,
                        "Content-Type": "application/json",
                    }
                },
            )
            .then(response => {
                console.log("handleSubmit.response:")
                console.log(response)
                resolve(response)
            })
            .catch(error => {
                console.log("handleSubmit.error:")
                console.log(error)

                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    console.log('Error', error.message);
                }

                reject(error.message)
            })
    })
}

export default postTemplate