import React, { useState } from 'react'
import { Link } from "react-router-dom";
import cancelBatchRegistration from "../../services/plants/cancelBatchRegistration"
import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"
import NotificationError from "../../components/NotificationError/NotificationError"

function StepCancelRegistration(props) {
    const handleCancelRegistration = async (e) => {
        console.log('batch registration id to cancel', props.state.registrationId)
        let cancelBatch = await cancelBatchRegistration(props.state.registrationId, cancelNote)
        console.log('cancel result', cancelBatch)
        if(cancelBatch.status === 200){
            setApiResponse('Batch Cancelled Successfully')
        }
        else{
            setApiResponse('Batch Cancellation Failed')
        }
    }

    const [cancelNote, setCancelNote] = useState()
    const [apiResponse, setApiResponse] = useState('')

    return (
        <div className="content container">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            {apiResponse === 'Batch Cancelled Successfully' ? <NotificationSuccess setMessage = {setApiResponse}  message= {apiResponse}/> : <NotificationError  setMessage = {setApiResponse}  message= {apiResponse}/>}
            <div className="columns is-centered">
                <h1 className="title">Cancel Batch Registration</h1>
                <br />
            </div>
            <div className="columns is-centered">
                <div className="field">
                    <label className="label">Please specify reason for cancelling</label>
                    <div className="control">
                        <textarea
                            onChange={e => setCancelNote(e.target.value)}
                            className="textarea"
                            name="cancelText"
                        />
                    </div>
                </div>
            </div>
            {!apiResponse ? 
             <div className="buttons">
                <button className="button is-info is-light" onClick={props.prev}>Previous</button>
                <button className="button is-danger is-light" onClick={handleCancelRegistration}>Submit</button>
            </div>
            :
            <Link to="/labs/dashboard" className="button is-success">Back to Labs</Link>
            }
           

        </div>
    );
}

export default StepCancelRegistration;