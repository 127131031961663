import React, { useEffect } from 'react'
import DataDisplay from '../../components/DataDisplay/DataDisplay'
import Button from '../../components/Button/Button'

function StepReviewMortalityReport(props) {

    const reportMore = () => {
        props.reportMore();
        props.jump(1);
    }

    const submitReport = async () => {
        let req = await props.submitReportPlantMortalityRequest();
        if (req)
            props.next()
    }

    useEffect(() => {
        props.compileReports()
        // eslint-disable-next-line  
    }, [])

    return (
        <div className="content container">

            <h4 className="title is-4">Ex plant/s has successfully been updated</h4>
            <DataDisplay label={'Bottle'} data={props.barCode} />
            <span>Ex Plant Info:</span>
            <DataDisplay label={'PCI'} data={props.scannedPci} />
            {props.selectedNode && <DataDisplay label={'Node'} data={props.selectedNode} />}
            <br />
            <DataDisplay label={'New Status'} data={'Dead'} />
            <DataDisplay label={'Mortality Reason'} data={props.selectedMortalityType} />

            <div className="buttons">
                <Button
                    onClick={reportMore}
                    label={'Report More Mortality'}
                    className={'button is-primary'}
                />
                <Button
                    onClick={submitReport}
                    label={'Finish Reporting'}
                    className={'button is-primary'}
                />
                <Button
                    // onClick={prev}
                    label={'Go Back'}
                    className={'button is-primary'}
                />
            </div>
        </div>
    )
}

export default StepReviewMortalityReport;