import React, {
    // useEffect,
    // useState
} from 'react'

const LabsStatisticsRecoveryTableBody = ({
    displayText,
    loading,
    headers,
    data,
    next,
    setSelectedData,
    type,
    tableStyle }) => {

    // const [totalDays, setTotalDays] = useState()
    // useEffect(() => {
    //     if (data) {
    //         const sum = data.reduce((partial_sum, a) => partial_sum + a.days_in_stage, 0);
    //         setTotalDays(sum)
    //     }
    // }, [data])

    return (
        <>
            {
                // !loading && data ?
                    // data?.map((info, i) => {
                        // return (
                            <tbody>
                                <tr>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td>test</td>
                                </tr>
                            </tbody>
                        // )

                    // })
                    // :
                    // <tbody>
                    //     <tr>
                    //         <td>{displayText}</td>
                    //     </tr>
                    // </tbody>
            }
        </>
    )
}

export default LabsStatisticsRecoveryTableBody
