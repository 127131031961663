import React from 'react'
import { Link } from "react-router-dom";

// import { Columns } from 'react-bulma-components';
// import { Box } from 'react-bulma-components';
// import { Heading } from 'react-bulma-components';

import './Labs.scss'

const Dashboard = (props) => {
    props.validateSession()


    return (
        <div className="container">

            <p>&nbsp;</p>
            <h1 className="title">Labs Dashboard</h1>

            <div className="columns is-variable is-3">
                <div className="column">
                    <div className="notification is-link is-light has-text-centered">
                        <Link to="/labs/statistics" className="button is-link is-large is-fullwidth is-inverted">Check Overall Statistics</Link>
                    </div>
                </div>
                <div className="column">
                    <div className="notification is-info is-light has-text-centered">
                        <Link to="/labs/batch/create" className="button is-link is-large is-fullwidth is-inverted">Register New Batch</Link>
                    </div>
                </div>
                <div className="column">
                    <div className="notification is-info is-light has-text-centered">
                        <Link to="/labs/update-stage" className="button is-link is-large is-fullwidth is-inverted">Update Plant Stage</Link>
                    </div>
                </div>
            </div>

            <div className="columns is-variable is-3">
                <div className="column">
                    <div className="notification is-info is-light has-text-centered">
                        <Link to="/labs/dashboard" className="button is-success is-large is-fullwidth is-inverted" disabled>Check Individual Plant</Link>
                    </div>
                </div>
                <div className="column">
                    <div className="notification is-link is-light has-text-centered">
                        <Link to="/labs/report-contamination" className="button is-success is-large is-fullwidth is-inverted">Report Contamination</Link>
                    </div>
                </div>
                <div className="column">
                    <div className="notification is-link is-light has-text-centered">
                        <Link to="/labs/motherplant/list" className="button is-link is-large is-fullwidth is-inverted">Manage Mother Plant</Link>
                    </div>
                </div>
            </div>

            <div className="columns is-variable is-3">
                <div className="column">
                    <div className="notification is-info is-light has-text-centered">
                        <Link to="/labs/dashboard" className="button is-success is-large is-fullwidth is-inverted" disabled>Register New Media</Link>
                    </div>
                </div>
                <div className="column">
                    <div className="notification is-info is-light has-text-centered">
                        <Link to="/labs/rescue-fungus" className="button is-success is-large is-fullwidth is-inverted">Rescue Plants From Fungus</Link>
                    </div>
                </div>
                <div className="column">
                    <div className="notification is-link is-light has-text-centered">
                        <Link to="/labs/check-bottle" className="button is-link is-large is-fullwidth is-inverted">Check Bottle</Link>
                    </div>
                </div>
            </div>

            <div className="columns is-variable is-3">
                <div className="column">
                    <div className="notification is-info is-light has-text-centered">
                        <Link to="/labs/timeattendance" className="button is-success is-large is-fullwidth is-inverted">Time & Attendance</Link>
                    </div>
                </div>
                <div className="column">
                    <div className="notification is-link is-light has-text-centered">
                        <Link to="/labs/manual-stage-update" className="button is-link is-large is-fullwidth is-inverted">Manual Registration of Plants</Link>
                    </div>
                </div>
                <div className="column">
                    <div className="notification is-link is-light has-text-centered">
                        <Link to="/labs/report-mortality" className="button is-link is-large is-fullwidth is-inverted">Report Mortality</Link>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Dashboard