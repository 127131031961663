// FinalStep.js
import React from "react";
import { Link } from "react-router-dom";

const StepExplantFinalConfimation = (props) => {
    console.log(props)
    return (
        <div className="container">

            <p>&nbsp;</p>
            <h1 className="title">Batch has been successfully registered!</h1>
            <h1 className="subtitle">Total of {props.state.exPlants.length} EX Plants have been created.</h1>
            <h2 className="subtitle">Please bring EX Plants to culture room</h2>


            <div className="buttons">
                <button onClick = {() => window.location.reload()} className="button is-primary is-large">Register New Batch</button>
                <Link to="/labs/dashboard" className="button is-link is-large">Back to Labs</Link>
            </div>

        </div>
    );
}

export default StepExplantFinalConfimation;