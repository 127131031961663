import React, { useState } from 'react'
import { Cookies } from 'react-cookie';

import axios from "axios";
import logo from '../logo.svg';
import '../App.css';

import NotificationError from "../components/NotificationError/NotificationError"
import NotificationSuccess from "../components/NotificationSuccess/NotificationSuccess"

const Login = (props) => {
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const [logoutChecker, setLogoutChecker] = useState(params.get('logout') && props?.loggedInStatus ? true : false);

    const [mobileCountryCode] = useState("63")
    const [mobileNumber, setMobileNumber] = useState("")
    const [password, setPassword] = useState("")
    const [message, setMessage] = useState("")
    const [apiResponse, setApiResponse] = useState("")

    const handleSuccessfulAuth = (data) => {

        console.log("handleSuccessfulAuth.data")
        console.log(data)
        console.log("user.type: ", data.data.type)

        if (data.error_code === 0 && data.message === "Login Success" && data.data.type === "user") {
            setApiResponse("error")
            setMessage("Invalid User Type")
        }
        else {
            props.handleLogin(data);
            setApiResponse("success")
            setMessage("Successful Login")
            props.history.push("/home", data);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const cookies = new Cookies();

        axios.post(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_LOGIN,
            {
                mobile_country_code: mobileCountryCode,
                mobile_number: mobileNumber,
                password: password,
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + process.env.REACT_APP_API_SEC_BEARER
                }
            },
        )
            .then(response => {
                console.log("handleSubmit.response:")
                console.log(response)

                if (response.data.message === 'Login Success') {
                    console.log("API/RESPONSE:")
                    console.log(response.data.data.jwt)

                    cookies.set('authSession', response.data.data.jwt, { path: '/' });
                    console.log(cookies.get('authSession')); // authSession

                    handleSuccessfulAuth(response.data);
                }
            })
            .catch(error => {
                setApiResponse("error")
                setMessage(error.message)
            });
    }

    return (
        <div className="container">
            <p>&nbsp;</p>

            <img src={logo} className="App-logo" alt="logo" />
            <h1 className="title">Vanilla Dream | Manager Login</h1>

            {logoutChecker &&  <NotificationSuccess setMessage={setLogoutChecker} message= "Successfully Logged Out"/>}
            {apiResponse === "error" && <NotificationError setMessage={setMessage} message={message} />}

            <form onSubmit={handleSubmit}>

                <div className="field">
                    <label className="label">Country Code</label>
                    <div className="control">
                        <div className="select">
                            <select name="mobile_country_code" placeholder="Country Code">
                                <option value="63">63</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="field">
                    <label className="label">Mobile Number</label>
                    <div className="control">
                        <input
                            className="input"
                            type="text"
                            name="mobile_number"
                            placeholder="Mobile Number -- 9171234567"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            required
                        />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Password</label>
                    <div className="control">
                        <input
                            className="input"
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                </div>

                <div className="field is-grouped">
                    <div className="control">
                        <button type="submit" className="button is-link">Login</button>
                    </div>
                </div>

            </form>
        </div>
    );
}

export default Login
