import axios from "axios";

const registerBatch = async (batchData) => {
    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ADMIN_PLANTS_BATCH_REGISTER,
                {
                    user_hash: batchData.signed_user_hash,
                    lab_aid: batchData.lab_aid,
                    type: batchData.type,
                    mother_plant_id: batchData.mother_plant_id,
                    propagation_method: batchData.propagation_method,
                    num_of_shoots: batchData.num_of_shoots,
                    laminar_flow: batchData.laminar_flow,
                    protocol: batchData.protocol,
                    status: 'pending-registration'
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + process.env.REACT_APP_API_SEC_BEARER
                    }
                },
            )
            .then(response => {
                console.log('create lesson response', response)
                resolve(response)
            })
            .catch(error => {
                resolve(error)
            });
    })
}

export default registerBatch