import React, { useState, useEffect } from 'react'
import uploadImage from "../../services/handbooks/image/uploadImage"
import deleteImage from "../../services/handbooks/image/deleteImage"
import createTools from '../../services/handbooks/tools/create'
import createLesson from "../../services/handbooks/lessons/createLesson"

import getTools from '../../services/handbooks/tools/retrieve'
import createToolsRelation from '../../services/handbooks/lessons/createToolsRelation'
import getToolsRelation from '../../services/handbooks/lessons/getToolsRelation'

import CreatableSelect from 'react-select/creatable';
import Select from 'react-select'

import deleteToolsRelation from '../../services/handbooks/lessons/deleteToolsRelation'
import updateLesson from '../../services/handbooks/lessons/updateLesson'
import durations from '../../constants/durations'
import getImage from '../../services/handbooks/image/getImage'
import './HandbookLessonForm.scss'

const HandbookLessonForm = (props) => {

    const [inputList, setInputList] = useState(props.location.state.lesson ? JSON.parse(props.location.state.lesson.content) : [{ media_reference: null, media_filename: null, media_signed_temp: null, text: null, media_type: null }]);

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    const handleAddClick = () => {
        setInputList([...inputList, { text: "" }]);
    };

    const [updateID] = useState(props.location.state.lesson && props.location.state.lesson.id)
    const [tools, setTools] = useState(null)
    const [title, setTitle] = useState(props.location.state.lesson && props.location.state.lesson.title);
    const [when, setWhen] = useState(props.location.state.lesson && props.location.state.lesson.when);
    const [image, setImage] = useState(props.location.state.lesson && props.location.state.lesson.imageLink);
    const [imageID, setImageID] = useState(props.location.state.lesson && props.location.state.lesson.image_id);
    const [loading, setLoading] = useState(false);
    const [newRecord, setNewRecord] = useState({});
    const [notes, setNotes] = useState(props.location.state.lesson && props.location.state.lesson.notes);

    const [handbookHash] = useState(props.location.state.lesson ? props.location.state.lesson.handbook_hash : props.location.state.handbookHash)

    const [allTools, setAllTools] = useState()
    const [toolsSelected, setToolsSelected] = useState()
    const [timeNeeded, setTimeNeeded] = useState()
    const [initialDuration] = useState(props.location.state.lesson && props.location.state.lesson.duration)
    const [defaultDuration, setDefaultDuration] = useState()
    const [filename, setFilename] = useState(props.location.state.lesson && props.location.state.lesson.image_filename)

    const updateImage = async () => {
        var duration;

        timeNeeded ? duration = timeNeeded : duration = defaultDuration[0]

        let lessonData = {
            id: updateID,
            title: title,
            duration: duration.value,
            content: inputList,
            notes: notes
        }

        let imageData = {
            id: updateID,
            image_ID: imageID,
            file_name: filename
        }

        let updateImageResponse = await updateLesson(lessonData, imageData);
        if (updateImageResponse)
            console.log('updated image in db', updateImageResponse)
    }

    useEffect(() => {

        var compileDuration = []

        compileDuration.push({
            'value': initialDuration,
            'label': initialDuration
        })


        setDefaultDuration(compileDuration)
    }, [initialDuration])

    useEffect(() => {

        const fetchSignedUrl = async () => {
            inputList.map(async (content, i) => {
                let signedUrl = await getImage(content.media_reference)
                const list = [...inputList];
                list[i]['media_type'] = signedUrl.mime_type;
                list[i]['media_signed_temp'] = signedUrl.s3_location;
                setInputList(list);
            })

        }

        fetchSignedUrl()
        // disabled eslint because placing `inputList` in the dependecy array causes infinite loop
        // eslint-disable-next-line  
    }, [props.location.state])

    const fetchTools = async () => {
        let allToolsFetch = await getTools()
        var compileAllTools = []
        allToolsFetch.forEach(el => {
            compileAllTools.push({
                value: el.id,
                label: el.name
            })
        })
        setAllTools(compileAllTools)
    }

    useEffect(() => {
        fetchTools();
    }, [props.location.state]);

    useEffect(() => {
        const fetchTools = async () => {
            let toolsRelation = await getToolsRelation(updateID)
            let allToolsFetch = await getTools()
            var compileTools = []
            var compileAllTools = []
            toolsRelation.forEach(element => {
                compileTools.push({
                    'value': element.tool_id,
                    'label': element.tool_name
                })
            });
            allToolsFetch.forEach(el => {
                compileAllTools.push({
                    'value': el.id,
                    'label': el.name
                })
            })
            setTools(compileTools)
            handleChange(compileTools)
            setAllTools(compileAllTools)
        }

        fetchTools();
    }, [props.location.state, updateID]);

    const uploadImageFile = async (e, i) => {
        setLoading(true)
        const file = e.target.files[0]
        const fileType = file.type

        const reader = new FileReader();
        reader.onload = async (e) => {
            console.log('uploading')
            const formData = new FormData()
            const imgBlob = new Blob([reader.result], {
                type: file.type
            });
            console.log(imgBlob)
            formData.append('handbook_image', imgBlob, file.name);

            var type = 'lessons'
            let uploadResult = await uploadImage(formData, type);
            console.log(uploadResult)
            setNewRecord(uploadResult.data[0].newRecord)
            setFilename(uploadResult.data[0].newRecord.filename)
            setImageID(uploadResult.data[0].newRecord.image_id)
            const list = [...inputList];
            list[i]['media_reference'] = uploadResult.data[0].newRecord.image_id;
            list[i]['media_signed_temp'] = uploadResult.data[0].location;
            list[i]['media_filename'] = uploadResult.data[0].newRecord.filename;
            list[i]['media_type'] = fileType;
            setInputList(list);

            setTimeout(() => {
                setImage(uploadResult.data[0].location)
                setLoading(false)
            }, 5000);
        };

        reader.readAsArrayBuffer(file);


    }

    const handleChange = async (newValue) => {

        if (newValue) {
            var tools = []
            let processTools = new Promise((resolve, reject) => {
                newValue.forEach(async (val, i) => {
                    if (val.__isNew__ === true) {
                        let toolsData = {
                            name: val.label,
                            description: null
                        }

                        let createResponse = await createTools(toolsData)

                        if (createResponse.status === 200 && newValue.length === i + 1) {
                            val.__isNew__ = false
                            val.value = createResponse.data.data.insertId
                            resolve(true)
                        }
                    }
                    else if (val.__isNew__ === undefined && newValue.length === i + 1) {
                        resolve(true)
                    }
                })
            })

            if (await processTools === true) {
                newValue.forEach(newTool => {
                    tools.push(newTool.value)
                })
            }
            setToolsSelected(tools)
        }

        else {
            setToolsSelected([])
        }


    };

    const handleSubmit = (evt) => {
        evt.preventDefault();

        submitLesson()

    }

    const submitLesson = async () => {

        if (props.location.state.lesson) {
            var duration;

            timeNeeded ? duration = timeNeeded : duration = defaultDuration[0]

            let clearTools = await deleteToolsRelation(updateID)

            if (clearTools) {
                let lessonData = {
                    id: updateID,
                    title: title,
                    duration: duration.value,
                    when: when,
                    content: inputList,
                    notes: notes,
                }

                let imageData = {
                    id: updateID,
                    image_ID: imageID,
                    file_name: filename
                }

                if (toolsSelected?.length > 0) {
                    var createRelation = await createToolsRelation(toolsSelected, updateID)
                    let updateResponse = await updateLesson(lessonData, imageData);
                    if (updateResponse && createRelation)
                        props.history.push("/handbooks/lessons", { handbookHash: handbookHash, handbookTitle: props.location.state.handbookTitle });
                }
                else {
                    console.log('no tools')
                    let updateResponse = await updateLesson(lessonData, imageData);
                    if (updateResponse)
                        props.history.push("/handbooks/lessons", { handbookHash: handbookHash, handbookTitle: props.location.state.handbookTitle });
                }
            }
        }

        else {
            console.log(inputList, title, handbookHash, newRecord, timeNeeded?.value, when)
            let submitResponse = await createLesson(inputList, title, handbookHash, newRecord, timeNeeded?.value, when, notes);

            if (submitResponse.lesson_id && toolsSelected?.length > 0) {
                let createRelation = await createToolsRelation(toolsSelected, submitResponse.lesson_id)
                console.log('relation creation response', createRelation)
                if (createRelation)
                    props.history.push("/handbooks/lessons", { handbookHash: handbookHash, handbookTitle: props.location.state.handbookTitle });
            }
            else {
                props.history.push("/handbooks/lessons", { handbookHash: handbookHash, handbookTitle: props.location.state.handbookTitle });
            }
        }
    }

    return (
        <div className="container">

            <p>&nbsp;</p>

            <h1 className="title">{props.location.state.lesson ? 'Update Lesson' : 'Create Lesson'}</h1>
            <h2 className="subtitle">Lesson is part of a section</h2>

            <form onSubmit={handleSubmit}>

                <div className="field">
                    <label className="label">Title:</label>
                    <div className="control">
                        <input
                            type="text"
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                            className="input"
                        />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Tools: <span>(Input what tools are needed to complete this lesson, if the tool is not on the list you can add new tool simply by typing it)</span></label>
                    {tools ?
                        <CreatableSelect
                            defaultValue={tools}
                            isMulti
                            onChange={handleChange}
                            options={allTools}
                        />
                        : <div className="control">
                            <CreatableSelect
                                isMulti
                                onChange={handleChange}
                                options={allTools}
                            />
                        </div>}

                </div>

                <div className="field">
                    <label className="label">Time Needed: <span>(Input how much time needed to complete this lesson)</span></label>
                    {defaultDuration ? <Select
                        defaultValue={defaultDuration}
                        value={timeNeeded}
                        onChange={(val) => setTimeNeeded(val)}
                        options={durations} /> : <div className="control">
                            <Select
                                value={timeNeeded}
                                onChange={(val) => setTimeNeeded(val)}
                                options={durations} />
                        </div>}

                </div>

                <div className="field">
                    <label className="label">When:</label>
                    <div className="control">
                        <input
                            type="text"
                            value={when}
                            onChange={e => setWhen(e.target.value)}
                            className="input"
                        />
                    </div>
                </div>

                {inputList.map((x, i) => {
                    return (
                        <div
                            key={i}
                            className="field">

                            <div className="field">
                                <label className="label">
                                    {i === 0 ? 'Primary Media (If the lesson has a video tutorial, it should be uploaded here as primary media)' : `Media ${i + 1}`}:
                                    </label>
                                <div className="control">
                                    <input
                                        type="file"
                                        onChange={(e) => uploadImageFile(e, i)}
                                    />
                                </div>
                            </div>
                            {inputList[i].media_type || loading ?
                                <div>
                                    {!loading ?
                                        <div>
                                            {loading}
                                            {inputList[i].media_type?.includes('image') ? (
                                                inputList[i].media_signed_temp ? <figure className="image is-128x128"><img src={inputList[i].media_signed_temp} alt="imageIcon" /></figure> : null) :
                                                (<video id="samp" width="640" height="480" controls><source src={inputList[i].media_signed_temp} type={inputList[i].media_type}></source>Your browser does not support this video format.</video>)}

                                            {image ?
                                                <button
                                                    className="delete"
                                                    type="button"
                                                    onClick={async () => await deleteImage(inputList[i].media_reference).then(res => {
                                                        console.log(res)
                                                        const list = [...inputList];
                                                        list[i]['media_reference'] = null;
                                                        list[i]['media_signed_temp'] = null;
                                                        setInputList(list);
                                                        updateImage()
                                                    })}
                                                >
                                                    Delete Image</button> : ''}
                                        </div> : <div>Loading..</div>}
                                </div> : null}

                            <label className="label">{i === 0 ? 'Primary Content' : `Content ${i + 1}`}:</label>
                            <div className="control">
                                <textarea
                                    defaultValue={inputList[i].text}
                                    className="textarea is-primary"
                                    name="text"
                                    onChange={e => handleInputChange(e, i)}
                                />

                                {inputList.length !== 1 && <button
                                    type="button"
                                    className="mr10"
                                    onClick={() => handleRemoveClick(i)}>X</button>}
                                {inputList.length - 1 === i && <button
                                    type="button"
                                    onClick={handleAddClick}>Add Media/Content</button>}
                            </div>
                        </div>

                    );
                })}

                <div className="field">

                    <label className="label">Staff Notes:</label>
                    <div className="control">
                        <textarea
                            type="text"
                            name="notes"
                            value={notes}
                            onChange={e => setNotes(e.target.value)}
                            className="textarea is-danger"
                        />
                    </div>
                </div>


                <div className="field is-grouped">
                    <div className="control">
                        <button className="button is-primary" type="submit" value="Submit" disabled={loading}>{props.location.state.lesson ? 'Update Lesson' : 'Create Lesson'}</button>
                    </div>
                </div>

            </form>
        </div>
    )
}

export default HandbookLessonForm
