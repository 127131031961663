import axios from "axios";

const deleteTemplate = async (id) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ACTIVITIES_SCHEDULER + "/" + id,
                {
                    headers: {
                        'Authorization': 'Bearer ' + process.env.REACT_APP_API_SEC_BEARER
                    }
                },
            )
            .then(res => {
                console.log("DEBUG/deleteTemplate: ", res)
                resolve(res)
            })
            .catch(error => {
                // reject(error)

                console.log("DEBUG/deleteTemplate.error:")
                console.log(error)

                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    console.log('Error', error.message);
                }

                reject(error.message)

            });
    })
}

export default deleteTemplate