const durations = [
    { value: '15 min', label: '15 min' },
    { value: '30 min', label: '30 min' },
    { value: '45 min', label: '45 min' },

    { value: '1 hr', label: '1 hr' },
    { value: '1 hr 15 min', label: '1 hr 15 min' },
    { value: '1 hr 30 min', label: '1 hr 30 min' },
    { value: '1 hr 45 min', label: '1 hr 45 min' },
    
    { value: '2 hrs', label: '2 hrs' },
    { value: '2 hrs 15 min', label: '2 hrs 15 min' },
    { value: '2 hrs 30 min', label: '2 hrs 30 min' },
    { value: '2 hrs 45 min', label: '2 hrs 45 min' },

    { value: '3 hrs', label: '3 hrs' },
    { value: '3 hrs 15 min', label: '3 hrs 15 min' },
    { value: '3 hrs 30 min', label: '3 hrs 30 min' },
    { value: '3 hrs 45 min', label: '3 hrs 45 min' },

    { value: '4 hrs', label: '4 hrs' },
    { value: '4 hrs 15 min', label: '4 hrs 15 min' },
    { value: '4 hrs 30 min', label: '4 hrs 30 min' },
    { value: '4 hrs 45 min', label: '4 hrs 45 min' },

    { value: '5 hrs', label: '5 hrs' },
    { value: '5 hrs 15 min', label: '5 hrs 15 min' },
    { value: '5 hrs 30 min', label: '5 hrs 30 min' },
    { value: '5 hrs 45 min', label: '5 hrs 45 min' },

    { value: '6 hrs', label: '6 hrs' },
    { value: '6 hrs 15 min', label: '6 hrs 15 min' },
    { value: '6 hrs 30 min', label: '6 hrs 30 min' },
    { value: '6 hrs 45 min', label: '6 hrs 45 min' },

    { value: '7 hrs', label: '7 hrs' },
    { value: '7 hrs 15 min', label: '7 hrs 15 min' },
    { value: '7 hrs 30 min', label: '7 hrs 30 min' },
    { value: '7 hrs 45 min', label: '7 hrs 45 min' },

    { value: '8 hrs', label: '8 hrs' },
    { value: '8 hrs 15 min', label: '8 hrs 15 min' },
    { value: '8 hrs 30 min', label: '8 hrs 30 min' },
    { value: '8 hrs 45 min', label: '8 hrs 45 min' },

    { value: '9 hrs', label: '9 hrs' },
    { value: '9 hrs 15 min', label: '9 hrs 15 min' },
    { value: '9 hrs 30 min', label: '9 hrs 30 min' },
    { value: '9 hrs 45 min', label: '9 hrs 45 min' },

    { value: '10 hrs', label: '10 hrs' },
    { value: '10 hrs 15 min', label: '10 hrs 15 min' },
    { value: '10 hrs 30 min', label: '10 hrs 30 min' },
    { value: '10 hrs 45 min', label: '10 hrs 45 min' },

    { value: '11 hrs', label: '11 hrs' },
    { value: '11 hrs 15 min', label: '11 hrs 15 min' },
    { value: '11 hrs 30 min', label: '11 hrs 30 min' },
    { value: '11 hrs 45 min', label: '11 hrs 45 min' },

    { value: '12 hrs', label: '12 hrs' },
    { value: '12 hrs 15 min', label: '12 hrs 15 min' },
    { value: '12 hrs 30 min', label: '12 hrs 30 min' },
    { value: '12 hrs 45 min', label: '12 hrs 45 min' },

]

export default durations