import React from 'react'
import Button from '../Button/Button'

const LabsSelectType = ({
    nodeTypes,
    setSelectedType,
    prev
}) => {

    return (
        <div className="container">
            <div className='buttons'>
                <ul>
                    {nodeTypes ?
                        nodeTypes.map((result, index) => {
                            return (
                                <button key={index} className="button is-info is-large"
                                    value={result.value}
                                    onClick={() => setSelectedType(result.value)}
                                >
                                    {result.value}</button>
                            )
                        }) : <div className="sections"><div className="section-header">Loading..</div></div>}
                </ul>
            </div>

            <div className="buttons">
                <Button
                    onClick={prev}
                    label={'Back'}
                    className={'button is-primary'}
                />
            </div>
        </div>
    );
}

export default LabsSelectType
