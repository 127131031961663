const deleteImage = async (id) => {
    return new Promise((resolve, reject) => {
        fetch(
            process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_DELETE_IMAGE + id,
            {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': 'Bearer ' + process.env.REACT_APP_API_SEC_BEARER
                  })
            }
        ).then(res => {
            console.log(res)
            resolve(res.json())
        }).catch(err => {
            reject(err)
        })
    })
}

export default deleteImage