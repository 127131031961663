// Step2.js
import React, { useState, useEffect } from 'react'
import getTypesByKey from "../../services/types/getTypesByKey"

function StepProtocolSelection(props) {

  const handleProtocolType = (e) => {
    console.log("Media Type: ", e.target.value)
    props.setState('protocol', e.target.value)
    props.next()
  }

  const [results, setResults] = useState([])
  const [loadedData, setLoadedData] = useState(false)

  const [key] = useState("plants_protocol");

  useEffect(() => {
    const getTypeKey = async () => {
      let result = await getTypesByKey(key)
      setResults(result)
      setLoadedData(true)
    }

    getTypeKey()
  }, [key]);

  return (
    <div className="container">

      <p>&nbsp;</p>
      {(props.state.transferCollection || props.state.transferRootCollection) && <h1 className="title has-text-centered">Update Plant Stage</h1>}
      <h1 className="title">{(props.state.transferCollection || props.state.transferRootCollection) ? `` :`Step 6:`} Choose Protocol</h1>
      {/* <h2 className="subtitle"></h2> */}

      <div className='buttons'>
        <ul>
          {loadedData === true ?
            results.map((result, index) => {
              return (
                <button key={index} className="button is-info is-large" value={result.value} onClick={handleProtocolType}>{result.value}</button>
              )
            }) : <div className="sections"><div className="section-header">Loading..</div></div>}
        </ul>
      </div>

      <div className="content">
        <h1>{props.state.protocol}</h1>
      </div>

      <div className="buttons">
        <button className="button is-info is-light is-large" onClick={props.prev}>Previous</button>
        <button disabled = {!props.state.protocol}  className="button is-info is-light is-large" onClick={props.next}>Next</button>
      </div>

    </div>
  );
}

export default StepProtocolSelection;