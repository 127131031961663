const uploadImage = async (data, type) => {
    return new Promise((resolve, reject) => {
        fetch(
            process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_UPLOAD_IMAGE + type,
            {
                method: 'POST',
                body: data,
                headers: new Headers({
                    'Authorization': 'Bearer ' + process.env.REACT_APP_API_SEC_BEARER
                  })
            }
        ).then(res => {
            resolve(res.json())
        }).catch(err => {
            reject(err)
        })
    })
}

export default uploadImage