// Step1.js
import React, { useState, useEffect } from 'react'
import getTypesByKey from "../../services/types/getTypesByKey"

import { useJwt } from "react-jwt";
import { Cookies } from 'react-cookie';

import auditTrail from "../../services/auditTrail ";

function StepPropagation(props) {

    const cookies = new Cookies();
    const authToken = cookies.get('authSession')
    const signed_user_hash = useJwt(authToken).decodedToken?.data?.hash

    const handleAuditTrail = async () => {
        let auditData = {
            type: "plant_registration",
            user_hash: signed_user_hash,
            activity: "Started: Register New Batch on Step 1-2",
            data: JSON.stringify({})
        }
        await auditTrail(auditData).then(() =>{
            props.next()
        })
    }

    const handlePropagation = (e) => {
        console.log("Propagation: ", e.target.value)
        props.setState('propagation', e.target.value)
        handleAuditTrail();
    }

    const [results, setResults] = useState([])
    const [loadedData, setLoadedData] = useState(false)

    const [key] = useState("plants_propagation_method");

    useEffect(() => {
        const getTypeKey = async () => {
            let result = await getTypesByKey(key)
            setResults(result)
            setLoadedData(true)
        }

        getTypeKey()
    }, [key]);

    return (

        <div className="container">

            <p>&nbsp;</p>
            <h1 className="title">Step 1: Choose Propagation Method </h1>
            {/* <h2 className="subtitle"></h2> */}

            <div className='buttons are-large'>
                <ul>
                    {loadedData === true ?
                        results.map((result, index) => {
                            return (
                                <button key={index} className="button is-info" value={result.value} onClick={handlePropagation}>{result.value}</button>
                            )
                        }) : <div className="sections"><div className="section-header">Loading..</div></div>}
                </ul>
            </div>

            <div className="content">
                <h1>{props.state.propagation}</h1>
            </div>

            <div className="buttons">
                <button className="button is-info is-light is-large" onClick={handleAuditTrail} disabled={!props.state.propagation}>Next</button>
            </div>


        </div>
    );
}

export default StepPropagation;