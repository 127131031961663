import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import getHandbooks from "../../services/handbooks/sections/getHandbooks"
import createQuiz from "../../services/handbooks/quiz/create"
import updateQuiz from "../../services/handbooks/quiz/update"

import './HandbookQuizForm.scss'


const HandbookQuizForm = (props) => {

    const [title, setTitle] = useState(props.location.state?.updateData ? props.location.state?.updateData?.title : "");
    const [sections, setSections] = useState()
    const [defaultSection, setDefaultSection] = useState()
    const [relatedSection, setRelatedSection] = useState()
    const [loading] = useState(false);


    const handleSections = (e) => {
        setRelatedSection(e)
    };

    useEffect(() => {
        if (sections) {

            if (props.location.state?.updateData) {

                console.log(props.location.state?.updateData?.id)
                let selectedSection = sections.find((val) => val.value === props.location.state?.updateData?.handbook_id)
                console.log(selectedSection)
                setDefaultSection([selectedSection])
            }
            else {
                let selectedSection = sections.find((val) => val.value === props.location.state.handbookID)
                console.log(selectedSection)
                setDefaultSection([selectedSection])
            }
        }

    }, [props.location.state, sections]);

    useEffect(() => {
        const fetchSections = async () => {
            let sectionsFetch = await getHandbooks()
            var compileAllSection = []
            sectionsFetch.forEach(el => {
                compileAllSection.push({
                    value: el.id,
                    label: el.title
                })
            })
            setSections(compileAllSection)
        }
        fetchSections();
    }, []);

    const handleSubmit = (evt) => {
        evt.preventDefault();
        submitQuiz()
    }

    const submitQuiz = async () => {

        let quizData = {
            title: title,
            handbookID: relatedSection ? relatedSection.value : (defaultSection ? defaultSection[0]?.value : null),
            handbookTitle: relatedSection ? relatedSection.label : (defaultSection ? defaultSection[0]?.label : null),
        }


        if (props.location.state?.updateData) {
            quizData.quizID = props.location.state?.updateData?.id
            console.log('to submit', quizData)
            let submitResponse = await updateQuiz(quizData);
            console.log(submitResponse)
            if (submitResponse)
                props.history.push("/handbooks/quiz", { handbookID: quizData.handbookID, handbookTitle: quizData.handbookTitle });
        }
        else {
            let submitResponse = await createQuiz(quizData);
            console.log(submitResponse)
            if (submitResponse)
                props.history.push("/handbooks/quiz", { handbookID: quizData.handbookID, handbookTitle: quizData.handbookTitle });
        }


        // }

    }
    return (
        <div className="container">

            <p>&nbsp;</p>

            <h1 className="title">{props.location.state ? 'Update' : 'Create'} Section</h1>

            <form onSubmit={handleSubmit}>
                <div className="field">
                    <label className="label">What section does this quiz belong to?</label>
                    <div className="control">
                        {defaultSection ? <Select
                            defaultValue={defaultSection}
                            value={relatedSection}
                            onChange={(val) => handleSections(val)}
                            options={sections} /> : 'Loading sections..'}


                    </div>
                </div>
                <div className="field">
                    <label className="label">
                        Title:
                </label>
                    <input
                        name="title"
                        className="input"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                    />
                </div>
                <input className={loading ? '' : 'button'} type="submit" value="Submit" disabled={loading} />
            </form>
        </div>
    );
}

export default HandbookQuizForm
