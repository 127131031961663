import axios from "axios";

const createFarm = async (data) => {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ADMIN_FARMS_LIST,
            {
                user_hash: data.userHash,
                square_footage: data.squareFootage,
                years_operation: data.years,
                soil_type: data.soilType,
                irrigated: data.irrigated,
                drainage: data.drainage,
                ownership: data.ownershipType,
                farm_name: data.farmName,
                selected: data.selected,
                current_coordinates: JSON.stringify(data.currentCoordinates),
                n_coordinates: JSON.stringify(data.northCoordinates),
                e_coordinates: JSON.stringify(data.eastCoordinates),
                w_coordinates: JSON.stringify(data.westCoordinates),
                s_coordinates: JSON.stringify(data.southCoordinates),
            },
            {
                headers: {
                    'Authorization': 'Bearer 4b58c6b1d51f0b45c5426d250324a6c49bb81c5e893b877ebe917f8bf5098a17'
                }
            }).then(async res => {
                resolve(res)
            }).catch(err => {
                console.log("service.getFarmList():")
                console.log(err)
                resolve(err)
            })
    })
}

export default createFarm
