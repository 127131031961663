import React from 'react'
import completeUpdatePlantStage from '../../../services/plants/completeUpdatePlantStage'

function StepUpdateConfirm(props) {

    console.log('props', props)

    const updateData = props.updateData.updateData
    const count = props.updateData.updateCount
    const type = props.updateData.content
    const bottleContent = props.updateData?.content === 'callus' ? 'Callus' : 'Ex plant'

    return (
        <div className="container">
            <p>&nbsp;</p>
            <h1 className="title has-text-centered">Update Plant Stage</h1>
            {/* <h4 className="title has-text-centered">Confirmation</h4> */}

            <div>
                {`${count} ${type.charAt(0).toUpperCase() + type.slice(1)} has successfully been registered!`}
                {/* {updateData[updateData.length - 1]} */}
            </div>
            <br></br>
            <div>
                {bottleContent} Info:
                {updateData.map((shoot) => {
                return (
                    <p key={shoot.pci}>PCI: {shoot.pci}</p>
                )
            })}
            </div>
            <br></br>
            <br></br>
            <div className="buttons">
                <button
                    className="button is-info is-light is-large"
                    onClick={() => props.jump(3)}
                >
                    Register Next Bottle
                </button>
                <button className="button is-info is-light is-large"
                    onClick={() => {
                        completeUpdatePlantStage(props.updateRegistrationId)
                        props.next()
                    }}
                >
                    Finish Update Plant Stage
                </button>
                <button className="button is-info is-light is-large" onClick={props.prev}>
                    Go Back
                </button>
            </div>
        </div>
    );
}

export default StepUpdateConfirm;
