import React, { useState, useEffect } from 'react'
import NotificationError from "../../components/NotificationError/NotificationError"
import transferStage from '../../services/plants/bottles/transferPlantStage'
import transferRoot from '../../services/plants/bottles/transferRootStage'
import { useJwt } from "react-jwt";
import { Cookies } from 'react-cookie';

function StepStageTransfer(props) {

    const cookies = new Cookies();
    const authToken = cookies.get('authSession')
    const signed_user_hash = useJwt(authToken).decodedToken?.data?.hash

    const [explants, setExplants] = useState()
    const [loading, toggleLoading] = useState(true)
    const [apiResponse, setApiResponse] = useState('')

    const handleTranferStage = async () => {
        let transferResponse = await transferStage(props.state.shoots[0].plant_bottle_code, signed_user_hash)
        if (transferResponse?.data) {
            if (props.state.transferCollection) {
                let currentArray = props.state.transferCollection
                currentArray.push(transferResponse.data.data)
                props.setState('transferCollection', currentArray)
            }
            else {
                props.setState('transferCollection', [transferResponse.data.data])
            }
            props.next();
        }
        else {
            setApiResponse('There was an error in updating the plant stage')
        }

    }

    const handleTranferRootStage = async () => {
        let transferResponse = await transferRoot(props.state.shoots[0].plant_bottle_code, signed_user_hash)
        if (transferResponse?.data) {
            if (props.state.transferRootCollection) {
                let currentArray = props.state.transferRootCollection
                currentArray.push(transferResponse.data.data)
                props.setState('transferRootCollection', currentArray)
            }
            else {
                props.setState('transferRootCollection', [transferResponse.data.data])
            }
            props.next();
        }
        else {
            setApiResponse('There was an error in updating the plant stage')
        }
    }

    useEffect(() => {
        setExplants(props.state.shoots)
        toggleLoading(false)
    }, [props])

    return (
        <div className="content container">

            <p>&nbsp;</p>
            <p>&nbsp;</p>
            {apiResponse && <NotificationError setMessage={setApiResponse} message={apiResponse} />}
            <h1 className="title has-text-centered">Update Plant Stage</h1>

            {!loading &&
                <h4 className="title is-4">Bottle {props.state.shoots[0].plant_bottle_code} Contains {explants.length} Ex Plant/s</h4>}
            {!loading ? explants.map((plant) => {
                return (
                    <div key={plant.id}>
                        <p>
                            <span>{plant.pci}</span> <br></br>
                        </p>
                    </div>
                )
            }) : <span>Loading..</span>}

            <div className="buttons">
                <button
                    onClick={() => handleTranferStage()}
                    className="button is-info is-light is-large"
                >Transfer Stage</button>
                <button
                    onClick={() => handleTranferRootStage()}
                    className="button is-info is-light is-large"
                >Transfer to R Stage</button>
                <button
                    onClick={() => props.jump(11)}
                    className="button is-info is-light is-large"
                >Report Callus Formation</button>
                <button
                    onClick={() => props.jump(12)}
                    className="button is-info is-light is-large"
                >Report Dormant Plants</button>
                <button className="button is-info is-light is-large"
                    onClick={props.prev}
                >Go Back</button>
            </div>
        </div>
    )
}

export default StepStageTransfer;