import axios from "axios";

const createToolsRelation = async (toolId, lessonId) => {
    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_TOOLS_RELATION,
                {
                    tool_id: toolId,
                    lesson_id: lessonId
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + process.env.REACT_APP_API_SEC_BEARER
                    }
                },
            )
            .then(response => {
                resolve(true)
            })
            .catch(error => {
                alert(error)
                console.log("login error", error);
                reject(error)
            });
    })
}

export default createToolsRelation