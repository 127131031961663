import React, { useState, useEffect } from "react";
import getTypesByKey from "../../services/types/getTypesByKey";

function StepLaminarFlow(props) {
    const handleExPlant = (e) => {
        console.log("Ex Plant: ", e.target.value);
        props.setState("laminar", e.target.value);
        props.next()
    };

    const [results, setResults] = useState([]);
    const [loadedData, setLoadedData] = useState(false);

    const [key] = useState("plant_laminar_flow");

    useEffect(() => {
        const getTypeKey = async () => {
            let result = await getTypesByKey(key);

            const n = 3;

            let divide = result.reduce(
                (r, e, i) => (i % n ? r[r.length - 1].push(e) : r.push([e])) && r,
                []
            ); // group array by three

            setResults(divide);
            setLoadedData(true);
        };

        getTypeKey();
    }, [key]);

    return (
        <div className="container">
            <p>&nbsp;</p>
            {(props.state.transferCollection || props.state.transferRootCollection) && <h1 className="title has-text-centered">Update Plant Stage</h1>}
            <h1 className="title">{(props.state.transferCollection || props.state.transferRootCollection) ? `` : `Step 4:`} Choose Laminar Flow</h1>
            {/* <h2 className="subtitle"></h2> */}

            <div>
                {loadedData === true ? (
                    results.map((result, index) => {
                        return (
                            <div key={index} className="columns is-variable is-3 is-offset-9">
                                {result.map((val) => {
                                    return (
                                        <div key={val.value} className="column is-one-fifth">
                                            <button
                                                key={index}
                                                className="button is-info is-fullwidth is-large"
                                                value={val.value}
                                                onClick={handleExPlant}
                                            >
                                                {val.value}
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })
                ) : (
                        <div className="sections">
                            <div className="section-header">Loading..</div>
                        </div>
                    )}
            </div>

            <div className="content">
                <h1>{props.state.laminar}</h1>
            </div>

            <div className="buttons">
                <button className="button is-info is-light is-large" onClick={props.prev}>
                    Previous
        </button>
                <button className="button is-info is-light is-large" onClick={props.next} disabled={!props.state.laminar}>
                    Next
        </button>
            </div>
        </div>
    );
}

export default StepLaminarFlow;
