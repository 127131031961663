import React, { useState, useEffect } from 'react'
import putUsersByUserHash from "../../services/users/putUsersByUserHash"
import userTypes from "../../constants/userTypes"
import accountStatus from "../../constants/accountStatus"
import Select from 'react-select'
import './Users.scss'
import NotificationError from "../../components/NotificationError/NotificationError"
import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"

// import { faHome } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UsersEdit = (props) => {

    props.validateSession()

    console.log("props:")
    console.log(props)

    useEffect(() => {
        var defaultType = {
            'value': props.location.state.type?.charAt(0).toUpperCase() + props.location.state.type?.slice(1),
            'label': props.location.state.type?.charAt(0).toUpperCase() + props.location.state.type?.slice(1)
        }
        console.log('defaultType', defaultType.value)
        setDefaultUserType(props.location.state.type ? defaultType : null)
        setUserType(props.location.state.type ? defaultType : null)

        var isActiveValue = 0
        var isActiveLabel = "Not-Active"
        if (props.location.state.isActive === 1) {
            isActiveValue = props.location.state.isActive
            isActiveLabel = "Active"
        }

        var defaultUserStatus = {
            'value': isActiveValue,
            'label': isActiveLabel
        }
        console.log('defaultUserStatus:', defaultUserStatus.value, defaultUserStatus.label)
        setDefaultUserStatus(props.location.state.isActive ? defaultUserStatus : null)
        setUserStatus(props.location.state.isActive ? defaultUserStatus : null)
    }, [props])

    const [apiResponse, setApiResponse] = useState("");

    const [userHash] = useState(props.location.state.userHash);
    const [mobileCountryCode, setMobileCountryCode] = useState(props.location.state.mobileCountryCode);
    const [mobileNumber, setMobileNumber] = useState(props.location.state.mobileNumber);
    const [firstName, setFirstName] = useState(props.location.state.firstName);
    const [lastName, setLastName] = useState(props.location.state.lastName);
    const [organization, setOrganization] = useState(props.location.state.organization);
    const [barangay, setBarangay] = useState(props.location.state.barangay);
    const [province, setProvince] = useState(props.location.state.province);
    const [yearsFarming, setYearsFarming] = useState(props.location.state.yearsFarming);
    const [userType, setUserType] = useState()
    const [userEmail, setUserEmail] = useState(props.location.state.email)
    const [defaultUserType, setDefaultUserType] = useState()
    const [defaultUserStatus, setDefaultUserStatus] = useState()
    const [userStatus, setUserStatus] = useState()
    const [password, setPassword] = useState("");

    const [mobileCountryCodeErr, setMobileCountryCodeErr] = useState({});
    const [mobileNumberErr, setMobileNumberErr] = useState({});
    const [firstNameErr, setFirstNameErr] = useState({});
    const [lastNameErr, setLastNameErr] = useState({});
    const [organizationErr, setOrganizationErr] = useState({});
    const [barangayErr, setBarangayErr] = useState({});
    const [provinceErr, setProvinceErr] = useState({});
    const [yearsFarmingErr, setYearsFarmingErr] = useState({});
    const [userStatusErr, setUserStatusErr] = useState({});
    const [passwordErr, setPasswordErr] = useState({});

    const formValidation = () => {
        const mobileCountryCodeErr = {}
        const mobileNumberErr = {}
        const firstNameErr = {}
        const lastNameErr = {}
        const organizationErr = {}
        const barangayErr = {}
        const provinceErr = {}
        const yearsFarmingErr = {}
        const userStatusErr = {}
        const passwordErr = {}

        let isValid = true

        if (firstName === "") {
            firstNameErr.required = "This field is required."
            isValid = false
        }

        if (lastName === "") {
            lastNameErr.required = "This field is required."
            isValid = false
        }

        if (organization === "") {
            organizationErr.required = "This field is required."
            isValid = false
        }

        if (barangay === "") {
            barangayErr.required = "This field is required."
            isValid = false
        }

        if (province === "") {
            provinceErr.required = "This field is required."
            isValid = false
        }

        if (yearsFarming === "") {
            yearsFarmingErr.required = "This field is required."
            isValid = false
        }

        if (userStatusErr === "") {
            userStatusErr.required = "This option is required"
            isValid = false
        }

        // if (password === "") {
        //     passwordErr.required = "This field is required."
        //     isValid = false
        // }

        setMobileCountryCodeErr(mobileCountryCodeErr)
        setMobileNumberErr(mobileNumberErr)
        setFirstNameErr(firstNameErr)
        setLastNameErr(lastNameErr)
        setOrganizationErr(organizationErr)
        setBarangayErr(barangayErr)
        setProvinceErr(provinceErr)
        setYearsFarmingErr(yearsFarmingErr)
        setPasswordErr(passwordErr)
        setUserStatusErr(passwordErr)
        return isValid
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const isValid = formValidation()

        if (isValid) {
            console.log("Form Valid: Processing on Backend")

            processBackend()

            // if (res.status == 200) {
            // window.location.href = "/users"
            // props.router.push("/users")
            // }

            e.preventDefault();
        } else {
            console.log("Form Invalid: Please check form")
        }
    }

    const processBackend = async () => {
        console.log(userType)
        try {
            let res = await putUsersByUserHash(userHash, mobileCountryCode, mobileNumber, firstName, lastName, organization, barangay, province, yearsFarming, password, userType?.value, userEmail, userStatus?.value)
            HandleResponse(res)
        } catch (error) {
            HandleResponse(error);
        }
    }

    const HandleResponse = (res) => {

        console.log("HandleResponse.res:")
        console.log(res)
        console.log(res.status)

        if (res.status === 200) {
            setApiResponse("success")
        } else {
            setApiResponse("error")
        }

    }

    return (
        <div className="container">
            <p>&nbsp;</p>

            <h1 className="title">Users</h1>
            <h2 className="subtitle">Edit User</h2>

            {apiResponse === "success" && <NotificationSuccess setMessage={setApiResponse} message="Successfully processed your request" />}
            {apiResponse === "error" && <NotificationError setMessage={setApiResponse} message="There was an error processing your request." />}

            <form onSubmit={handleSubmit}>
                <div className="field">
                    <label className="label">User Type</label>
                    <div className="control">
                        {defaultUserType ? <Select
                            defaultValue={defaultUserType}
                            value={userType}
                            onChange={(val) => setUserType(val)}
                            options={userTypes} />

                            :
                            <Select
                                value={userType}
                                onChange={(val) => setUserType(val)}
                                options={userTypes} />
                        }
                    </div>
                </div>

                <fieldset disabled>

                    <div className="field">
                        <label className="label">Mobile Country Code</label>
                        <div className="control">
                            <input name="mobileCountryCode" className="input" type="text" placeholder="Mobile Country Code"
                                value={mobileCountryCode}
                                onChange={e => setMobileCountryCode(e.target.value)} />
                        </div>
                        {Object.keys(mobileCountryCodeErr).map((key) => {
                            return <p className="help is-danger">{mobileCountryCodeErr[key]}</p>
                        })}
                    </div>

                    <div className="field">
                        <label className="label">Mobile Number</label>
                        <div className="control">
                            <input name="mobileNumber" className="input" type="text" placeholder="Mobile Number"
                                value={mobileNumber}
                                onChange={e => setMobileNumber(e.target.value)} />
                        </div>
                        {Object.keys(mobileNumberErr).map((key) => {
                            return <p className="help is-danger">{mobileNumberErr[key]}</p>
                        })}
                    </div>

                </fieldset>

                <div className="field">
                    <label className="label">Email Address</label>
                    <div className="control">
                        <input name="mobileNumber" className="input" type="email" placeholder="Email Address"
                            value={userEmail}
                            onChange={e => setUserEmail(e.target.value)} />
                    </div>
                    {/* {Object.keys(userEmailErr).map((key) => {
                        return <p className="help is-danger">{userEmailErr[key]}</p>
                    })} */}
                </div>

                <div className="field">
                    <label className="label">First Name</label>
                    <div className="control">
                        <input name="firstName" className="input" type="text" placeholder="First Name"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)} />
                    </div>
                    {Object.keys(firstNameErr).map((key) => {
                        return <p className="help is-danger">{firstNameErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Last Name</label>
                    <div className="control">
                        <input name="lastName" className="input" type="text" placeholder="Last Name"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)} />
                    </div>
                    {Object.keys(lastNameErr).map((key) => {
                        return <p className="help is-danger">{lastNameErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Organization</label>
                    <div className="control">
                        <input name="organization" className="input" type="text" placeholder="Organization"
                            value={organization}
                            onChange={e => setOrganization(e.target.value)} />
                    </div>
                    {Object.keys(organizationErr).map((key) => {
                        return <p className="help is-danger">{organizationErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Barangay</label>
                    <div className="control">
                        <input name="barangay" className="input" type="text" placeholder="Barangay"
                            value={barangay}
                            onChange={e => setBarangay(e.target.value)} />
                    </div>
                    {Object.keys(barangayErr).map((key) => {
                        return <p className="help is-danger">{barangayErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Province</label>
                    <div className="control">
                        <input name="province" className="input" type="text" placeholder="Province"
                            value={province}
                            onChange={e => setProvince(e.target.value)} />
                    </div>
                    {Object.keys(provinceErr).map((key) => {
                        return <p className="help is-danger">{provinceErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Years Farming</label>
                    <div className="control">
                        <input name="yearsFarming" className="input" type="text" placeholder="Years Farming"
                            value={yearsFarming}
                            onChange={e => setYearsFarming(e.target.value)} />
                    </div>
                    {Object.keys(yearsFarmingErr).map((key) => {
                        return <p className="help is-danger">{yearsFarmingErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">User Status</label>
                    <div className="control">
                        {defaultUserStatus ? <Select
                            defaultValue={defaultUserStatus}
                            value={userStatus}
                            onChange={(val) => setUserStatus(val)}
                            options={accountStatus} />

                            :
                            <Select
                                value={userStatus}
                                onChange={(val) => setUserStatus(val)}
                                options={accountStatus} />
                        }
                    </div>
                    {Object.keys(userStatusErr).map((key) => {
                        return <p className="help is-danger">{userStatusErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <p className="control has-icons-left">
                        <label className="label">Password</label>
                        <div className="control">
                            <input name="password" className="input" type="password" placeholder="Password"
                                value={password}
                                onChange={e => setPassword(e.target.value)} />
                            <span className="icon is-small is-left">
                                <i className="fas fa-lock"></i>
                            </span>
                        </div>
                    </p>
                    {Object.keys(passwordErr).map((key) => {
                        return <p className="help is-danger">{passwordErr[key]}</p>
                    })}
                </div>

                <div className="field is-grouped">
                    <div className="control">
                        <button className="button is-link" type="submit">Update User</button>
                    </div>
                    <div className="control">
                        <button className="button is-link is-light">Cancel</button>
                    </div>
                </div>

            </form>



        </div>
    )
}

export default UsersEdit
