import React, { useState, useEffect } from 'react'
import soilTypes from '../../constants/soilTypes'
import ownershipTypes from '../../constants/ownershipTypes'
import Select from 'react-select'

import getUsers from "../../services/users/getUsers"
import updateFarm from "../../services/farms/updateFarm"

import NotificationError from "../../components/NotificationError/NotificationError"
import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"

import FarmMaps from "../../components/FarmMaps/FarmMaps"

const FarmUpdateForm = (props) => {

    console.log(props)
    const farmId = props.location.state.farm.id
    const [farmName, setFarmName] = useState(props.location.state.farm?.farm_name);
    const [squareFootage, setSquareFootage] = useState(props.location.state.farm?.square_footage);
    const [drainage, setDrainage] = useState(props.location.state.farm?.drainage);
    const [soilType, setSoilType] = useState(props.location.state.farm?.soil_type);
    const [ownershipType, setOwnershipType] = useState(props.location.state.farm?.ownership);
    const [irrigated, setIrrigated] = useState(props.location.state.farm.irrigated === 'yes' ? '1' : '0');
    const [selected, setSelected] = useState(props.location.state.farm.selected);
    const [userLists, setUserLists] = useState([])
    const [userHash, setUserHash] = useState(props.match.params.userHash);
    const [defaultUser, setDefaultUser] = useState();
    const [years, setYears] = useState(props.location.state.farm.years_operation);

    const [currentMarkerCoordinates, setCurrentCoordinates] = useState(props.location.state.farm?.current_coordinates && JSON.parse(props.location.state?.farm.current_coordinates));
    const [northCoordinates, setNorthCoordinates] = useState(props.location.state.farm?.n_coordinates && JSON.parse(props.location.state?.farm.n_coordinates));
    const [eastCoordinates, setEastCoordinates] = useState(props.location.state.farm?.e_coordinates && JSON.parse(props.location.state?.farm.e_coordinates));
    const [westCoordinates, setWestCoordinates] = useState(props.location.state.farm?.w_coordinates && JSON.parse(props.location.state?.farm.w_coordinates));
    const [southCoordinates, setSouthCoordinates] = useState(props.location.state.farm?.s_coordinates && JSON.parse(props.location.state?.farm.s_coordinates));

    const [enablePlot, setEnablePlot] = useState(undefined)

    const initialValues = ['1', '0']

    const [apiResponse, setApiResponse] = useState("");


    const [yearsError, setYearsError] = useState({})
    const [footageError, setFootageError] = useState({})

    const handleSubmit = (e) => {
        e.preventDefault();

        const isValid = formValidation()

        console.log(isValid)
        if (isValid) {
            processBackend()
        }

        e.preventDefault();
    }

    useEffect(() => {
        const getUserList = async () => {
            let result = await getUsers()

            var listAll = []
            result.forEach(el => {
                listAll.push({
                    value: el.hash,
                    label: "" + el.mobile_country_code + "-" + el.mobile_number + " - " + el.first_name + " " + el.last_name + " (" + el.organization + ")"
                })
            })

            setUserLists(listAll)
        }

        getUserList()
    }, []);


    useEffect(() => {
        if (userHash && userLists.length > 0) {
            console.log(userHash)
            let selected = userLists.find((user) => user.value === userHash)
            console.log(selected)
            setDefaultUser(selected)
        }

    }, [userHash, userLists]);

    const formValidation = () => {
        const yearsErr = {}
        const footageErr = {}

        let isValid = true

        if (isNaN(years)) {
            yearsErr.required = "Non numeric characters are not allowed"
            isValid = false
        }

        if (isNaN(squareFootage)) {
            footageErr.required = "Non numeric characters are not allowed"
            isValid = false
        }

        setYearsError(yearsErr)
        setFootageError(footageErr)
        return isValid
    }

    const processBackend = async () => {
        // console.log(currentLon)  
        let request = {
            userHash: userHash,
            farmName: farmName,
            squareFootage: squareFootage,
            drainage: drainage,
            years: years,
            soilType: soilType,
            ownershipType: ownershipType,
            irrigated: irrigated === '1' ? 'yes' : 'no',
            selected: selected,
            currentCoordinates: currentMarkerCoordinates,
            northCoordinates: northCoordinates,
            eastCoordinates: eastCoordinates,
            westCoordinates: westCoordinates,
            southCoordinates: southCoordinates
        }

        console.log('request values', request)
        let response = await updateFarm(request, farmId)
        // console.log('this is the update request', response)
        if (response.status === 200) {
            setApiResponse("success")
        } else {
            setApiResponse("error")
        }
        // request.start_datetime = startDate.toISOString().slice(0, 10);
        // request.end_datetime = endDate.toISOString().slice(0, 10);

        // console.log("DEBUG/request: ", request)

        // try {
        //     let res = await postActivity(request)
        //     HandleResponse(res)
        // } catch (error) {
        //     HandleResponse(error);
        // }
    }

    return (
        <div className="container">
            <p>&nbsp;</p>
            <h1 className="title">Update Farm</h1>
            <h2 className="subtitle">Create or Edit</h2>
            <div className = "buttons">
            <button
                className="button is-success"
                onClick={() => setEnablePlot(!enablePlot)}
                disabled = {!enablePlot}
            >Plot Markers</button>

            <button
                className="button is-warning"
                onClick={() => setEnablePlot(!enablePlot)}
                disabled = {enablePlot}
            >Clear Plot Markers</button>
            </div>

            {apiResponse === "success" && <NotificationSuccess setMessage={setApiResponse} message="Successfully processed your request" />}
            {apiResponse === "error" && <NotificationError setMessage={setApiResponse} message="There was an error processing your request." />}

            <FarmMaps
                currentCoordinates={setCurrentCoordinates}
                northCoordinates={setNorthCoordinates}
                eastCoordinates={setEastCoordinates}
                westCoordinates={setWestCoordinates}
                southCoordinates={setSouthCoordinates}
                current = {currentMarkerCoordinates}
                north = {northCoordinates}
                east = {eastCoordinates}
                west = {westCoordinates}
                south = {southCoordinates}
                updatePlot={enablePlot}
                update


            ></FarmMaps>

            <form onSubmit={handleSubmit}>
                <div className="field">
                    <label className="label">Which User</label>
                    <div className="control">
                        {userHash ?
                            defaultUser &&
                            <Select
                                isDisabled
                                defaultValue={defaultUser}
                                onChange={e => setUserHash(e.value)}
                                options={userLists} /> :
                            <Select
                                onChange={e => setUserHash(e.value)}
                                options={userLists} />}

                    </div>
                </div>

                <div className="field">
                    <label className="label">Farm Name</label>
                    <div className="control">
                        <input name="farmName" className="input" type="text" placeholder="Farm Name"
                            value={farmName}
                            onChange={e => setFarmName(e.target.value)}
                        />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Drainage</label>
                    <div className="control">
                        <input name="drainage" className="input" type="text" placeholder="Drainage"
                            value={drainage}
                            onChange={e => setDrainage(e.target.value)}
                        />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Square Footage</label>
                    <div className="control">
                        <input name="squareFootage" className="input" type="text" placeholder="Square Footage"
                            value={squareFootage}
                            onChange={e => setSquareFootage(e.target.value)}
                        />
                    </div>
                    {Object.keys(footageError).map((key, index) => {
                        return <p key={index} className="help is-danger">{footageError[key]}</p>
                    })}
                </div>
                <div className="field">
                    <label className="label">Years Operation</label>
                    <div className="control">
                        <input name="years" className="input" type="text" placeholder="Years Operation"
                            value={years}
                            onChange={e => setYears(e.target.value)}
                        />
                    </div>
                    {Object.keys(yearsError).map((key, index) => {
                        return <p key={index} className="help is-danger">{yearsError[key]}</p>
                    })}
                </div>
                <div className="field">
                    <label className="label">Soil Type</label>
                    <div className="control">
                        <Select
                            defaultValue={soilTypes.find((soil) => soil.value === soilType)}
                            onChange={e => setSoilType(e.value)}
                            options={soilTypes} />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Ownership Type</label>
                    <div className="control">
                        <Select
                            defaultValue={ownershipTypes.find((own) => own.value === ownershipType)}
                            onChange={e => setOwnershipType(e.value)}
                            options={ownershipTypes} />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Irrigated</label>

                    <div className="control">
                        {initialValues.map((i, index) => {
                            return (
                                <label className="radio" key={index}>
                                    <input
                                        type="radio"
                                        value={i}
                                        checked={irrigated?.toString() === i}
                                        onChange={e => setIrrigated(e.currentTarget.value)}
                                    />
                                    {i === '1' ? 'Yes' : 'No'}</label>
                            )
                        })}

                    </div>
                </div>

                <div className="field">
                    <label className="label">Selected</label>

                    <div className="control">
                        {initialValues.map((i, index) => {
                            return (

                                <label className="radio" key={index}>
                                    <input
                                        type="radio"
                                        value={i}
                                        checked={selected?.toString() === i}
                                        onChange={e => setSelected(e.currentTarget.value)}
                                    />
                                    {i === '1' ? 'Yes' : 'No'}</label>
                            )
                        })}

                    </div>
                </div>

                <div className="field is-grouped">
                    <div className="control">
                        <button className="button is-link" type="submit">Update</button>
                    </div>
                    {/* <div className="control">
                        <button className="button is-link is-light">Cancel</button>
                    </div> */}
                </div>

            </form>
        </div>
    )
}

export default FarmUpdateForm