export const recoveryStatisticsTableHeader = ['Date of Inoculation',
    'Young Shoots',
    'Ex Plants',
    'Bottles',
    'Fungus',
    'Bacteria',
    'Fungus & Bacteria',
    'Dead',
    'Fungus %',
    'Bacteria %',
    'Fungus & Bacteria %',
    'Dead %',
    'Mortality Total',
    'Mortality %',
    'Recovery %',
    'Split Factor',
    'Fungus Plants',
    'Bacteria Plants',
    'Fungus and Bateria Plants',
    'Dead Plants',
    'Dormant Plants',
    'Fungus Plant %',
    'Bacteria Plants %',
    'Fungus and Bacteria Plants %',
    'Dead Plants %',
    'Dormant Plants %',
    'Total Mortality Plants',
    'Total Mortality Plants %'
]