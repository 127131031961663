import axios from "axios";

const QuestionUpdate = async (choices, quizData, newRecord) => {
    return new Promise((resolve, reject) => {
        axios
            .put(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_QUESTIONS + quizData.quizID,
                {
                    handbook_hash: quizData.handbookHash,
                    question: quizData.question,
                    related_lesson_id: quizData.related_lesson_id,
                    choices: choices,
                    correct_answer: quizData.correctAnswer,
                    image_id: newRecord.image_id,
                    image_filename: newRecord.filename,
                    quiz_id: quizData.quiz_id
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + process.env.REACT_APP_API_SEC_BEARER
                    }
                },
            )
            .then(response => {
                console.log("handleSubmit.response:")
                console.log(response)
                resolve(true)
            })
            .catch(error => {
                alert(error)
                console.log("login error", error);
                reject(error)
            });
    })
}

export default QuestionUpdate