import axios from "axios";

const getLessons = async (handbookHash) => {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_LESSONS + handbookHash, {
            headers: {
                'Authorization': 'Bearer 4b58c6b1d51f0b45c5426d250324a6c49bb81c5e893b877ebe917f8bf5098a17'
            }
        }).then(async res => {
            var lesson_data = res.data.data
            if (lesson_data.length === 0) {
                return resolve(lesson_data)
            }
            resolve(lesson_data)
        })
    })
}

export default getLessons