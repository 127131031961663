import React, { useEffect, useState } from 'react'
import LabsTableView from '../../components/Labs/LabsTableView'
import retrieveRawData from '../../services/plants/retrieveRawData'


const LabsRawData = (props) => {

    const [rawData, setRawData] = useState();
    const [displayText] = useState('Loading..')

    const tableHeaders =
        ['Date of Inoculation',
            'Name of Inoculator',
            'Lab Aid',
            'Lab Protocol',
            'Mother Plant Source',
            'Batch number',
            'Current Stage'
        ]

    useEffect(() => {
        const fetchRawData = async () => {
            let req = await retrieveRawData()

            setRawData(req)
            console.log('raw data', req)
        }

        fetchRawData()
    }, [])

    return (
        <div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h1 className="title has-text-centered">Labs Raw Data</h1>
            <div className="box">
                <LabsTableView
                    displayText={displayText}
                    data={rawData}
                    headers={tableHeaders}
                    type='raw' />

            </div>
        </div>
    )
}

export default LabsRawData
