import axios from "axios";

const putUsersByUserHash = async (userHash, mobileCountryCode, mobileNumber, firstName, lastName, organization, barangay, province, yearsFarming, password, userType, userEmail, isActive) => {
    console.log("DEBUG/putUsersByUserHash:")
    console.log(userHash)
    console.log(mobileCountryCode)
    console.log(mobileNumber)
    console.log(firstName)
    console.log(lastName)
    console.log(organization)
    console.log(barangay)
    console.log(province)
    console.log(userType)
    console.log(isActive)
    return new Promise((resolve, reject) => {
        axios.put
            (process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ADMIN_USERS + "/" + userHash,
                {
                    first_name: firstName,
                    last_name: lastName,
                    organization: organization,
                    barangay: barangay,
                    province: province,
                    years_farming: yearsFarming,
                    password: password,
                    type: userType,
                    email: userEmail,
                    is_active: isActive,
                },
                {
                    headers: {
                        "Authorization": "Bearer " + process.env.REACT_APP_API_SEC_BEARER,
                        "Content-Type": "application/json",
                    }
                },
            )
            .then(response => {
                console.log("handleSubmit.response:")
                console.log(response)
                resolve(response)
            })
            .catch(error => {
                console.log("handleSubmit.error:")
                console.log(error)

                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    console.log('Error', error.message);
                }

                reject(error.message)
            })
    }) 
}

export default putUsersByUserHash