import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import ToolRetrieve from '../../services/handbooks/tools/retrieve'
import ToolDelete from '../../services/handbooks/tools/delete'

import './HandbookTools.scss'

const HandbookTools = () => {

    const [tools, setTools] = useState([])
    const [loadedData, setLoadedData] = useState(false)


    const getHandbookTools = async () => {
        let toolsFetched = await ToolRetrieve()
        setTools(toolsFetched)
        setLoadedData(true)
    }

    useEffect(() => {
        getHandbookTools();
    }, []);

    return (
        <div className="container">
            <p>&nbsp;</p>
            <h1 className="title">Handbook Tools</h1>
            <h2 className="subtitle">List of Tools</h2>

            <nav className="level">
                <div className="level-right">
                    <div className="field is-grouped">
                        <div className="control">
                            <Link className="button is-primary" to={{ pathname: "/handbooks/tools/create", state: { operation: 'create' } }}>Create Tool</Link>
                        </div>
                    </div>
                </div>
                <div className="level-left">
                    <div className="level-item">
                        <p className="subtitle is-5">
                            <strong>{tools.length}</strong> Record/s
                        </p>
                    </div>
                    <div className="level-item">
                        <div className="field has-addons">
                            <p className="control">
                                <input className="input" type="text" placeholder="Find a tool" />
                            </p>
                            <p className="control">
                                <button className="button">
                                    Search
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </nav>

            <div className='list'>
                <ul>
                    {loadedData ? <div>
                        {tools ? tools.map(((tool, key) => {
                            return (
                                <div className='list-item' key={key}>
                                    <div className="box">
                                        <article className="media">
                                            <div className="media-left">
                                                <div className="field is-grouped">
                                                    <div className="control">
                                                        <Link className="button is-link is-light" to={{ pathname: "/handbooks/tools/update", state: { operation: 'update', updateData: tool } }}>Edit</Link>
                                                    </div>
                                                    <div className="control">
                                                        <button
                                                            onClick={async () =>
                                                                await ToolDelete(tool.id).then(res => {
                                                                    setLoadedData(false)
                                                                    if (res) {
                                                                        getHandbookTools()
                                                                    }
                                                                })
                                                            }
                                                            type="button"
                                                            className="button is-danger is-light">Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="media-content">
                                                <div className="content">
                                                    <h2>{tool.name}</h2>
                                                </div>
                                                <nav className="level is-mobile">
                                                    <div className="level-left">
                                                    </div>
                                                </nav>
                                            </div>
                                            {/* <div className="media-right">
                                            <figure className="image is-64x64">
                                                <img src={lesson.imageLink} alt="lessonImage"></img>
                                            </figure>
                                        </div> */}
                                        </article>
                                    </div>
                                </div>
                            )

                        })) : <div><h4>No tools created for this section yet</h4></div>}
                    </div>
                        :
                        <div><h4>Loading..</h4>
                        </div>}

                </ul>
            </div>
        </div>
    )
}

export default HandbookTools