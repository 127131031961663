import axios from "axios";

const postMother = async (req) => {
    console.log("DEBUG/postMother:")
    console.log(req)

    return new Promise((resolve, reject) => {
        axios.post
            (process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ADMIN_PLANTS_MOTHER_CREATE_UPDATE,
                {
                    name: req.name,
                    unique_code: req.uniqueCode,
                    date_of_birth: req.dateOfBirth,
                    origin_country: req.originCountry,
                },
                {
                    headers: {
                        "Authorization": "Bearer " + process.env.REACT_APP_API_SEC_BEARER,
                        "Content-Type": "application/json",
                    }
                },
            )
            .then(response => {
                console.log("handleSubmit.response:")
                console.log(response)
                resolve(response)
            })
            .catch(error => {
                console.log("handleSubmit.error:")
                console.log(error)

                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    console.log('Error', error.message);
                }

                reject(error.message)
            })
    })
}

export default postMother