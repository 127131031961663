import React, { useState, useEffect } from 'react'
// import { Button } from 'react-bulma-components';
import { Link } from "react-router-dom";
import getUsers from "../../services/users/getUsers"
import './Farms.scss'

const Farms = (props) => {

    props.validateSession()

    const [results, setResults] = useState([])
    const [loadedData, setLoadedData] = useState(false)
    useEffect(() => {
        const getUser = async () => {
            let result = await getUsers()
            setResults(result)
            setLoadedData(true)
        }

        getUser()
    }, []);

    return (
        <div className="container">

            {/* <Button color="primary">My Bulma button</Button> */}

            <p>&nbsp;</p>

            <h1 className="title">Farms</h1>
            <h2 className="subtitle">View farms by user</h2>


            <nav className="level">
                <div className="level-right">
                    <p className="level-item">
                        <Link to={{
                            pathname: '/farms/create'
                        }} className="button is-success">New Farm</Link>
                    </p>

                    <p className="level-item"><strong>All</strong></p>
                    <p className="level-item"><a href="/#">Active</a></p>
                    <p className="level-item"><a href="/#">Disabled</a></p>
                </div>

                <div className="level-left">
                    <div className="level-item">
                        <p className="subtitle is-5">
                            <strong>{results?.length}</strong> records
                        </p>
                    </div>
                    <div className="level-item">
                        <div className="field has-addons">
                            <p className="control">
                                <input className="input" type="text" placeholder="Find a user" />
                            </p>
                            <p className="control">
                                <button className="button">
                                    Search
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </nav>

            <div className='list'>
                <ul>
                    {loadedData === true ?
                        results.map((result, index) => {
                            return (
                                <div className='list-item' key={result.id}>
                                    <div className="box">
                                        <article className="media">
                                            <div className="media-left">
                                                <div className="buttons">
                                                    <Link to={{
                                                        pathname: '/farms/list/user/' + result.hash
                                                    }} className="button is-link">View Farms</Link>
                                                </div>
                                            </div>
                                            <div className="media-content">
                                                <div className="content">
                                                    <h2>{result.first_name} {result.last_name}</h2>
                                                    <small>{result.total_farms} {result.total_farms > 1 ? 'farms' : 'farm'}</small> <br />
                                                    <small>{result.mobile_country_code}-{result.mobile_number}</small> <small>({result.type})</small>
                                                    <br />
                                                    {result.organization} {result.barangay} {result.province} {result.country}
                                                    <br />
                                                    <small>Created {result.date_created}</small>

                                                </div>
                                                <nav className="level is-mobile">
                                                    <div className="level-left">
                                                    </div>
                                                </nav>
                                            </div>
                                            <div className="media-right">
                                                <figure className="image is-64x64">
                                                    <img src="https://bulma.io/images/placeholders/128x128.png" alt="Profile" />
                                                </figure>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            )
                        }) : <div className="sections"><div className="section-header">Loading..</div></div>}
                </ul>
            </div>



        </div>
    )
}

export default Farms
