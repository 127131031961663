// FinalStep.js
import React from "react";
import { Link } from "react-router-dom";

const StepUpdateFinalConfirmation = (props) => {
    console.log(props)

    const bottleCount = props.updateCounter
    const exPlants = props.newShoots.filter(explant => explant.type === 'explant')
    const callus = props.newShoots.filter(explant => explant.type === 'callus')

    console.log('bottle count', bottleCount)
    console.log('exPlants count', exPlants)
    console.log('callus count', callus)
    return (
        <div className="container">

            <p>&nbsp;</p>
            <br></br>
            <div className="columns is-centered">
                <h1 className="title">Update Plant Stage</h1>
            </div>

            <br></br>
            <br></br>
            <div className="columns is-centered">
                <h1 className="subtitle">{bottleCount && bottleCount + " bottles have been successfully registered"}</h1>
            </div>

            <div className="columns is-centered">
                <h1 className="subtitle">{bottleCount && exPlants.length + " Ex Plants have been updated"}</h1>
            </div>

            <div className="columns is-centered">
                <h1 className="subtitle">{callus && callus.length + " Callus have been created"}</h1>
            </div>

            <div className="columns is-centered">
                <h1 className="subtitle">Please bring bottles to culture room</h1>
            </div>
            <br></br>
            <div className="buttons">
                <Link to="/labs/dashboard" className="button is-success">Back to Labs</Link>
            </div>

        </div>
    );
}

export default StepUpdateFinalConfirmation;