import axios from "axios";

const deleteFarm = async (farmId) => {
    return new Promise((resolve, reject) => {
        axios.delete(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ADMIN_FARMS_LIST + '/' + farmId, {
            headers: {
                'Authorization': 'Bearer 4b58c6b1d51f0b45c5426d250324a6c49bb81c5e893b877ebe917f8bf5098a17'
            }
        }).then(async res => {
            console.log("service.deleteFarm():")
            console.log(res)
            resolve(res)
        }).catch(err => {
            console.log("service.deleteFarm():")
            console.log(err)
            reject(err)
        })
    })
}

export default deleteFarm
