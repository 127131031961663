import React, { useState, useEffect } from "react";
import getTypesByKey from "../../services/types/getTypesByKey";
import registerExPlantRequest from '../../services/plants/registerExPlant'
import checkBottle from '../../services/plants/bottles/getBottleByIdentifierUpdate'
import NotificationError from "../../components/NotificationError/NotificationError"

function StepNumOfShoots(props) {

    const handleExPlant = (e) => {
        e.target.value ? toggleInvalid(false) : toggleInvalid(true)
        props.setState("numShoots", e.target.value);
        props.next()
    };

    const [results, setResults] = useState([]);
    const [loadedData, setLoadedData] = useState(false);
    const [isAboveTen, setIsAboveTen] = useState(false);
    const [invalid, toggleInvalid] = useState()
    const [apiResponse, setApiResponse] = useState('')
    const bottleContent = props?.updateData ? (props?.updateData[0]?.content === 'callus' ? 'callus' : 'ex plant') : 'ex plant'

    const [key] = useState("plants_number_of_shoots");

    useEffect(() => {
        const getTypeKey = async () => {
            let result = await getTypesByKey(key);
            const n = 3;

            let divide = result.slice(0, 9).reduce(
                (r, e, i) => (i % n ? r[r.length - 1].push(e) : r.push([e])) && r,
                []
            ); // group array by three

            setResults(divide);
            setLoadedData(true);
        };

        getTypeKey();
    }, [key]);

    const handleAdditionalShoots = (bottleCode, userHash, apiCallCount, content) => {

        let counter = parseInt(props.updateCounter) + 1
        props.setUpdateCounter(counter)
        console.log('total api calls', apiCallCount)
        setApiResponse(null)
        return new Promise(async (resolve, reject) => {
            console.log('will start bottle registration')
            var i = 0;
            var newShootsArr = []
            const exPlantRegistration = async () => {

                var registerRequest
                if (content !== 'callus') {
                    registerRequest = await registerExPlantRequest(bottleCode, props.updateRegistrationId, userHash, content)
                }
                else {
                    registerRequest = await registerExPlantRequest(bottleCode, props.updateRegistrationId, userHash, content, "C")
                }
                newShootsArr.push(registerRequest.data.data[0])
                newShootsArr[newShootsArr?.length - 1].type = content
                i++;
                if (registerRequest?.data?.error_code === 0 && registerRequest?.data?.message === 'Successfully created exPlant and linked bottle to registration') {
                    console.log(i, apiCallCount)
                    if (i < apiCallCount) {
                        exPlantRegistration();
                    }
                    else {
                        console.log('compiled new shoots', newShootsArr)
                        let existingNewShoots = props.newShoots ? [...props.newShoots, ...newShootsArr] : [newShootsArr]

                        let updateData = { "updateData": newShootsArr }
                        updateData.content = content
                        updateData.updateCount = apiCallCount
                        props.setUpdateData(updateData);
                        props.setNewShoots(existingNewShoots)
                        resolve(true)
                    }
                }

            }
            exPlantRegistration()
        })
    }

    const handleShootsCount = async (e) => {
        const allData = props.updateData
        let bottleCode = allData[allData?.length - 1].updateData[0].plant_bottle_code
        let userHash = allData[allData?.length - 1].updateData[0].hash

        let apiCallCount = parseInt(e.target.value)
        const content = allData[allData?.length - 1].content

        let updateShootsCount = await handleAdditionalShoots(bottleCode, userHash, apiCallCount, content)
        console.log('update count done?', updateShootsCount)
        if (updateShootsCount) {
            let bottleScan = await checkBottle(bottleCode)

            if (bottleScan) {
                // let updateData = { "updateData": bottleScan?.data?.data }
                // updateData.content = content
                // updateData.updateCount = totalCount
                // allData[allData?.length - 1] = updateData
                // props.setUpdateData(allData);
                props.next()
            }
        }
        // console.log()

    }

    return (
        <div className="container">
            <p>&nbsp;</p>
            {!props.updateData ? <h1 className="title">Step 3: Choose Number Of Shoots</h1> :
                <div>
                    <h1 className="title">Update Plant Stage</h1>
                    <h2 className="subtitle">Choose number of {bottleContent}</h2>
                </div>}

            {apiResponse && <NotificationError setMessage={setApiResponse} message={apiResponse} />}
            <div className="columns is-centered">
                <div className={`column ${`is-offset-3`}`}>
                    {loadedData === true ? (
                        results.map((result, index) => {
                            return (
                                <div key={index} className="columns is-variable is-3">
                                    {result.map((val) => {
                                        return (
                                            <div key={val.value} className="column is-one-third">
                                                <div className="notification is-info is-light has-text-centered">
                                                    <button
                                                        key={index}
                                                        className="button is-info is-large"
                                                        value={val.value}
                                                        onClick={props.updateData ? handleShootsCount : handleExPlant}
                                                    >
                                                        {val.value}
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })
                    ) : (
                            <div className="sections">
                                <div className="section-header">Loading..</div>
                            </div>
                        )}
                </div>
                <div className="column">
                    <div className="columns is-1">
                        <div className="column is-one-third">
                            {!isAboveTen && loadedData && <div className="has-text-centered">
                                <button
                                    className="button is-info is-medium"
                                    value={'10+'}
                                    onClick={() => setIsAboveTen(true)}
                                >
                                    + Add more
                                </button>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            {
                isAboveTen && !invalid && <article className="message is-warning">
                    <div className="message-body">
                        Numeric valus only
                    </div>
                </article>
            }
            {
                isAboveTen && invalid && <article className="message is-danger">
                    <div className="message-body">
                        Numeric valus only
                    </div>
                </article>
            }

            {isAboveTen && <div className="content">
                <input type="number" className="input" name="numShoots" onChange={(e) => props.updateData ? handleShootsCount(e) : handleExPlant(e)} />
            </div>}


            <div className="content">
                <h1>{props.state.numShoots}</h1>
            </div>

            <div className="buttons">
                <button className="button is-info is-light is-large" onClick={props.prev}>
                    Previous
        </button>
                <button className="button is-info is-light is-large" onClick={props.next} disabled={!props.state.numShoots}>
                    Next
        </button>
            </div>
        </div>
    );
}

export default StepNumOfShoots;
