import React, { useState} from 'react';
import attendanceCreate from "../../services/labs/attendanceCreate"
import NotificationError from "../../components/NotificationError/NotificationError"
import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"

const CreateAttendance = (props) => {


    const [file, setFile] = useState()
    const [apiResponse, setApiResponse] = useState("")
    const handleSubmit = (evt) => {
        evt.preventDefault();

        submitFile()

    }

    const submitFile = async () => {
        let submitResponse = await attendanceCreate(file);
        submitResponse.status === 200 ? setApiResponse("success") : setApiResponse("error")

    }
    return (
        <div className="container">
            <p>&nbsp;</p>
            {apiResponse === "success" && <NotificationSuccess setMessage={setApiResponse} message="Successfully processed your request" />}
            {apiResponse === "error" && <NotificationError setMessage={setApiResponse} message="There was an error processing your request." />}

            <h1 className="title">Upload CSV</h1>

            <form onSubmit={handleSubmit}>
                <div className="field">
                    <label className="label">
                        CSV File:
                </label>
                    <input
                        type="file"
                        onChange={(e) => {
                            if (e.target.files[0]) {
                                const formData = new FormData();
                                formData.append('timeattendance', e.target.files[0]);
                                setFile(formData)
                            }
                        }}
                    />
                </div>
                <input className='button' type="submit" value="Submit" />
            </form>
        </div>
    );
}

export default CreateAttendance
