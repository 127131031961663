import axios from "axios";

const QuestionDelete = async (id) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_QUESTIONS + id ,
                {
                    headers: {
                        'Authorization': 'Bearer ' + process.env.REACT_APP_API_SEC_BEARER
                    }
                },
            )
            .then(response => {
                resolve(true)
            })
            .catch(error => {
                reject(error)
            });
    })
}

export default QuestionDelete