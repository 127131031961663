import React, { useState } from 'react'
import { Steps, Step } from "react-step-builder";
import StepPlantDisplay from "./StepPlantDisplay"
import StepManualEditPage from "./StepManualEditPage"
import StepManualConfirmationPage from "./StepManualConfirmationPage"
import StepManualFinalConfirmation from "./StepManualFinalConfirmation"


const ManualRegistrationPlant = (props) => {

    const [selectedData, setSelectedData] = useState()
    const [finalData, setFinalData] = useState()

    return (
        <div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h1 className="title has-text-centered">Update Plant Stage Manual</h1>
            <div className="box">
                <Steps>
                    <Step component={StepPlantDisplay} setSelectedData={setSelectedData} />
                    <Step component={StepManualEditPage} selectedData={selectedData} />
                    <Step
                        component={StepManualConfirmationPage}
                        selectedData={selectedData}
                        setFinalData={setFinalData}
                    />
                    <Step component={StepManualFinalConfirmation} finalData={finalData} />

                </Steps>
            </div>
        </div>
    )
}

export default ManualRegistrationPlant
