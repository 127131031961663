import axios from "axios";

const retrievePlantProductionTimeAverage = async (batchNumber) => {
    console.log("DEBUG/retrievePlantProductionTime:")
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ADMIN_STATISTICS_PLANT_PRODUCTION_TIME_AVERAGE + batchNumber, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_API_SEC_BEARER}`
            }
        }).then(async res => {
            console.log("service.retrievePlantProductionTime():")
            console.log(res)
            var result = res.data.data
            resolve(result)
        }).catch(err => {
            console.log("service.retrievePlantProductionTime():")
            console.log(err)
            resolve(false)
        })
    })
}

export default retrievePlantProductionTimeAverage