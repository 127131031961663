import axios from "axios";

const reportPlantMortality = async (data) => {
    console.log("DEBUG/reportPlantMortality:")

    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ADMIN_PLANTS_REPORT_MORTALITY,
            {
                plant_bottle_id: data.plant_bottle_id,
                plant_bottle_code: data.plant_bottle_code,
                pci: data.pci,
                status: "dead"
            },
            {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_API_SEC_BEARER}`
                }
            }).then(async res => {
                console.log("service.reportPlantMortality():")
                console.log(res)
                var result = res.data.data
                resolve(result)
            }).catch(err => {
                console.log("service.reportPlantMortality():")
                console.log(err)
                resolve(false)
            })
    })
}

export default reportPlantMortality