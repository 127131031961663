import React from 'react'
import { Link } from "react-router-dom";

// import { Columns } from 'react-bulma-components';
// import { Box } from 'react-bulma-components';
// import { Heading } from 'react-bulma-components';

const LabsStatistics = (props) => {
    props.validateSession()


    return (
        <div className="container">

            <p>&nbsp;</p>
            <h1 className="title">Statistics Dashboard</h1>

            <div className="columns is-variable is-3">
                <div className="column">
                    <div className="notification is-link is-light has-text-centered">
                        <Link to="/labs/statistics/plant-production-time" className="button is-link is-large is-fullwidth is-inverted">Plant Production Time</Link>
                    </div>
                </div>
                <div className="column">
                    <div className="notification is-info is-light has-text-centered">
                        <Link to="/labs/statistics/raw-data" className="button is-link is-large is-fullwidth is-inverted">Raw Data</Link>
                    </div>
                </div>
                <div className="column">
                    <div className="notification is-info is-light has-text-centered">
                        <Link to="/labs/statistics/recovery" className="button is-link is-large is-fullwidth is-inverted">Recovery Statistics</Link>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default LabsStatistics