import React, { useEffect, useState } from 'react'
import { useJwt } from "react-jwt";
import { Cookies } from 'react-cookie';

import auditTrail from "../../services/auditTrail ";
// import registerExPlantRequest from '../../services/plants/registerExPlant'
import unlistExPlant from '../../services/plants/unlistExPlant' //to enable if endpoint is ready
import NotificationError from "../../components/NotificationError/NotificationError"
import completeBatchRegistration from "../../services/plants/completeBatchRegistration"

import ModalDialogComponent from "../../components/ModalDialogComponent/ModalDialogComponent"
import ModalComponent from "../../components/ModalComponent/ModalComponent"

function StepPlantCreation(props) {

    const cookies = new Cookies();
    const authToken = cookies.get('authSession')
    const signed_user_hash = useJwt(authToken).decodedToken?.data?.hash

    const [apiResponse, setApiResponse] = useState('')

    const [displayDialog, toggleDisplayDialog] = useState(false)
    const [loading, isLoading] = useState(false)

    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const loadingText = 'Completing batch registration..'

    const handleDisplayDialog = () => {
        if (props.numOfShoots <= props.state.exPlants.length) {
            setDialogTitle("Registered more than selected shoots");
            setDialogMessage(`Are you sure you want to register more? You chose ${props.numOfShoots} and ${props.state.exPlants.length} shoot/s has already been registered.`);
        } else {
            setDialogTitle("Registered less than selected shoots");
            setDialogMessage(`Are you sure you want to finish registration? You chose ${props.numOfShoots} and only ${props.state.exPlants.length} shoot/s has been registered.`);
        }
        toggleDisplayDialog(!displayDialog)
    }

    const handleAuditTrail = async (audit_type, data = null) => {
        let auditData = {
            type: "plant_registration",
            user_hash: signed_user_hash,
            activity: "Completed: Continue Batch Registration on Step 2-2",
            data: JSON.stringify({ exPlantsRegistered: props.state.exPlants })
        }
        await auditTrail(auditData).then(() => {
            isLoading(false)
            props.next()
        })
    }
    useEffect(() => {
        props.setExplantCounter(props.state.exPlants.registrationNumber)
    }, [props])

    const completeRegistration = async () => {
        displayDialog && toggleDisplayDialog(false);
        isLoading(true)
        let completeRequest = await completeBatchRegistration(props.state.batchData.registration_id)
        if (completeRequest.status === 200)
            handleAuditTrail()

    }

    const popLatestExPlant = async () => {
        let explants = props.state.exPlants
        explants.pop()
        console.log('temp', explants)
        props.setState('exPlants', explants)
        props.setGoBack(true)
        props.setRegisterAgain(false)
        props.jump(2)
    }

    const cancelLatestExPlant = async () => {
        let unlistResponse = await unlistExPlant(props.registerData[0].pci, signed_user_hash, 'unlisted')// api call for unlisting explant
        console.log('unlist response', unlistResponse)
        if (unlistResponse) {
            let explants = props.state.exPlants
            explants.pop()
            props.setGoBack(false)
            props.setRegisterAgain(true)
            props.jump(2)
        }
    }

    const registerMore = () => {
        props.setGoBack(false)
        props.setRegisterAgain(true)
        props.jump(2)
    }

    const handleAcceptModalButton = () => {
        if (props.numOfShoots <= props.state.exPlants.length) {
            registerMore()
        } else {
            completeRegistration()
        }
    }

    return (
        <div className="content container">
            <p>&nbsp;</p>
            <p>&nbsp;</p>

            {loading && <ModalComponent display={loadingText}></ModalComponent>}
            
            {displayDialog &&
                <ModalDialogComponent
                    title={dialogTitle}
                    display={dialogMessage}
                    accept={handleAcceptModalButton}
                    isOpen={displayDialog}
                    toggleModal={handleDisplayDialog} />}
            {apiResponse && <NotificationError setMessage={setApiResponse} message={apiResponse} />}
            <div className="columns is-centered">
                <h1 className="title">ExPlant Registration</h1>
                <br />
            </div>
            <div className="columns is-centered">
                <div>
                    <h2>EX Plant {props.state.exPlants[props.state.exPlants.length - 1]?.registrationNumber} has successfully been created!</h2>
                    <h4>EX Plant info:</h4>
                    <span>PCI: {props.registerData[0]?.pci}</span><br />
                    <span>Mother Plant Source: {props.state.batchData?.name}</span><br />
                    <span>Batch: {props.state.exPlants[props.state.exPlants.length - 1]?.batch}</span><br />
                    <span>EX Plant: {props.state.exPlants[props.state.exPlants.length - 1]?.registrationNumber}</span><br />
                    <span>Node: {props.state.exPlants[props.state.exPlants.length - 1]?.node}</span><br />
                    <span>Registration Week: {props.state.exPlants[props.state.exPlants.length - 1]?.registrationWeek}</span><br />
                    <span>Registration Date: {props.state?.batchData?.date_created.slice(0, 10)}</span><br />
                    <span>Next Transfer Week: {props.state?.batchData?.next_transfer_week}</span><br />
                    <span>Bottle barcode: {props.state.exPlants[props.state.exPlants.length - 1]?.code}</span><br />
                </div>
            </div>
            <div className="buttons">
                <button className="button is-info is-light is-large"
                    onClick={() => {
                        if (props.numOfShoots <= props.state.exPlants.length) {
                            handleDisplayDialog()
                        } else {
                            registerMore()
                        }
                    }}
                // disabled={parseInt(props.state.exPlants[props.state.exPlants.length - 1]?.registrationNumber) === parseInt(process.env.REACT_APP_EXPLANT_LIMIT) || parseInt(process.env.REACT_APP_EXPLANT_LIMIT) !== 0}
                // disabled={props.numOfShoots === props.state.exPlants.length}
                >Register New Ex Plant</button>
                <button className="button is-info is-success is-large"
                    onClick={() => {
                        props.numOfShoots > props.state.exPlants.length ? handleDisplayDialog() : completeRegistration()
                    }}
                >Finish Registration</button>
                <button className="button is-info is-warning is-large"
                    onClick={popLatestExPlant}
                >Go Back</button>

                <button className="button is-danger is-danger is-large" onClick={cancelLatestExPlant}>Cancel {props.registerData[0]?.pci}</button>
            </div>

        </div>
    );
}

export default StepPlantCreation;