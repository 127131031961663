import React from 'react'
import LabsTableView from '../../components/Labs/LabsTableView'
import {recoveryStatisticsTableHeader} from '../../constants/tableHeaders'

// import { Columns } from 'react-bulma-components';
// import { Box } from 'react-bulma-components';
// import { Heading } from 'react-bulma-components';

const LabsStatisticsRecovery = (props) => {
    props.validateSession()


    return (
        <div className="container">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h1 className="title has-text-centered">Recovery Statistics</h1>
            <div className="box">
                {/* <span>Batch Number:</span>
                <LabsSearchInput
                    value={batchNumber}
                    placeholder={'Batch Number'}
                    setOldBatchNum={setBatchNumber}
                    formClassName="control is-expanded"
                /> */}
                <br />
                <br />
                <LabsTableView
                    // loading={loading}
                    // displayText={productionDisplayText}
                    // data={plantProduction}
                    headers={recoveryStatisticsTableHeader}
                    type='recovery'
                />

            </div>
        </div>
    )
}

export default LabsStatisticsRecovery