import React, { useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import updateActivity from "../../services/activities/updateActivity"
import getFarmLists from "../../services/farms/getFarmList"
import getUsers from "../../services/users/getUsers"
import getActivity from "../../services/activities/getActivity"

import NotificationError from "../../components/NotificationError/NotificationError"
import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"

import DatePicker from 'react-datepicker'
import Select from 'react-select'
import activityTypes from "../../constants/activityTypes"


const ActivitiesEventForm = (props) => {
    const { activityHash } = props.match.params;


    props.validateSession()

    const initialValues = ['1', '0']

    const [apiResponse, setApiResponse] = useState("");

    const [farm, setFarm] = useState("");
    const [userHash, setUserHash] = useState("");
    const [farmLists, setFarmLists] = useState([])
    const [userLists, setUserLists] = useState([])

    const [activityType, setActivityType] = useState("");
    const [title, setTitle] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [allDay, setAllDay] = useState("0");


    const [defaultActivityType, setDefaultActivityType] = useState(activityTypes.find((type) => type.value === activityType))

    const [activityTypeErr, setActivityTypeErr] = useState({});
    const [titleErr, setTitleErr] = useState({});
    const [startDateErr, setStartDateErr] = useState({});
    const [endDateErr, setEndDateErr] = useState({});
    const [allDayErr, setAllDayErr] = useState({});

    const [defaultUser, setDefaultUser] = useState();
    const [defaultFarm, setDefaultFarm] = useState();



    const [farms, setFarms] = useState();
    useEffect(() => {

        const retrieveActivity = async () => {
            let result = await getActivity(activityHash)
            if (result && userLists) {
                let selectedUser = userLists.find((selected) => selected.value === result[0].user_hash)
                let selectedActivityType = activityTypes.find((activity) => activity.value === result[0].type)
                let defaulStarttDate = moment(result[0].start_datetime);
                let defaultEndDate = moment(result[0].end_datetime);

                setDefaultActivityType([selectedActivityType])
                setDefaultUser([selectedUser])
                setTitle(result[0].title)
                setStartDate(defaulStarttDate.toDate())
                setEndDate(defaultEndDate.toDate())

                result[0].all_day ? setAllDay(result[0].all_day) : setAllDay("0")
                if (selectedUser && farms)
                    filterFarms(selectedUser, result[0].farm_id);

            }
        }

        if (userLists.length > 0)
            retrieveActivity()
        // disabled eslint because placing `filterFarms()` function in the dependecy array causes infinite loop
        // eslint-disable-next-line  
    }, [activityHash, userLists, activityType, farms]);

    const filterFarms = useCallback((user, defaultFarm) => {
        if (farms) {
            var listAll = []
            farms.filter((farm) => farm.user_hash === user.value).forEach(el => {
                listAll.push({
                    value: el.id,
                    label: el.farm_name + " by (" + el.full_name + ", " + el.organization + ")",
                    user: el.user_hash
                })
            })

            let selectedFarm = listAll.find((farm) => farm.value === defaultFarm)

            setDefaultFarm([selectedFarm])
            setFarmLists(listAll)
        }
    },
        [farms],
    )

    useEffect(() => {
        const getFarmList = async () => {
            let result = await getFarmLists()
            setFarms(result)
        }
        getFarmList()
    }, []);

    useEffect(() => {
        const getUserList = async () => {
            let result = await getUsers()

            var listAll = []
            result.forEach(el => {
                listAll.push({
                    value: el.hash,
                    label: "" + el.mobile_country_code + "-" + el.mobile_number + " - " + el.first_name + " " + el.last_name + " (" + el.organization + ")"
                })
            })

            setUserLists(listAll)
        }

        getUserList()
    }, []);

    const handleCalendarClose = () => console.log("Calendar closed");
    const handleCalendarOpen = () => console.log("Calendar opened");

    const formValidation = () => {
        const templateTypeErr = {}
        const titleErr = {}
        const startDateErr = {}
        const endDateErr = {}
        const allDayErr = {}

        let isValid = true

        if (activityType === "" && defaultActivityType === "") {
            templateTypeErr.required = "This field is required."
            isValid = false
        }

        if (title === "") {
            titleErr.required = "This field is required."
            isValid = false
        }

        if (startDate === "") {
            startDateErr.required = "This field is required."
            isValid = false
        }

        if (endDate === "") {
            endDateErr.required = "This field is required."
            isValid = false
        }

        if (allDay === "") {
            allDayErr.required = "This field is required."
            isValid = false
        }


        setActivityTypeErr(templateTypeErr)
        setTitleErr(titleErr)
        setStartDateErr(startDateErr)
        setEndDateErr(endDateErr)
        setAllDayErr(allDayErr)
        return isValid
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const isValid = formValidation()

        if (isValid) {
            console.log("Form Valid: Processing on Backend")

            processBackend()

            // if (res.status == 200) {
            // window.location.href = "/users"
            // props.router.push("/users")
            // }

            e.preventDefault();
        } else {
            console.log("Form Invalid: Please check form")
        }
    }

    const processBackend = async () => {
        console.log(userHash, farm)
        console.log(defaultUser, defaultFarm)
        let request = {
            userHash: userHash !== '' ? userHash : defaultUser[0].value,
            farmId: farm !== '' ? farm : defaultFarm[0].value,
            activityType: activityType !== '' ? activityType : defaultActivityType[0].value,
            title: title,
            startDate: startDate,
            endDate: endDate,
            allDay: allDay,
        }

        request.start_datetime = new Date(startDate.getTime() + Math.abs(startDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);
        request.end_datetime = new Date(endDate.getTime() + Math.abs(endDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);

        console.log("DEBUG/request: ", request)

        try {
            let res = await updateActivity(request, activityHash)
            HandleResponse(res)
        } catch (error) {
            HandleResponse(error);
        }
    }

    const HandleResponse = (res) => {

        console.log("HandleResponse.res:")
        console.log(res)
        console.log(res.status)

        if (res.status === 200) {
            setApiResponse("success")
        } else {
            setApiResponse("error")
        }

    }
    return (
        <div className="container">
            <p>&nbsp;</p>

            <h1 className="title">Update Single Activity</h1>
            <h2 className="subtitle">Create or Edit</h2>

            {apiResponse === "success" && <NotificationSuccess setMessage={setApiResponse} message="Successfully processed your request" />}
            {apiResponse === "error" && <NotificationError setMessage={setApiResponse} message="There was an error processing your request." />}

            <form onSubmit={handleSubmit}>
                <div className="field">
                    <label className="label">Which User</label>
                    <div className="control">
                        {defaultUser &&
                            <Select
                                isDisabled={true}
                                defaultValue={defaultUser}
                                onChange={e => setUserHash(e.value)}
                                options={userLists} />}
                    </div>
                </div>

                <div className="field">
                    <label className="label">Which Farm</label>
                    <div className="control">
                        {defaultFarm &&
                            <Select
                                defaultValue={defaultFarm}
                                onChange={e => setFarm(e.value)}
                                options={farmLists} />}

                    </div>
                </div>

                <div className="field">
                    <label className="label">Activity Type</label>
                    <div className="control">
                        {
                            defaultActivityType && <Select
                                defaultValue={defaultActivityType}
                                onChange={(val) => setActivityType(val.value)}
                                options={activityTypes} />}
                    </div>
                    {Object.keys(activityTypeErr).map((key) => {
                        return <p className="help is-danger">{activityTypeErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Title</label>
                    <div className="control">
                        <input name="title" className="input" type="text" placeholder="Title"
                            value={title}
                            onChange={e => setTitle(e.target.value)} />
                    </div>
                    {Object.keys(titleErr).map((key) => {
                        return <p className="help is-danger">{titleErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">Start Date</label>
                    <div className="control">
                        <DatePicker
                            selected={startDate}
                            // selected={date}
                            onChange={
                                date => setStartDate(date)
                            }
                            onCalendarClose={handleCalendarClose}
                            onCalendarOpen={handleCalendarOpen}
                            dateFormat="yyyy/MM/dd"
                        />
                    </div>
                    {Object.keys(startDateErr).map((key) => {
                        return <p className="help is-danger">{startDateErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">End Date</label>
                    <div className="control">
                        <DatePicker
                            selected={endDate}
                            // selected={date}
                            onChange={
                                date => setEndDate(date)
                            }
                            onCalendarClose={handleCalendarClose}
                            onCalendarOpen={handleCalendarOpen}
                            dateFormat="yyyy/MM/dd"
                        />
                    </div>
                    {Object.keys(endDateErr).map((key) => {
                        return <p className="help is-danger">{endDateErr[key]}</p>
                    })}
                </div>

                <div className="field">
                    <label className="label">All Day</label>

                    <div className="control">

                    </div>
                    <div className="control">
                        {initialValues.map((i) => {
                            return (

                                <label className="radio"
                                    key={i}>
                                    <input
                                        type="radio"
                                        value={i}
                                        checked={allDay.toString() === i}
                                        onChange={e => setAllDay(e.currentTarget.value)}
                                    />
                                    {i === '1' ? 'Yes' : 'No'}</label>
                            )
                        })}
                    </div>
                    {Object.keys(allDayErr).map((key) => {
                        return <p className="help is-danger">{allDayErr[key]}</p>
                    })}
                </div>

                {/* {apiResponse === "success" && <NotificationSuccess setMessage={setApiResponse} message="Successfully processed your request" />}
                {apiResponse === "error" && <NotificationError setMessage={setApiResponse} message="There was an error processing your request." />} */}

                <div className="field is-grouped">
                    <div className="control">
                        <button className="button is-link" type="submit">Update</button>
                    </div>
                </div>

            </form>



        </div>
    )
}

export default ActivitiesEventForm