import axios from "axios";
import getImage from "../image/getImage"

const QuestionRetrieve = async (handbookHash) => {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_QUESTIONS + handbookHash, {
            headers: {
                'Authorization': 'Bearer 4b58c6b1d51f0b45c5426d250324a6c49bb81c5e893b877ebe917f8bf5098a17'
            }
        }).then(async res => {
            var quiz_data = res.data.data
            if (quiz_data.count === 0) {
                return resolve(quiz_data)
            }
            var counter = 0
            let imageFetch = new Promise((resolve, reject) => {
                quiz_data.forEach(async (element) => {
                    let imageLink = await getImage(element.image_id)
                    element.imageLink = imageLink.s3_location
                    counter += 1
                    if (counter === quiz_data.length) {
                        resolve(true)
                    }
                });
            })
            if (await imageFetch)
                resolve(quiz_data)
        })
    })
}

export default QuestionRetrieve