// Step2.js
import React, { useState, useEffect } from "react";
import getMotherByIdentifier from "../../services/plants/mother/getMotherByIdentifier";

function StepMotherPlant(props) {
    const handleMotherPlant = (data) => {
        console.log("handleMotherPlant: ", data)
        props.setState('plant_mother', data)
        props.next()
    }

    const [results, setResults] = useState([])
    const [loadedData, setLoadedData] = useState(false)

    useEffect(() => {
        const getTypeKey = async () => {
            let result = await getMotherByIdentifier()
            setResults(result)
            setLoadedData(true)
        }

        getTypeKey()
    }, []);

    return (
        <div className="container">

            <p>&nbsp;</p>
            <h1 className="title">Step 2: Choose Mother Plant</h1>
            {/* <h2 className="subtitle"></h2> */}

            <div className='buttons are-large'>
                <ul>
                    {loadedData === true ?
                        results.map((result, index) => {
                            return (
                                <button key={index} className="button is-info" onClick={() => handleMotherPlant(result)}>{result.name}</button>
                            )
                        }) : <div className="sections"><div className="section-header">Loading..</div></div>}
                </ul>
            </div>

            <div className="content">
                <h1>{props.state.plant_mother?.name}</h1>
            </div>

            <div className="buttons">
                <button className="button is-info is-light is-large" onClick={props.prev}>Previous</button>
                <button disabled={!props.state.plant_mother} className="button is-info is-light is-large" onClick={props.next}>Next</button>
            </div>

        </div>
    );
}

export default StepMotherPlant;
