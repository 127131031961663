import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import ToolCreate from '../../services/handbooks/tools/create'
import ToolUpdate from '../../services/handbooks/tools/update'

import './HandbookToolForm.scss'

const HandbookToolForm = (props) => {
    const [toolID, setToolID] = useState()
    const [tool, setTool] = useState('')
    const [description, setDescription] = useState('' || undefined)

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        let toolsData = {
            name: tool,
            description: description
        }

        if (!toolID) {
            let createResponse = await ToolCreate(toolsData)
            if (createResponse)
                window.location.href = "/handbooks/tools"
        }
        else {
            toolsData.id = toolID
            let updateResponse = await ToolUpdate(toolsData)
            if (updateResponse)
                window.location.href = "/handbooks/tools"
        }


    }

    useEffect(() => {
        if (props.location.state.operation === 'update') {
            setTool(props.location.state.updateData.name)
            setDescription(props.location.state.updateData.description)
            setToolID(props.location.state.updateData.id)
        }
    }, [props.location.state]);

    return (
        <div className="container">
            <p>&nbsp;</p>
            <h1 className="title">Handbook Tools</h1>
            {toolID? <h2 className="subtitle">Create New Tool</h2> : <h2 className="subtitle">Update Tool</h2>}
            <form onSubmit={handleSubmit}>
                <div className="field">
                    <label className="label">Tool Name</label>
                    <div className="control">
                        <input name="toolName"
                            value={tool}
                            onChange={e => setTool(e.target.value)}
                            className="input"
                            type="text"
                            placeholder="Name of the tool" />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Tool Description</label>
                    <div className="control">
                        <input
                            value={description || undefined}
                            onChange={e => setDescription(e.target.value)}
                            name="toolName"
                            className="input"
                            type="text"
                            placeholder="Description of the tool" />
                    </div>
                </div>
                <div className="field is-grouped">
                    <div className="control">
                        {toolID ? <button className="button is-primary" type="submit">Update Tool</button>
                            : <button className="button is-primary" type="submit"> Create Tool</button>}

                    </div>
                    <div className="control">
                        <Link className="button is-link is-light" to={{ pathname: "/handbooks/tools" }}>Cancel</Link>
                    </div>
                </div>
            </form>
        </div>
    )
}


export default HandbookToolForm