// FinalStep.js
import React, { useState } from 'react'
import registerBatch from "../../services/plants/registerBatch"
import updateBatch from "../../services/plants/updateBatch"
import NotificationError from "../../components/NotificationError/NotificationError"

const FinalStep = (props) => {
    const handleSubmit = (evt) => {
        evt.preventDefault();
        submitData()

    }
    const [apiResponse, setApiResponse] = useState("")
    const [message, setMessage] = useState("")
    const submitData = async () => {
        const labsData = {
            signed_user_hash: props.signed_user_hash ? props.signed_user_hash : props.state.userHash,
            type: (props.state.transferCollection || props.state.transferRootCollection) && "update",
            lab_aid: props.state.labAids ? props.state.labAids : null,
            mother_plant_id: props.state.plant_mother?.id ? props.state.plant_mother.id : props.state.shoots[0].pci_m,
            propagation_method: props.state.propagation ? props.state.propagation : null,
            num_of_shoots: props.state.numShoots ? props.state.numShoots : props.state.shoots.length,
            laminar_flow: props.state.laminar ? props.state.laminar : null,
            protocol: props.state.protocol ? props.state.protocol : null,
        }
        console.log('to submit these data', labsData)
        props.setState('transferCollection', '')
        props.setState('transferRootCollection', '')

        var batchSubmit
        if (props.type === 'update') {
            console.log('for updating')
            batchSubmit = await updateBatch(labsData, props.state.shoots[0].plant_registration_id)
        }
        else {
            batchSubmit = await registerBatch(labsData)
        }
        console.log('submit result', batchSubmit)
        if (batchSubmit.status === 200) {
            props.setState('final', true)
            props.setState(`shootsData`, undefined);
            props.next()
        }
        else {
            setApiResponse("error")
            setMessage("Failed to submit batch registration")
        }

    }
    return (
        <div className="container">
            <p>&nbsp;</p>

            {apiResponse === "error" && <NotificationError setMessage={setMessage} message={message} />}
            <h1 className="title">Review Before Submission</h1>
            <h2 className="subtitle">Let's review the data entry before we submit</h2>

            {props.state.propagation && <p>Propagation: {props.state.propagation}</p>}
            {/* <p>Media Type: {props.state.mediatype}</p> */}
            {props.state.plant_mother && <p>Mother Plant: {`${props.state.plant_mother?.name} (${props.state.plant_mother?.unique_code}, ${props.state.plant_mother?.country_nice})`}</p>}
            {props.state.numShoots && <p>Number of Shoots: {props.state.numShoots}</p>}
            {props.state.laminar && <p>Laminar Flow: {props.state.laminar}</p>}
            {props.state.labAids && <p>Lab Aids: {props.state.labAids}</p>}
            {/* <p>Ex Plant: {props.state.explant}</p> */}
            {props.state.protocol && <p>Protocol: {props.state.protocol}</p>}
            {/* <p>Bottle: {props.state.plant_bottle}</p> */}

            {/* PCI: M159-B7-X1-W12-S5-21-33-42-11-1009 */}
            {/* <p>PCI: {props.state.plant_mother}-{props.state.explant}</p> */}



            <div className="buttons">
                <button className="button is-info is-light is-large" onClick={props.prev}>Previous</button>
                <button className="button is-success is-large"
                    onClick={(e) => handleSubmit(e)}
                >Confirm and Start Inoculation</button>
            </div>

        </div>
    );
}

export default FinalStep;