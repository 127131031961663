import axios from "axios";

const retrieveManualUpdateListFiltered = async (inoculationDate,oldBatchNum,motherPlantSource,inoculatorName,newBatchNumber ) => {
    console.log("DEBUG/retrieveManualUpdateList:")

    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ADMIN_PLANTS_MANUAL_UPDATE +  `list/pending/${inoculationDate ? inoculationDate : 'NULL'}/${oldBatchNum ? oldBatchNum : 'NULL'}/${motherPlantSource ? motherPlantSource : 'NULL'}/${inoculatorName ? inoculatorName : 'NULL'}/${newBatchNumber ? newBatchNumber : 'NULL'}`, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_API_SEC_BEARER}`
            }
        }).then(async res => {
            console.log("service.retrieveManualUpdateList():")
            console.log(res)
            var result = res.data.data
            resolve(result)
        }).catch(err => {
            console.log("service.retrieveManualUpdateList():")
            console.log(err)
            resolve(false)
        })
    })
}

export default retrieveManualUpdateListFiltered