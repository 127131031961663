import React from 'react'
import LabsSearchInput from '../InputField/InputField'

const LabsSearchBar = ({
    setInoculationDate,
    setOldBatchNum,
    setMotherPlantSource,
    setInoculatorName,
    setNewBatchNumber }) => {

    return (
        <div>
            <div className="field-body">
                <div className="field">
                    <label className="label">Old Batch Number</label>
                    <LabsSearchInput
                        placeholder={'Old Batch Number'}
                        setOldBatchNum={setOldBatchNum}
                        formClassName="control is-expanded"
                    />

                </div>
                <div className="field">
                    <label className="label">New Batch Number</label>
                    <LabsSearchInput
                        placeholder={'New Batch Number'}
                        setNewBatchNumber={setNewBatchNumber}
                        formClassName="control is-expanded"
                    />
                </div>
                <div className="field">
                    <label className="label">Inoculator</label>
                    <LabsSearchInput
                        placeholder={'Inoculator'}
                        setInoculatorName={setInoculatorName}
                        formClassName="control is-expanded"
                    />
                </div>
                <div className="field">
                <label className="label">Date</label>
                    <LabsSearchInput
                        placeholder={'Date'}
                        setInoculationDate={setInoculationDate}
                        formClassName="control is-expanded"
                    />
                </div>
            </div>
        </div>
    )
}

export default LabsSearchBar
