import axios from "axios";
import getImage from "../image/getImage"

const getToolsRelation = async (id) => {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_LESSONS_TOOLS + id, {
            headers: {
                'Authorization': 'Bearer 4b58c6b1d51f0b45c5426d250324a6c49bb81c5e893b877ebe917f8bf5098a17'
            }
        }).then(async res => {
            var lesson_data = res.data.data
            console.log(lesson_data)
            if (lesson_data.length === 0) {
                return resolve(lesson_data)
            }
            var counter = 0
            let imageFetch = new Promise((resolve, reject) => {
                lesson_data.forEach(async (element) => {
                    let imageLink = await getImage(element.image_id)
                    element.imageLink = imageLink.s3_location
                    counter += 1
                    if (counter === lesson_data.length) {
                        resolve(true)
                    }
                });
            })
            if (await imageFetch)
                resolve(lesson_data)
        })
    })
}

export default getToolsRelation