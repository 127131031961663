import axios from "axios";

const registerExPlant = async (code, regId, hash, type, origin, node) => {
    // console.log("DEBUG/posteBottleByIdentifier:")
    // console.log(code, regId)

    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ADMIN_EXPLANT_REGISTRATION + "/" + code,
            {
                plant_registration_id: regId,
                user_hash: hash,
                type: type,
                node: node,
                origin: origin
            },
            {
                headers: {
                    'Authorization': 'Bearer 4b58c6b1d51f0b45c5426d250324a6c49bb81c5e893b877ebe917f8bf5098a17'
                }
            }).then(async res => {
                console.log(res)
                resolve(res)
            }).catch(err => {
                console.log(err)
                resolve(err)
            })
    })
}

export default registerExPlant