import axios from "axios";

const attendanceCreate = async (file) => {
    // console.log("DEBUG/attendanceCreate:")
    // console.log(file)

    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ATTENDANCE_CREATE, file,
            {
                headers: {
                    'Authorization': 'Bearer ' + process.env.REACT_APP_API_SEC_BEARER
                }
            },
        )
            .then(response => {
                // console.log("attendanceCreate.response:")
                // console.log(response)
                resolve(response)
            })
            .catch(error => {
                // console.log("attendanceCreate error", error);
                resolve(error)
            });
    })
}

export default attendanceCreate