import axios from "axios";

// const createSection = async (sectionTitle, newRecord, id) => {
const updateLesson = async (lessonData, imageData) => {
    var updateData = {}
    if (lessonData.title === null) {
        updateData = {
            // title: sectionTitle,
            content: lessonData.content,  
            duration: lessonData.duration,  
            when: lessonData.when,
            image_id: imageData.image_ID,
            filename: imageData.file_name,
            notes: lessonData.notes,
        }
    }
    else {
        updateData = {
            title: lessonData.title,
            content: lessonData.content,
            duration: lessonData.duration,
            when: lessonData.when,
            image_id: imageData.image_ID,
            filename: imageData.file_name,
            notes: lessonData.notes,
        }
    }
    return new Promise((resolve, reject) => {
        axios.put(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_LESSONS + lessonData.id,
            updateData,
            {
                headers: {
                    'Authorization': 'Bearer ' + process.env.REACT_APP_API_SEC_BEARER
                }
            },
        )
            .then(response => {
                console.log(response)
                resolve(true)
            })
            .catch(error => {
                reject(error)
            });
    })
}

export default updateLesson