import React from 'react'
import Button from '../Button/Button'

const LabsManualTableBody = ({
    displayText,
    loading,
    headers,
    data,
    next,
    setSelectedData,
    type,
    tableStyle }) => {

    console.log(data)
    return (
        <>
            {
                !loading && data ?
                    data?.map((info, i) => {
                        return (
                            <tbody key={info.id}>
                                <tr>
                                    {type === 'manual' && <td>
                                        <Button
                                            className={'button is-primary'}
                                            label={'Select'}
                                            onClick={() => {
                                                setSelectedData(info)
                                                next()
                                            }}
                                        />
                                    </td>}
                                    <td>{info.date_inoculation.slice(0, 10)}</td>
                                    <td>{info.name_inoculator}</td>
                                    <td>{info.lab_aid}</td>
                                    <td>{info.laminar_flow}</td>
                                    <td>{info.lab_protocol}</td>
                                    <td>{info.mother_plant_source}</td>
                                    <td>{info.source_of_vanilla}</td>
                                    <td>{info.nursery_protocol}</td>
                                    <td>{info.field_type}</td>
                                    <td>{info.old_batch_no}</td>
                                    <td>{info.new_batch_no}</td>
                                    <td>{info.no_of_source}</td>
                                    <td>{info.current_stage}</td>
                                </tr>
                            </tbody>
                        )

                    })
                    :
                    <tbody>
                        <tr>
                            <td>{displayText}</td>
                        </tr>
                    </tbody>
            }
        </>
    )
}

export default LabsManualTableBody
