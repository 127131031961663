import React from 'react';
import Modal from 'react-modal';

const ModalDialogComponent = ({ title, display, isOpen, toggleModal, accept }) => {

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <Modal
            isOpen={isOpen}
            style={customStyles}
            ariaHideApp={false}
        >
            <div className="container">
                <h1 className="title has-text-centered">{title}</h1>
                <br />
                <h2 className="subtitle">{display}</h2>
                <br />
                <div className="buttons">
                    <button className="button is-info is-danger is-normal"
                        onClick={accept}
                    >
                        Yes
                </button>
                    <button className="button is-info is-light is-normal"
                        onClick={toggleModal}
                    >
                        No
                </button>
                </div>
            </div>
        </Modal>
    )
}

export default ModalDialogComponent
