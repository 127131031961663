import React, { useEffect, useState } from 'react'
import LabsTableView from '../../components/Labs/LabsTableView'
import LabsSearchInput from '../../components/InputField/InputField'
import retrievePlantProductionTime from '../../services/plants/retrievePlantProductionTime'
import retrievePlantProductionTimeAverage from '../../services/plants/retrievePlantProductionTimeAverage'


const LabsPlantProduction = (props) => {

    const [batchNumber, setBatchNumber] = useState('1');
    const [plantProduction, setPlantProduction] = useState();
    const [plantProductionAverage, setPlantProductionAverage] = useState();
    const [productionDisplayText, setProductionDisplayText] = useState('Loading..');
    const [productionAverageDisplayText, setProductionAverageDisplayText] = useState('Loading..');
    const [loading, toggleLoading] = useState(true)

    const tableHeaders =
        ['Date of Inoculation',
            'New Batch Number',
            'Current Stage',
            'Days in Stage',
        ]

    const tableHeadersAverage =
        ['Batch ID',
            'S0 Inoculation Date',
            'S1 Inoculation Date',
            'Days in Stage',
        ]

    const tableHeadersEstimate =
        ['Stage',
            'Days In Stage',
        ]

    useEffect(() => {
        const fetchPlantProduction = async () => {
            let plantProductionRequest = await retrievePlantProductionTime(batchNumber)
            let plantProductionAverageRequest = await retrievePlantProductionTimeAverage(batchNumber)


            if (plantProductionRequest.length > 0) {
                toggleLoading(false)
                setPlantProduction(plantProductionRequest)
            }
            else {
                setPlantProduction(null)
                setProductionDisplayText('No data fetched')
            }
            if (plantProductionAverageRequest.length > 0) {
                toggleLoading(false)
                setPlantProductionAverage(plantProductionAverageRequest)
            }
            else {
                setPlantProductionAverage(null)
                setProductionAverageDisplayText('No data fetched')
            }
        }


        if (batchNumber) {

            console.log(batchNumber)
            setProductionDisplayText('Loading..')
            setProductionAverageDisplayText('Loading..')
            fetchPlantProduction()
        }
        else {
            setProductionAverageDisplayText('Please enter batch number')
            setProductionDisplayText('Please enter batch number')
            setPlantProduction(null)
            toggleLoading(true)
        }
    }, [batchNumber])

    return (
        <div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h1 className="title has-text-centered">Labs Plant Production</h1>
            <div className="box">
                <span>Batch Number:</span>
                <LabsSearchInput
                    value={batchNumber}
                    placeholder={'Batch Number'}
                    setOldBatchNum={setBatchNumber}
                    formClassName="control is-expanded"
                />
                <br />
                <br />
                <h2 className='subtitle'>Plant Production Time</h2>
                <LabsTableView
                    loading={loading}
                    displayText={productionDisplayText}
                    data={plantProduction}
                    headers={tableHeaders}
                    type='plant-production'
                />

                <br />
                <br />
                <h2 className='subtitle'>S0 Production Days (average)</h2>
                <LabsTableView
                    loading={loading}
                    displayText={productionAverageDisplayText}
                    data={plantProductionAverage}
                    headers={tableHeadersAverage}
                    type='plant-production-average'
                />

                <br />
                <br />
                <h2 className='subtitle'>Estimated Production Days Per Stage</h2>
                <LabsTableView
                    loading={loading}
                    displayText={productionDisplayText}
                    data={plantProduction}
                    headers={tableHeadersEstimate}
                    type='plant-production-estimate'
                />

            </div>
        </div>
    )
}

export default LabsPlantProduction
