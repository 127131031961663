
import React, { useState, useEffect, useCallback } from "react";
import Select from 'react-select'
import uploadImage from "../../services/handbooks/image/uploadImage"
import QuestionCreate from "../../services/handbooks/questions/create"
import QuestionUpdate from "../../services/handbooks/questions/update"
import deleteImage from "../../services/handbooks/image/deleteImage"

import getLessons from "../../services/handbooks/lessons/getLessons"
import getHandbooks from "../../services/handbooks/sections/getHandbooks"

import getQuizzes from "../../services/handbooks/quiz/retrieve"

import "./HandbookQuestionsForm.js.scss"

const HandbookQuestionsForm = (props) => {
    const [inputList, setInputList] = useState([{ choice: "" }]);
    const [image, setImage] = useState(String, "");
    const [imageID, setImageID] = useState(String, "");
    const [question, setQuestion] = useState("");
    const [correctAnswer, setCorrectAnswer] = useState("");
    const [choicesCollection, setChoicesCollection] = useState("");
    const [newRecord, setNewRecord] = useState({});
    const [loading, setLoading] = useState(false);
    const [handbookHash] = useState(props.location.state.handbookHash)

    const [relatedLesson, setRelatedLesson] = useState()
    const [relatedQuiz, setRelatedQuiz] = useState()
    const [sections, setSections] = useState()
    const [lessons, setLessons] = useState()
    const [quizzes, setQuizzes] = useState()
    const [update] = useState(props.location.state?.updateDetails ? true : false)

    const [defaultLesson, setDefaultLesson] = useState()
    const [defaultQuiz, setDefaultQuiz] = useState()
    const [quizID] = useState(props?.location?.state?.updateDetails?.id || null)


    useEffect(() => {
        if (sections && quizzes?.length > 0) {

            if (update) {
                setQuestion(props.location.state?.updateDetails.questions)
                setCorrectAnswer(props.location.state?.updateDetails.correct_answer)

                let choices = JSON.parse(props.location.state.updateDetails.choices).choices
                var tempContainer = []
                choices.forEach(element => {
                    tempContainer.push({ 'choice': element })
                });
                setInputList(tempContainer)
                setImage(props.location.state.updateDetails.imageLink)
                setImageID(props.location.state.updateDetails.image_id)

                let selectedQuiz = quizzes.find((val) => val.value === props.location.state.updateDetails.quiz_id)
                setDefaultQuiz(selectedQuiz)
            }
        }

    }, [props.location.state, sections, update, quizzes]);

    const updateImage = async () => {
        let quizData = {
            handbookHash: handbookHash,
            related_lesson_id: relatedLesson ? relatedLesson.id : (defaultLesson ? defaultLesson[0]?.id : null),
            quiz_id: relatedQuiz ? relatedQuiz.value : (defaultQuiz ? defaultQuiz?.value : null),
            question: question,
            correctAnswer: correctAnswer
        }

        console.log('update', quizData)
        quizData.quizID = quizID
        let updateResponse = await QuestionUpdate(choicesCollection, quizData, newRecord)
        if (updateResponse)
            console.log('updated image data', updateResponse)
    }

    useEffect(() => {
        if (lessons?.length > 0) {
            let selectedLesson = lessons.find((val) => val.id === props.location.state?.updateDetails?.related_lesson_id)
            setDefaultLesson([selectedLesson])
        }
    }, [lessons, props]);

    useEffect(() => {
        const fetchSections = async () => {
            let sectionsFetch = await getHandbooks()
            var compileAllSection = []
            sectionsFetch.forEach(el => {
                compileAllSection.push({
                    value: el.hash,
                    label: el.title
                })
            })
            setSections(compileAllSection)
        }
        fetchSections();
    }, []);

    useEffect(() => {
        const fetchQuizzes = async () => {
            let sectionsFetch = await getQuizzes(props.location.state?.handbookID)
            var compileAllQuizzes = []
            sectionsFetch.forEach(el => {
                compileAllQuizzes.push({
                    value: el.id,
                    label: el.title
                })
            })
            setQuizzes(compileAllQuizzes)
            console.log(compileAllQuizzes)
        }
        fetchQuizzes();
    }, [props]);


    useEffect(() => {
        const fetchLessons = async () => {
            let lessonFetch = await getLessons(handbookHash)
            var compileAllLessons = []
            lessonFetch.forEach(el => {
                compileAllLessons.push({
                    id: el.id,
                    label: el.title
                })
            })
            setLessons(compileAllLessons)
        }
        fetchLessons();
    }, [handbookHash]);

    useEffect(() => {

        const compileChoices = () => {
            var choices = []
            inputList.forEach(c => {
                choices.push(c.choice)
            })
            let choices_collection = {
                choices: choices
            }
            setChoicesCollection(JSON.stringify(choices_collection))
        }

        compileChoices()
    }, [inputList]);

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    const handleAddClick = () => {
        setInputList([...inputList, { choice: "" }]);
    };

    const uploadImageFile = async e => {

        setLoading(true)
        const file = e.target.files[0]
        const reader = new FileReader();
        reader.onload = async (e) => {
            console.log('uploading')
            const formData = new FormData()
            const imgBlob = new Blob([reader.result], {
                type: file.type
            });
            console.log(imgBlob)
            formData.append('handbook_image', imgBlob, file.name);

            let type = 'quiz';
            let uploadResult = await uploadImage(formData, type);
            console.log(uploadResult)
            setNewRecord(uploadResult.data[0].newRecord)

            setTimeout(() => {
                setImage(uploadResult.data[0].location)
                setLoading(false)
            }, 3500);
        };

        reader.readAsArrayBuffer(file);

    }


    const handleSubmit = (evt) => {
        evt.preventDefault();
        submitQuiz()
    }

    const findAnswer = useCallback((answer, choices) => {
        return new Promise((resolve, reject) => {
            let contains = choices.find((choice) =>
                choice === answer
            )
            if (contains) {
                resolve(true)
            }
            else {
                resolve(false)
            }
        })
    },
        [],
    )

    const insertAnswer = useCallback((answer, choices) => {
        choices.push(answer)
        return new Promise((resolve, reject) => {
            let choices_collection = {
                choices: choices.sort(() => .5 - Math.random())
            }

            resolve(choices_collection)
        })
    },
        [],
    )

    const submitQuiz = async () => {
        console.log(defaultLesson)
        let quizData = {
            handbookHash: handbookHash,
            related_lesson_id: relatedLesson ? relatedLesson.id : (defaultLesson ? defaultLesson[0]?.id : null),
            quiz_id: relatedQuiz ? relatedQuiz.value : (defaultQuiz ? defaultQuiz?.value : null),
            question: question,
            correctAnswer: correctAnswer
        }
        let containsAnswer = await findAnswer(quizData.correctAnswer, JSON.parse(choicesCollection).choices)

        if (!containsAnswer) {
            var insert = await insertAnswer(quizData.correctAnswer, JSON.parse(choicesCollection).choices)
        }

        if (update) {
            console.log('update', quizData)
            quizData.quizID = quizID
            let updateResponse = await QuestionUpdate(containsAnswer ? choicesCollection : JSON.stringify(insert), quizData, newRecord)
            if (updateResponse)
                props.history.push("/handbooks/questions", { handbookHash: handbookHash, handbookTitle: props.location.state.handbookTitle, handbookID: props.location.state.handbookID });
        }
        else {
            console.log('creating')
            let createResponse = await QuestionCreate(containsAnswer ? choicesCollection : JSON.stringify(insert), quizData, newRecord)
            if (createResponse)
                props.history.push("/handbooks/questions", { handbookHash: handbookHash, handbookTitle: props.location.state.handbookTitle, handbookID: props.location.state.handbookID });
        }



    }

    return (
        <div className="container">

            <p>&nbsp;</p>
            {update ? <h1 className="title">Update Question</h1> : <h1 className="title">Create Question</h1>}
            <h2 className="subtitle">Question related to a section</h2>

            <div className="field">
                <label className="label">What quiz does this question belong to?</label>
                <div className="control">

                    {update && defaultQuiz ? <Select
                        defaultValue={defaultQuiz}
                        value={relatedQuiz}
                        onChange={(val) => setRelatedQuiz(val)}
                        options={quizzes} /> : ''}

                    {!update && <Select
                        value={relatedQuiz}
                        onChange={(val) => setRelatedQuiz(val)}
                        options={quizzes} />}


                </div>
            </div>
            <div className="field">
                <label className="label">
                    Media:
                </label>
                <input
                    type="file"
                    onChange={uploadImageFile}
                />
            </div>
            <div>
                {image ? <figure className="image is-128x128"><img src={image} alt="imageIcon" /></figure> : ''}
                {image ?
                    <button
                        className="delete"
                        type="button"
                        onClick={async () => await deleteImage(imageID).then(res => {
                            console.log(res)
                            setImage(null)
                            updateImage()
                        })}
                    >
                        Delete Image</button> : ''}
            </div>


            <form onSubmit={handleSubmit}>

                <div className="field">
                    <label className="label">Question:</label>
                    <div className="control">
                        <input
                            name="question"
                            placeholder="Enter question"
                            value={question}
                            onChange={e => setQuestion(e.target.value)}
                            className="input"
                        />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Correct Answer:</label>
                    <div className="control">
                        <input
                            name="correct_answer"
                            placeholder="Enter correct answer"
                            value={correctAnswer}
                            onChange={e => setCorrectAnswer(e.target.value)}
                            className="input"
                        />
                    </div>
                </div>

                {inputList.map((x, i) => {
                    return (
                        <div key={i} className="field">
                            <label className="label">Choice:</label>
                            <div className="control">
                                <input
                                    name="choice"
                                    placeholder="Enter choice"
                                    value={x.choice}
                                    onChange={e => handleInputChange(e, i)}
                                    className="input"
                                />{inputList.length !== 1 && <button
                                    className="mr10"
                                    onClick={() => handleRemoveClick(i)}>X</button>}
                                {inputList.length - 1 === i && <button onClick={handleAddClick}>Add</button>}
                            </div>
                        </div>

                    );
                })}

                <div className="field">
                    <label className="label">Where to find the answer in the handbook</label>
                    <div className="control">
                        {defaultLesson && update && handbookHash ? <Select
                            defaultValue={defaultLesson}
                            value={relatedLesson}
                            onChange={(val) => setRelatedLesson(val)}
                            options={lessons} /> : ''}

                        {!update && <Select
                            isDisabled={handbookHash ? false : true}
                            value={relatedLesson}
                            onChange={(val) => setRelatedLesson(val)}
                            options={lessons} />}


                    </div>
                </div>

                {inputList.length < 2 ? <span className="tag is-danger">There should be at least 2 choices</span> : ''}
                <div className="field is-grouped">
                    <div className="control">
                        {update ? <button className="button is-primary" type="submit" value="Submit" disabled={loading || inputList.length < 2 || !handbookHash}>Update Question</button>
                            : <button className="button is-primary" type="submit" value="Submit" disabled={loading || inputList.length < 2 || !handbookHash || !relatedQuiz}>Create Question</button>}
                    </div>
                </div>


            </form>
        </div>
    );
}

export default HandbookQuestionsForm;