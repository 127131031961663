import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { useJwt } from "react-jwt";
import { Cookies } from 'react-cookie';
import 'react-bulma-components/dist/react-bulma-components.min.css';

// import { faHome } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Login from "./pages/Login";
import Home from "./pages/Home";

import Users from "./pages/Users";
import UsersCreate from "./pages/Users/create";
import UsersEdit from "./pages/Users/edit";

import Activities from "./pages/Activities/Activities";
import ActivitesAddForm from "./pages/Activities/ActivitesAddForm";
import ActivitiesListEventsByUser from "./pages/Activities/ActivitiesListEventsByUser";
import ActivitiesListEventsByUsersFarm from "./pages/Activities/ActivitiesListEventsByUsersFarm";
import RetrieveByFarmUser from "./pages/Activities/RetrieveByFarmUser";
import ActivitityTemplates from "./pages/ActivitityTemplates/ActivityTemplates";
import ActivityTemplatesForm from "./pages/ActivitityTemplates/ActivityTemplatesForm";
import ActivityGenerateForm from "./pages/ActivitityTemplates/ActivityGenerateForm";
import ActivityScheduler from "./pages/ActivitityScheduler/ActivityScheduler";
import ActivitySchedulerForm from "./pages/ActivitityScheduler/ActivitySchedulerForm";
import ActivitiesLogByUserHash from "./pages/ActivitiesLog/ActivitiesLog";

import Alerts from "./pages/Alerts";
import Farms from "./pages/Farms/index";
import FarmsAddForm from "./pages/Farms/FarmsAddForm"
import FarmUpdateForm from "./pages/Farms/FarmUpdateForm"
import FarmListByUser from "./pages/Farms/FarmListByUser"

import HandbookSections from "./pages/HandbookSections/HandbookSections";
import HandbookSectionForm from "./pages/HandbookSectionForm/HandbookSectionForm"

import HandbookLessons from "./pages/HandbookLessons/HandbookLessons"
import HandbookLessonForm from "./pages/HandbookLessonForm/HandbookLessonForm"

import HandbookQuizList from "./pages/HandbookQuiz/HandbookQuiz"
import HandbookQuizForm from "./pages/HandbookQuizForm/HandbookQuizForm"

import HandbookQuestionsList from "./pages/HandbookQuestions/HandbookQuestions";
import HandbookQuestionsForm from "./pages/HandbookQuestionsForm/HandbookQuestionsForm"

import HandbookTools from "./pages/HandbookTools/HandbookTools"
import HandbookToolForm from "./pages/HandbookToolForm/HandbookToolForm"

import LabsDashboard from "./pages/Labs/Dashboard";
import LabsMotherPlantRegister from "./pages/LabsMotherPlant/Register";
import LabsMotherPlantEdit from "./pages/LabsMotherPlant/Edit";
import LabsMotherPlantList from "./pages/LabsMotherPlant/List";
import LabsPendingPlantList from "./pages/LabsPendingPlantList/LabsPendingPlantList";
import LabsBatch from "./pages/LabsBatch/LabsBatch";
import LabsNewStage from "./pages/LabsNewStage/LabsNewStage";
import LabsTimeAttendanceList from "./pages/LabsTimeAttendance/List";
import LabsStatistics from "./pages/LabsStatistics/LabsStatistics";
import LabsUpdateStage from "./pages/LabsUpdateStage/LabsUpdateStage";
import LabsReportContamination from "./pages/LabsReportContamination/LabsReportContamination";
import LabsRescueFungus from "./pages/LabsRescueFungus/LabsRescueFungus";
import LabsCheckBottle from "./pages/LabsCheckBottle/LabsCheckBottle";
import LabsPlantProduction from "./pages/LabsPlantProduction/LabsPlantProduction";
import LabsReportMortality from "./pages/LabsReportMortality/LabsReportMortality";
import LabsStatisticsRecovery from "./pages/LabsStatisticsRecovery/LabsStatisticsRecovery"

import ManualRegistrationPlant from "./pages/ManualRegistrationPlants/ManualRegistrationPlant";

import LabsRawData from "./pages/LabsRawData/LabsRawData";

import ActivitiesEventForm from './pages/ActivitiesEventForm/ActivitiesEventForm'

import CreateAttendance from './pages/LabsTimeAttendance/Create'

import Header from "./components/Header/Header"
import Footer from "./components/Footer/Footer"

export default class App extends Component {
    constructor() {
        super();

        this.state = {
            loggedInStatus: "NOT_LOGGED_IN",
            authToken: "",
            user: {}
        };

        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.validateSession = this.validateSession.bind(this);
    }

    componentDidMount() {
        const cookies = new Cookies();
        cookies.get('authSession') ? this.setState({loggedInStatus: "LOGGED_IN"}) : this.setState({loggedInStatus: "NOT_LOGGED_IN"})
    }

    validateSession() {
        const cookies = new Cookies();
        let authToken = cookies.get('authSession')

        let { decodedToken, isExpired } = useJwt(authToken)

        // console.log("jwt/dcoded:")
        // console.log(decodedToken)

        // console.log("jwt/isExpired:")
        // console.log(isExpired)

        if (isExpired) {
            console.log("JWT/Token -- ", decodedToken, ", isExpired -- ", isExpired)
            this.setState({
                loggedInStatus: "NOT_LOGGED_IN",
                user: {}
            });
            
            this.handleLogout()
            // return
        }
        else {
            // console.log("JWT/Token -- ", decodedToken, " -- Allow, isExpired -- ", isExpired)
            // console.log("logged-status: " + this.loggedInStatus)

            // this.setState({
            //     loggedInStatus: "LOGGED_IN",
            //     user: {}
            // });

            // return
            // this.handleLogin()

            // Issue here
            // this.setState({
            //     loggedInStatus: "LOGGED_IN",
            //     user: {}
            // });
        }

        // let sessionState = this.loggedInStatus
        // console("State: " + sessionState)

        // return true

    }

    logoutSession() {
        const cookies = new Cookies();
        let authToken = cookies.get('authSession')
        console.log(authToken)
        cookies.delete('authSession')
    }

    handleLogout() {
        this.setState({
            loggedInStatus: "NOT_LOGGED_IN",
            user: {}
        });
        window.location = '/'
    }

    handleLogin(data) {
        this.setState({
            loggedInStatus: "LOGGED_IN",
            // user: data.user
        });
    }

    render() {
        return (
            <div>
                {this.state.loggedInStatus !== "NOT_LOGGED_IN" ? <Header/> : ''}                

                    <Switch>
                        <Route
                            exact
                            path={"/"}
                            render={props => (
                                <Login
                                    {...props}
                                    handleLogin={this.handleLogin}
                                    loggedInStatus={this.state.loggedInStatus}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/handbooks"}
                            render={props => (
                                <HandbookSections
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />
                         <Route
                            exact
                            path={"/handbooks/section/create"}
                            render={props => (
                                <HandbookSectionForm
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={"/handbooks/section/update/:id"}
                            render={props => (
                                <HandbookSectionForm
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/handbooks/lessons"}
                            render={props => (
                                <HandbookLessons
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={"/handbooks/lessons/create"}
                            render={props => (
                                <HandbookLessonForm
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                />
                            )}
                        />
                         <Route
                            exact
                            path={"/handbooks/lessons/update/:id"}
                            render={props => (
                                <HandbookLessonForm
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/handbooks/quiz/"}
                            render={props => (
                                <HandbookQuizList
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/handbooks/quiz/create"}
                            render={props => (
                                <HandbookQuizForm
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/handbooks/quiz/update"}
                            render={props => (
                                <HandbookQuizForm
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/handbooks/questions"}
                            render={props => (
                                <HandbookQuestionsList
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={"/handbooks/questions/create"}
                            render={props => (
                                <HandbookQuestionsForm
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={"/handbooks/questions/update"}
                            render={props => (
                                <HandbookQuestionsForm
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                />
                            )}
                        />
                         <Route
                            exact
                            path={"/handbooks/tools"}
                            render={props => (
                                <HandbookTools
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                />
                            )}
                        />
                         <Route
                            exact
                            path={"/handbooks/tools/create"}
                            render={props => (
                                <HandbookToolForm
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                />
                            )}
                        />
                         <Route
                            exact
                            path={"/handbooks/tools/update"}
                            render={props => (
                                <HandbookToolForm
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/home"}
                            render={props => (
                                <Home
                                    {...props}
                                    handleLogout={this.handleLogout}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={"/users"}
                            render={props => (
                                <Users
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={"/users/create"}
                            render={props => (
                                <UsersCreate
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={"/users/edit"}
                            render={props => (
                                <UsersEdit
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/activities"}
                            render={props => (
                                <Activities
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={"/activities/create"}
                            render={props => (
                                <ActivitesAddForm
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={"/activities/events/:user_hash"}
                            render={props => (
                                <ActivitiesListEventsByUser
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={"/activities/events/farms-user/:user_hash"}
                            render={props => (
                                <ActivitiesListEventsByUsersFarm
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />
                         <Route
                            exact
                            path={"/activities/events/update/:activityHash"}
                            render={props => (
                                <ActivitiesEventForm
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={"/activities/events/farms-user/:farm_id/:user_hash"}
                            render={props => (
                                <RetrieveByFarmUser
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={"/activities/templates"}
                            render={props => (
                                <ActivitityTemplates
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={"/activities/templates/create"}
                            render={props => (
                                <ActivityTemplatesForm
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={"/activities/generate/create"}
                            render={props => (
                                <ActivityGenerateForm
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={"/activities/scheduler"}
                            render={props => (
                                <ActivityScheduler
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={"/activities/scheduler/edit"}
                            render={props => (
                                <ActivitySchedulerForm
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={"/activities/log/:user_hash"}
                            render={props => (
                                <ActivitiesLogByUserHash
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />
                        
                        <Route
                            exact
                            path={"/alerts"}
                            render={props => (
                                <Alerts
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={"/farms"}
                            render={props => (
                                <Farms
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/farms/create"}
                            render={props => (
                                <FarmsAddForm
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/farms/update/:userHash"}
                            render={props => (
                                <FarmUpdateForm
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/farms/list/user/:userHash"}
                            render={props => (
                                <FarmListByUser
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/labs/dashboard"}
                            render={props => (
                                <LabsDashboard
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/labs/motherplant/create"}
                            render={props => (
                                <LabsMotherPlantRegister
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/labs/motherplant/edit"}
                            render={props => (
                                <LabsMotherPlantEdit
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/labs/motherplant/list"}
                            render={props => (
                                <LabsMotherPlantList
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/labs/batch/create"}
                            render={props => (
                                <LabsBatch
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/labs/stage/create"}
                            render={props => (
                                <LabsNewStage
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/labs/timeattendance"}
                            render={props => (
                                <LabsTimeAttendanceList
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/labs/statistics"}
                            render={props => (
                                <LabsStatistics
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/labs/attendance/create"}
                            render={props => (
                                <CreateAttendance
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/labs/pending-plants"}
                            render={props => (
                                <LabsPendingPlantList
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/labs/update-stage"}
                            render={props => (
                                <LabsUpdateStage
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/labs/report-contamination"}
                            render={props => (
                                <LabsReportContamination
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/labs/rescue-fungus"}
                            render={props => (
                                <LabsRescueFungus
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />
                        
                        <Route
                            exact
                            path={"/labs/check-bottle"}
                            render={props => (
                                <LabsCheckBottle
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/labs/manual-stage-update"}
                            render={props => (
                                <ManualRegistrationPlant
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/labs/statistics/raw-data"}
                            render={props => (
                                <LabsRawData
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/labs/statistics/plant-production-time"}
                            render={props => (
                                <LabsPlantProduction
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/labs/statistics/recovery"}
                            render={props => (
                                <LabsStatisticsRecovery
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/labs/report-mortality"}
                            render={props => (
                                <LabsReportMortality
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    validateSession={this.validateSession}
                                    logoutSession={this.logoutSession}
                                />
                            )}
                        />

                    </Switch>
                    {this.state.loggedInStatus !== "NOT_LOGGED_IN" ? <Footer/> : ''}

            </div>
        );
    }
}