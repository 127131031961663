import React from 'react'
import LabsSelectMortality from '../../components/Labs/LabsSelectType'
import DataDisplay from '../../components/DataDisplay/DataDisplay'

function StepSelectMortality(props) {

    const setSelectedMortalityType = (e) => {
        props.setSelectedMortalityType(e)
        props.next()
    }

    return (
        <div className="content container">
            <p>&nbsp;</p>

            <h3 className="subtitle has-text-centered">This Bottle Contains The Following:</h3>
            <DataDisplay label={'Bottle'} data={props.barCode} />
            <span>Ex Plant Info:</span>
            <DataDisplay label={'PCI'} data={props.plantPci} />
            <DataDisplay label={'Node'} data={props.plantNode} />
            <br />
            <h4 className="title is-4">Choose Mortality Type</h4>
            <LabsSelectMortality prev={props.prev} nodeTypes={props.mortalityTypes} setSelectedType={setSelectedMortalityType} />
        </div>
    )
}

export default StepSelectMortality;