import React, { useState, useEffect } from 'react';
import { Steps, Step } from "react-step-builder";
import StepBottleScan from './StepBottleScan';
import StepSelectMortality from './StepSelectMortality'
import StepReviewMortalityReport from './StepReviewMortalityReport'

import getTypesByKey from "../../services/types/getTypesByKey";
import reportPlantMortality from "../../services/plants/mortality/reportPlantMortality"

import LabsConfirmationPage from "../../components/Labs/LabsConfirmationPage"
import ModalComponent from "../../components/ModalComponent/ModalComponent"

const LabsReportMortality = (props) => {

    const [barCode, setBarcode] = useState('')
    const [selectedNode, setSelectedNode] = useState('')
    const [selectedMortalityType, setSelectedMortalityType] = useState('')
    const [plantPci, setPlantPci] = useState('')
    const [plantBottleId, setPlantBottleId] = useState('')
    const [plantNode, setPlantNode] = useState('')

    const [mortalityReports, setMortalityReports] = useState()

    const [scanResult, setScanResult] = useState()
    const [mortalityTypes, setMortalityTypes] = useState();
    const [finishedScan, toggleFinishedScan] = useState(false)
    const [loading, isLoading] = useState(false)
    const [loadingText, setLoadingText] = useState('Loading..')

    const confirmationMessage = [
        "Mortality has successfully been reported!",
        "Thank you!"
    ]

    const getMortalityTypes = async () => {
        setMortalityTypes(await getTypesByKey('plants_mortality_types'))
    }
    useEffect(() => {
        getMortalityTypes();
    }, []);

    useEffect(() => {
        if (scanResult) {
            console.log('scanResult', scanResult)
            setPlantPci(scanResult[0].pci)
            setPlantBottleId(scanResult[0].plant_bottle_id)
            setPlantNode(scanResult[0].node)
            toggleFinishedScan(true)
        }
    }, [scanResult])

    const compileReports = () => {

        let currReports = []
        let data = {
            plant_bottle_id: plantBottleId,
            plant_bottle_code: barCode,
            pci: plantPci,
            status: "dead"
        }
        if (mortalityReports)
            currReports = [...mortalityReports]

        currReports.push(data)
        setMortalityReports(currReports)
    }

    const reportMore = () => {

        setBarcode('')
        setSelectedNode('')
        setSelectedMortalityType('')
        setPlantPci('')
        setPlantBottleId('')
        toggleFinishedScan(false)
    }

    const submitReportPlantMortalityRequest = async () => {
        setLoadingText(`Submitting mortality reports..`)
        isLoading(true)
        return new Promise(async (resolve, reject) => {

            var i = 0;
            const sendReportRequest = async () => {
                await reportPlantMortality(mortalityReports[i])
                i++
                if (i < mortalityReports.length) {
                    sendReportRequest()
                }
                else {
                    isLoading(false)
                    resolve(true)
                }
            }
            sendReportRequest();
        })
    }

    useEffect(() => {
        if (props?.location?.state?.passedBarcode) {
            setBarcode(props.location.state.passedBarcode)
        }
    }, [props])

    return (
        <div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h1 className="title has-text-centered">Report Mortality</h1>
            <div className="box">
                {loading && <ModalComponent display={loadingText}></ModalComponent>}
                <Steps>
                    <Step isLoading={isLoading} toggleFinishedScan={toggleFinishedScan} finishedScan={finishedScan} setScanResult={setScanResult} barCode={barCode} setBarcode={setBarcode} stepLabel={'Scan Bottle Barcode'} component={StepBottleScan} />

                    <Step barCode={barCode} plantPci={plantPci} plantNode={plantNode} component={StepSelectMortality} setSelectedMortalityType={setSelectedMortalityType} mortalityTypes={mortalityTypes} />

                    <Step compileReports={compileReports} submitReportPlantMortalityRequest={submitReportPlantMortalityRequest} reportMore={reportMore} selectedMortalityType={selectedMortalityType} selectedNode={selectedNode} scannedPci={plantPci} barCode={barCode} component={StepReviewMortalityReport} />

                    <Step data={confirmationMessage} component={LabsConfirmationPage} />

                </Steps>
            </div>
        </div>
    )
}

export default LabsReportMortality
