import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import QuestionRetrieve from "../../services/handbooks/questions/retrieve"
import QuestionDelete from "../../services/handbooks/questions/delete"
import imagePlaceholder from "../../assets/images/placeholders/image_placeholder.png"
import './HandbookQuestions.scss'

const HandbookQuestionsList = (props) => {
    console.log(props.location.state)
    const [loadingState, setLoadingState] = useState(true);
    const [quizzes, setQuizzes] = useState();
    const [handbookID] = useState(props.location.state.handbookID)
    const [handbookHash] = useState(props.location.state.handbookHash)
    const [handbookTitle] = useState(props.location.state.handbookTitle)

    useEffect(() => {
        const getQuiz = async () => {
            let quiz = await QuestionRetrieve(handbookHash)
            console.log("QuestionRetrieve:", quiz)
            if (quiz.length > 0) {
                setQuizzes(quiz)
            }
            setLoadingState(false)
        }
        getQuiz();
    }, [handbookHash]);

    return (
        <div className='container'>

        <p>&nbsp;</p>

        <h1 className="title">Questions</h1>
        <h2 className="subtitle">List of Questions</h2>

            <nav className="level">
                <div className="level-right">
                    <Link to={{ pathname: "/handbooks/questions/create", state: { handbookHash: handbookHash, handbookID:handbookID, handbookTitle: handbookTitle } }}><button className="button is-primary">Create Question</button></Link>
                </div>
                <div className="level-left">
                    <div className="level-item">
                        <p className="subtitle is-5">
                            <strong>{quizzes?.length}</strong> Record/s
                        </p>
                    </div>
                    <div className="level-item">
                        <div className="field has-addons">
                            <p className="control">
                                <input className="input" type="text" placeholder="Find a quiz" />
                            </p>
                            <p className="control">
                                <button className="button" disabled>
                                    Search
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </nav>
            <ul>
                {!loadingState ? <div>
                    <h1>List of Questions for {handbookTitle}</h1>
                    {quizzes ? quizzes.map((quiz => {
                        return (
                            <div className='list-item' key={quiz.question_hash}>
                                <div className="box">
                                    <article className="media">
                                        <div className="media-left">
                                            <div className="buttons">
                                                <Link to={{ pathname: "/handbooks/questions/update", state: { updateDetails: quiz, handbookHash: handbookHash, handbookID:handbookID, handbookTitle: handbookTitle } }} className="button is-link">Update</Link>
                                                <button
                                                    onClick={async () =>
                                                        await QuestionDelete(quiz.id).then(res => {
                                                            setLoadingState(true)
                                                            if (res) {
                                                                window.location.reload(false);
                                                            }
                                                        })
                                                    }
                                                    type="button"
                                                    className="button is-danger">Delete</button>
                                            </div>
                                        </div>
                                        <div className="media-content">
                                            <div className="content">
                                                <h2>{quiz.questions}</h2>
                                            </div>
                                            <nav className="level is-mobile">
                                                <div className="level-left">
                                                </div>
                                            </nav>
                                        </div>
                                        <div className="media-right">
                                            <figure className="image is-64x64">
                                                <img src={quiz.imageLink ? `${quiz.imageLink}` : imagePlaceholder} alt="handbookImage" />
                                            </figure>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        )

                    })) : <div><h4>No quiz created for this section yet</h4></div>}
                </div>
                    :
                    <div><h4>Loading..</h4>
                    </div>}
            </ul>
        </div>
    )
}

export default HandbookQuestionsList