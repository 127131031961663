import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import updateSection from "../../services/handbooks/sections/updateSection"
import createSection from "../../services/handbooks/sections/createSection"
import uploadImage from "../../services/handbooks/image/uploadImage"
import deleteImage from "../../services/handbooks/image/deleteImage"

import imagePlaceholder from "../../assets/images/placeholders/image_placeholder.png"

import './HandbookSectionForm.scss'


const HandbookSectionForm = (props) => {
    const [title, setTitle] = useState(props.location.state ? props.location.state.handbook.title : "");
    const [originalTitle] = useState(title)
    const [image, setImage] = useState(props.location.state ? props.location.state.handbook.imageLink : "");
    const [imageID, setImageID] = useState();
    const [newRecord, setNewRecord] = useState({});
    const [filename, setFilename] = useState(props.location.state ? props.location.state.handbook.image_filename : "");
    const [updateID] = useState(props.location.state ? props.location.state.handbook.id : "");

    const [upImg, setUpImg] = useState(props.location.state ? props.location.state.handbook.imageLink : "");
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
    const [completedCrop, setCompletedCrop] = useState(null);


    const [uploadedCroppedImage, setUploadedCroppedImage] = useState(false)

    const [updateMode, setUpdateMode] = useState(false)

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
        setUpdateMode(true)
    };

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [completedCrop]);

    const uploadCroppedImage = async (evt) => {
        console.log(previewCanvasRef.current)
        evt.preventDefault();
        previewCanvasRef.current.toBlob(
            async (blob) => {
                const formData = new FormData()
                console.log('image to upload', blob)
                formData.append('handbook_image', blob, 'croppedImage');
                var type = 'sections'

                let uploadResult = await uploadImage(formData, type);

                if (uploadResult?.error_code === 200) {
                    setNewRecord(uploadResult.data[0].newRecord)
                    setImageID(uploadResult.data[0].newRecord.image_id)
                    setFilename(uploadResult.data[0].newRecord.filename)
                    setUploadedCroppedImage(true)
                }


            },
            'image/png',
            1
        );

    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        submitSection()

    }

    const submitSection = async () => {

        if (props.location.state) {
            if (originalTitle === title) {
                let updateResponse = await updateSection(null, updateID, imageID, filename);
                console.log(updateResponse)
                window.location.href = "/handbooks"
            }
            else {
                let updateResponse = await updateSection(title, updateID, imageID, filename);
                console.log(updateResponse)
                window.location.href = "/handbooks"
            }
        }
        else {
            let submitResponse = await createSection(title, newRecord);
            if (submitResponse)
                window.location.href = "/handbooks"
        }

    }
    return (
        <div className="container">

            <p>&nbsp;</p>

            <h1 className="title">{props.location.state ? 'Update' : 'Create'} Section</h1>

            <form onSubmit={handleSubmit}>
                <div className="field">
                    <label className="label">
                        Section Title:
                </label>
                    <input
                        className="input"
                        type="text"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                    />
                </div>
                <div className="field">
                    <label className="label">
                        Section Image:
                </label>
                    <input
                        type="file"
                        onChange={onSelectFile}
                    />
                </div>
                <div className="imageContainer">
                    <div>
                        {image && !updateMode ?
                            <div>
                                <figure className="image is-128x128"><img src={image ? `${image}` : imagePlaceholder} alt="imageIcon" /></figure>
                                <button
                                    className="delete"
                                    type="button"
                                    onClick={async () => await deleteImage(imageID).then(res => {
                                        console.log(res)
                                        setImage(null)
                                    })}
                                >
                                    Delete Image</button>

                            </div>
                            :
                            <ReactCrop
                                src={upImg}
                                onImageLoaded={onLoad}
                                crop={crop}
                                onChange={(c) => setCrop(c)}
                                onComplete={(c) => setCompletedCrop(c)}
                            />
                        }



                    </div>
                    <div className="preview">
                        <label className="label">Image Preview:</label>
                        <canvas
                            ref={previewCanvasRef}
                            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                            style={{
                                width: Math.round(completedCrop?.width ?? 0),
                                height: Math.round(completedCrop?.height ?? 0)
                            }}
                        />
                    </div>
                </div>
                {!image || updateMode ?
                    <div>
                        <button className="button" type = "button" onClick={uploadCroppedImage} disabled={uploadedCroppedImage}>Upload Image</button>
                        <input className='button' type="submit" value="Submit" disabled={!uploadedCroppedImage} />
                    </div>
                    :
                    <input className='button' type="submit" value="Submit" />
                }
            </form>
        </div>
    );
}

export default HandbookSectionForm
