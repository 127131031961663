import React, { useState, useEffect } from 'react'
import soilTypes from '../../constants/soilTypes'
import ownershipTypes from '../../constants/ownershipTypes'
import Select from 'react-select'

import getUsers from "../../services/users/getUsers"
import createFarm from "../../services/farms/createFarm"

import NotificationError from "../../components/NotificationError/NotificationError"
import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"
import FarmMaps from "../../components/FarmMaps/FarmMaps"

const FarmsAddForm = (props) => {

    const [farmName, setFarmName] = useState();
    const [squareFootage, setSquareFootage] = useState();
    const [drainage, setDrainage] = useState();
    const [soilType, setSoilType] = useState();
    const [ownershipType, setOwnershipType] = useState();
    const [irrigated, setIrrigated] = useState();
    const [selected, setSelected] = useState();
    const [userLists, setUserLists] = useState([])
    const [userHash, setUserHash] = useState(props.location?.state?.userHash);
    const [defaultUser, setDefaultUser] = useState();
    const [years, setYears] = useState();

    const [currentCoordinates, setCurrentCoordinates] = useState({})
    const [northCoordinates, setNorthCoordinates] = useState({})
    const [eastCoordinates, setEastCoordinates] = useState({})
    const [westCoordinates, setWestCoordinates] = useState({})
    const [southCoordinates, setSouthCoordinates] = useState({})

    const [enablePlot, setEnablePlot] = useState(false)

    const initialValues = ['1', '0']

    const [apiResponse, setApiResponse] = useState("");


    const [yearsError, setYearsError] = useState({})
    const [footageError, setFootageError] = useState({})

    const handleSubmit = (e) => {
        e.preventDefault();

        const isValid = formValidation()

        console.log(isValid)
        if (isValid) {
            processBackend()
        }

        e.preventDefault();
    }

    useEffect(() => {
        const getUserList = async () => {
            let result = await getUsers()

            var listAll = []
            result.forEach(el => {
                listAll.push({
                    value: el.hash,
                    label: "" + el.mobile_country_code + "-" + el.mobile_number + " - " + el.first_name + " " + el.last_name + " (" + el.organization + ")"
                })
            })

            setUserLists(listAll)
        }

        getUserList()
    }, []);


    useEffect(() => {
        if (userHash && userLists.length > 0) {
            // console.log(userHash)
            let selected = userLists.find((user) => user.value === userHash)
            console.log(selected)
            setDefaultUser(selected)
        }

    }, [userHash, userLists]);

    const formValidation = () => {
        const yearsErr = {}
        const footageErr = {}

        let isValid = true

        if (isNaN(years)) {
            yearsErr.required = "Non numeric characters are not allowed"
            isValid = false
        }

        if (isNaN(squareFootage)) {
            footageErr.required = "Non numeric characters are not allowed"
            isValid = false
        }

        setYearsError(yearsErr)
        setFootageError(footageErr)

        return isValid
    }

    const processBackend = async () => {
        let request = {
            userHash: userHash,
            farmName: farmName,
            squareFootage: squareFootage,
            drainage: drainage,
            years: years,
            soilType: soilType,
            ownershipType: ownershipType,
            irrigated: irrigated === '1' ? 'yes' : 'no',
            selected: selected,
            currentCoordinates: currentCoordinates,
            northCoordinates: northCoordinates,
            eastCoordinates: eastCoordinates,
            westCoordinates: westCoordinates,
            southCoordinates: southCoordinates
        }

        console.log('request values', request)
        let response = await createFarm(request)
        console.log('this is the create request', response)
        if (response.status === 200) {
            setApiResponse("success")
        } else {
            setApiResponse("error")
        }
    }

    return (
        <div className="container">

            <p>&nbsp;</p>
            <h1 className="title">Create Farm</h1>
            <h2 className="subtitle">Create or Edit</h2>
            <div className = "buttons">
            <button
                className="button is-success"
                onClick={() => setEnablePlot(!enablePlot)}
                disabled = {enablePlot}
            >Plot Markers</button>

            <button
                className="button is-warning"
                onClick={() => setEnablePlot(!enablePlot)}
                disabled = {!enablePlot}
            >Clear Plot Markers</button>
            </div>
            {apiResponse === "success" && <NotificationSuccess setMessage={setApiResponse} message="Successfully processed your request" />}
            {apiResponse === "error" && <NotificationError setMessage={setApiResponse} message="There was an error processing your request." />}


            <FarmMaps
                currentCoordinates={setCurrentCoordinates}
                northCoordinates={setNorthCoordinates}
                eastCoordinates={setEastCoordinates}
                westCoordinates={setWestCoordinates}
                southCoordinates={setSouthCoordinates}
                plot={enablePlot}


            ></FarmMaps>

            <form onSubmit={handleSubmit}>
                <div className="field">
                    <label className="label">Which User</label>
                    <div className="control">
                        {userHash ?
                            defaultUser &&
                            <Select
                                defaultValue={defaultUser}
                                onChange={e => setUserHash(e.value)}
                                options={userLists} /> :
                            <Select
                                onChange={e => setUserHash(e.value)}
                                options={userLists} />}

                    </div>
                </div>

                <div className="field">
                    <label className="label">Farm Name</label>
                    <div className="control">
                        <input name="farmName" className="input" type="text" placeholder="Farm Name"
                            onChange={e => setFarmName(e.target.value)}
                        />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Drainage</label>
                    <div className="control">
                        <input name="drainage" className="input" type="text" placeholder="Drainage"
                            onChange={e => setDrainage(e.target.value)}
                        />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Square Footage</label>
                    <div className="control">
                        <input name="squareFootage" className="input" type="text" placeholder="Square Footage"
                            onChange={e => setSquareFootage(e.target.value)}
                        />
                    </div>
                    {Object.keys(footageError).map((key, index) => {
                        return <p key={index} className="help is-danger">{footageError[key]}</p>
                    })}
                </div>
                <div className="field">
                    <label className="label">Years Operation</label>
                    <div className="control">
                        <input name="years" className="input" type="text" placeholder="Years Operation"
                            onChange={e => setYears(e.target.value)}
                        />
                    </div>
                    {Object.keys(yearsError).map((key, index) => {
                        return <p key={index} className="help is-danger">{yearsError[key]}</p>
                    })}
                </div>
                <div className="field">
                    <label className="label">Soil Type</label>
                    <div className="control">
                        <Select
                            // defaultValue=""
                            onChange={e => setSoilType(e.value)}
                            options={soilTypes} />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Ownership Type</label>
                    <div className="control">
                        <Select
                            // defaultValue=""
                            onChange={e => setOwnershipType(e.value)}
                            options={ownershipTypes} />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Irrigated</label>

                    <div className="control">
                        {initialValues.map((i, index) => {
                            return (
                                <label className="radio" key={index}>
                                    <input
                                        type="radio"
                                        value={i}
                                        checked={irrigated?.toString() === i}
                                        onChange={e => setIrrigated(e.currentTarget.value)}
                                    />
                                    {i === '1' ? 'Yes' : 'No'}</label>
                            )
                        })}

                    </div>
                </div>

                <div className="field">
                    <label className="label">Selected</label>

                    <div className="control">
                        {initialValues.map((i, index) => {
                            return (

                                <label className="radio" key={index}>
                                    <input
                                        type="radio"
                                        value={i}
                                        checked={selected?.toString() === i}
                                        onChange={e => setSelected(e.currentTarget.value)}
                                    />
                                    {i === '1' ? 'Yes' : 'No'}</label>
                            )
                        })}

                    </div>
                </div>

                <div className="field is-grouped">
                    <div className="control">
                        <button className="button is-link" type="submit">Create</button>
                    </div>
                    {/* <div className="control">
                        <button className="button is-link is-light">Cancel</button>
                    </div> */}
                </div>

            </form>
        </div>
    )
}

export default FarmsAddForm