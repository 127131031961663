const activityTypes = [
    // { value: '', label: 'Please Select' },
    { value: 'onboarding', label: 'On-boarding' },
    { value: 'visit', label: 'Visit' },
    { value: 'planting', label: 'Planting' },
    { value: 'fertilizing', label: 'Fertilizing' },
    { value: 'spraying', label: 'Spraying' },
    { value: 'tilling', label: 'Tilling' },
    { value: 'irrigating', label: 'Irrigating' },
    { value: 'harvesting', label: 'Harvesting' },
]

export default activityTypes