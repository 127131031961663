
import React, { useState } from 'react'
import NotificationSuccess from "../components/NotificationSuccess/NotificationSuccess"

const Home = (props) => {

    props.validateSession()

    const [initialLogin] = useState(props.location.state ? true : false)
    const [message, setMessage] = useState(props.location.state?.message || null)

    return (
        <div className="container">

            <p>&nbsp;</p>
            {initialLogin && <NotificationSuccess setMessage={setMessage} message={message} />}
            <h1 className="title">Welcome Home</h1>
            <h2 className="is-italic">{props.loggedInStatus}</h2>

        </div>
    )
}

export default Home