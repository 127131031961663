import axios from "axios";

const completeUpdatePlantStage = async (id) => {
    console.log("DEBUG/completeBatchRegistration:")
    console.log(id)

    return new Promise((resolve, reject) => {
        axios.put(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ADMIN_PLANTS_RETRIEVE_PENDING_UPDATE + id,
            {
                status: "complete",
                notes: "done"
            },
            {
                headers: {
                    'Authorization': 'Bearer 4b58c6b1d51f0b45c5426d250324a6c49bb81c5e893b877ebe917f8bf5098a17'
                }
            }).then(async res => {
                console.log("service.completeUpdatePlantStage():")
                console.log(res)
                resolve(res)
            }).catch(err => {
                console.log("service.completeUpdatePlantStage():")
                console.log(err)
                resolve(err)
            })
    })
}

export default completeUpdatePlantStage