import axios from "axios";

const manualUpdatePlant = async (data) => {
    console.log("DEBUG/manualUpdatePlant:")

    return new Promise((resolve, reject) => {
        axios.put(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ADMIN_PLANTS_MANUAL_UPDATE + `update/${data.id}`,
            {
                user_hash: data.userHash,
                date_inoculation: data.date_inoculation.slice(0, 10),
                old_batch_no: data.old_batch_no,
                new_batch_no: data.new_batch_no,
                name_inoculator: data.name_inoculator,
                ex_plants: data.ex_plants,
                current_stage: data.current_stage,
                mother_plant_source: data.mother_plant_source,
                next_transfer_week: data.next_transfer_week,
                nodes: data.nodes,
                plant_bottle_code: data.plant_bottle_code
            },
            {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_API_SEC_BEARER}`
                }
            }).then(async res => {
                console.log("service.manualUpdatePlant():")
                console.log(res)
                var result = res.data.data
                resolve(result)
            }).catch(err => {
                console.log("service.manualUpdatePlant():")
                console.log(err)
                resolve(false)
            })
    })
}

export default manualUpdatePlant