import React, { useState } from 'react'
import NotificationError from "../../components/NotificationError/NotificationError"
import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"

import './LabsNewStage.scss'

const LabsNewStage = (props) => {

    const [apiResponse, setApiResponse] = useState("");

    props.validateSession()

    return (
        <div className="container">
        
            <p>&nbsp;</p>
            <h1 className="title">Register New Batch</h1>
            <h2 className="subtitle">Scan Bottle Barcode</h2>
        
            {apiResponse === "success" && <NotificationSuccess setMessage={setApiResponse} message= "Successfully processed your request"/>}
            {apiResponse === "error" && <NotificationError setMessage={setApiResponse} message="There was an error processing your request." />}

            <form>

                <div className="field">
                    <label className="label">Explant Information</label>
                    <div className="control">
                        <input className="input" type="text" placeholder="Text input" value="M159-B7-X1-W44-S0" />
                    </div>
                </div>
                
                <div className="field is-grouped">
                    <div className="control">
                        <button className="button is-link" type="submit">Register New Ex Plant</button>
                    </div>
                    <div className="control">
                        <button className="button is-link is-light">Add / Edit Note</button>
                    </div>
                </div>

            </form>
            
        
        </div>
    )
}

export default LabsNewStage