import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
// import { Button } from 'react-bulma-components';
// import getActivities from "../../services/activities/getActivities"
import getUsers from "../../services/users/getUsers"
import './Activities.scss'

const Activities = () => {

    const [results, setResults] = useState([])
    const [loadedData, setLoadedData] = useState(false)
    useEffect(() => {
        const getUser = async () => {
            let result = await getUsers()
            setResults(result)
            setLoadedData(true)
        }

        getUser()
    }, []);

    return (
        <div className="container">

            {/* <Button color="primary">My Bulma button</Button> */}

            <p>&nbsp;</p>

            <h1 className="title">Activities</h1>
            <h2 className="subtitle">View activity by user</h2>

            <nav className="level">
                <div className="level-right">
                    <p className="level-item">
                        <Link to="/activities/templates" className="button is-success">Manage Scheduled Templates</Link>
                    </p>
                    <p className="level-item">
                        <a href="/activities/create" className="button is-success">New Single Activity</a>
                    </p>
                    <p className="level-item">
                        <Link to="/activities/generate/create" className="button is-success">Generate Activities</Link>
                    </p>

                    <p className="level-item"><strong>All</strong></p>
                    <p className="level-item"><a href="/#">Active</a></p>
                    <p className="level-item"><a href="/#">Pending</a></p>
                    <p className="level-item"><a href="/#">Completed</a></p>
                </div>

                <div className="level-left">
                    <div className="level-item">
                        <p className="subtitle is-5">
                            <strong>{results.length}</strong> record/s
                        </p>
                    </div>
                    <div className="level-item">
                        <div className="field has-addons">
                            <p className="control">
                                <input className="input" type="text" placeholder="Find a activity" />
                            </p>
                            <p className="control">
                                <button className="button">
                                    Search
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </nav>

            <div className='list'>
                <ul>
                    {loadedData === true ?
                        results.map((result, index) => {
                            return (
                                <div className='list-item' key={index}>
                                    <div className="box">
                                        <article className="media">
                                            <div className="media-left">

                                                <div className="buttons">

                                                    <Link to={{
                                                        pathname: '/activities/events/farms-user/' + result.hash, state: {
                                                            id: result.id,
                                                            userHash: result.hash,
                                                        }
                                                    }} className="button is-link">View By Farm</Link>

                                                </div>

                                            </div>
                                            <div className="media-content">
                                                <div className="content">
                                                    <p>
                                                        <strong>{result.first_name} {result.last_name}</strong> <small>{result.mobile_country_code}-{result.mobile_number}</small> <small>({result.date_created})</small>
                                                        <br />
                                                        <small>
                                                            {console.log('here is the query result', result)}
                                                            {result.organization} {result.barangay}<br />
                                                                Number of farms: {result.total_farms}<br />
                                                                Number of activities: {result.total_activties}
                                                            </small>
                                                    </p>



                                                </div>
                                                <nav className="level is-mobile">
                                                    <div className="level-left">

                                                        <div className="buttons">
                                                            <Link to={{
                                                                pathname: '/activities/events/' + result.hash, state: {
                                                                    id: result.id,
                                                                    userHash: result.hash,
                                                                }
                                                            }} className="button is-link is-light">All Activities</Link>

                                                            <Link to={{
                                                                pathname: '/activities/log/' + result.hash, state: {
                                                                    id: result.id,
                                                                    userHash: result.hash,
                                                                }
                                                            }} className="button is-link is-light">All Action Logs</Link>

                                                            {/* <button className="button is-link is-light" disabled>Create Quiz</button>
                                                                <button className="button is-link is-light">View Quizzes</button> */}
                                                        </div>

                                                    </div>
                                                </nav>
                                            </div>
                                            {/* <div className="media-right">
                                                    <figure className="image is-64x64">
                                                        <img src="https://bulma.io/images/placeholders/128x128.png" alt="Profile" />
                                                    </figure>
                                                </div> */}
                                        </article>
                                    </div>

                                </div>
                            )
                        }) : <div className="sections"><div className="section-header">Loading..</div></div>}
                </ul>
            </div>



        </div>
    )
}

export default Activities
