import axios from "axios";

const postProcessor = async (req) => {
    console.log("DEBUG/postProcessor:")
    console.log(req)

    return new Promise((resolve, reject) => {
        axios.post
            (process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ACTIVITIES_PROCESSOR + "/" + req.templateType,
                {
                    user_hash: req.user_hash,
                    farm_id: req.farm_id,
                    start_datetime: req.start_datetime
                },
                {
                    headers: {
                        "Authorization": "Bearer " + process.env.REACT_APP_API_SEC_BEARER,
                        "Content-Type": "application/json",
                    }
                },
            )
            .then(response => {
                console.log("postProcessor.response:")
                console.log(response)
                resolve(response)
            })
            .catch(error => {
                console.log("postProcessor.error:")
                console.log(error)

                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    console.log('Error', error.message);
                }

                reject(error.message)
            })
    })
}

export default postProcessor