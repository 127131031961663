import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import getHandbooks from "../../services/handbooks/sections/getHandbooks"
import deleteSection from "../../services/handbooks/sections/deleteSection"
import imagePlaceholder from "../../assets/images/placeholders/image_placeholder.png"
import './HandbookSections.scss'

const HandbookSections = (props) => {

    props.validateSession()


    const [handbooks, setHandbooks] = useState([])
    const [loadedData, setLoadedData] = useState(false)

    const getHandbook = async () => {
        let handbook = await getHandbooks()
        console.log(handbook)
        setHandbooks(handbook)
        setLoadedData(true)
    }

    useEffect(() => {

        getHandbook()
    }, []);

    return (
        <div className="container">
            <p>&nbsp;</p>

            <h1 className="title">Handbooks</h1>
            <h2 className="subtitle">List of Sections</h2>

            <nav className="level">
                <div className="level-right">
                    <div className="level-item">
                        <div className="buttons">
                            <Link to={{ pathname: "/handbooks/section/create" }} className="button is-primary">Create Section</Link>
                            {/* <Link to={{ pathname: "/create", createType: "lesson" }} className="button is-link">Create Lesson</Link> */}
                        </div>
                    </div>

                    <p className="level-item"><strong>All</strong></p>
                    <p className="level-item"><a href="/#">Active</a></p>
                    <p className="level-item"><a href="/#">Pending</a></p>
                    <p className="level-item"><a href="/#">Disabled</a></p>
                </div>

                <div className="level-left">
                    <div className="level-item">
                        <p className="subtitle is-5">
                            <strong>{handbooks?.length}</strong> Record/s
                        </p>
                    </div>
                    <div className="level-item">
                        <div className="field has-addons">
                            <p className="control">
                                <input className="input" type="text" placeholder="Find a section" />
                            </p>
                            <p className="control">
                                <button className="button" disabled>
                                    Search
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </nav>

            <div className='list'>
                <ul>
                    {loadedData === true ?
                        handbooks.map((handbook, key) => {
                            return (
                                <div className='list-item' key={handbook.id}>

                                    <div className="box" key={handbook.hash}>

                                        <article className="media">

                                            <div className="media-left">
                                                <div className="buttons">
                                                    <Link to={{ pathname: `handbooks/section/update/${handbook.id}`, state: { handbook } }} className="button is-link is-light">Edit</Link>
                                                    <button onClick={async () =>
                                                        await deleteSection(handbook.id).then(res => {
                                                            setLoadedData(false)
                                                            if (res) {
                                                                getHandbook()
                                                            }
                                                        })
                                                    } className="button is-danger is-light">Delete</button>
                                                </div>
                                            </div>

                                            <div className="media-content">
                                                <div className="content">

                                                    <h2>{handbook.title}</h2>

                                                    <figure className="image is-128x128">
                                                        <img src={handbook.imageLink ? `${handbook.imageLink}` : imagePlaceholder} alt="handbookImage" />
                                                    </figure>

                                                    <div className="buttons">
                                                        <Link to={{ pathname: "/handbooks/lessons/create", state: {handbookHash: handbook.hash, handbookTitle: handbook.title} }} className="button is-link is-light">Create Lesson</Link>
                                                        <Link to={{ pathname: "/handbooks/lessons", state: {handbookHash: handbook.hash, handbookTitle: handbook.title } }} className="button is-link is-light">View Lessons</Link>
                                                        
                                                        <Link to={{ pathname: "/handbooks/quiz/create", state: { handbookID: handbook.id } }} className="button is-link is-light">Create Quiz</Link>
                                                        <Link to={{ pathname: "/handbooks/quiz", state: { handbookID: handbook.id, handbookTitle: handbook.title } }} className="button is-link is-light">View Quizzes</Link>

                                                        {/* <button className="button is-link is-light" disabled>Create Quiz</button>
                                                        <button className="button is-link is-light">View Quizzes</button> */}

                                                        <Link to={{ pathname: "/handbooks/questions/create", state: { handbookHash: handbook.hash, handbookTitle: handbook.title, handbookID: handbook.id } }} className="button is-link is-light">Create Questions</Link>
                                                        <Link to={{ pathname: "/handbooks/questions", state: { handbookHash: handbook.hash, handbookTitle: handbook.title, handbookID: handbook.id } }} className="button is-link is-light">View Questions</Link>
                                                        
                                                    </div>

                                                </div>
                                            </div>

                                        </article>
                                    </div>

                                </div>
                            )
                        }) : <div className="sections"><div className="section-header">Loading..</div></div>}
                </ul>

            </div>

        </div >
    )
}

export default HandbookSections
