import React, { useState, useEffect } from 'react'
// import NotificationError from "../../components/NotificationError/NotificationError"
// import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"
import { Steps, Step } from "react-step-builder";
import StepPropagation from "./StepPropagation";
// import StepMediaType from "./StepMediaType";
import StepLabAids from "./StepLabAids";
import StepMotherPlant from "./StepMotherPlant";
// import StepExPlant from "./StepExPlant";
// import StepPlantBottle from "./StepPlantBottle";
import StepProtocolSelection from "./StepProtocolSelection";
import StepProtocolConfirmation from "./StepProtocolConfirmation"
import StepLaminarFlow from "./StepLaminarFlow";
import StepNumOfShoots from "./StepNumOfShoots";
import FinalStep from "./StepFinalReview";
import StepFinalConfirmation from "./StepFinalConfirmation"
import { useJwt } from "react-jwt";
import { Cookies } from 'react-cookie';
import LabsPendingPlantList from "../LabsPendingPlantList/LabsPendingPlantList"
import retrievePendingPlants from "../../services/plants/retrievePendingPlants"

import './LabsBatch.scss'

const LabsBatch = (props) => {

    // const [apiResponse, setApiResponse] = useState("");
    const [pendingPlants, setPendingPlants] = useState([])
    const [loadedData, setLoadedData] = useState(false)
    const cookies = new Cookies();
    const authToken = cookies.get('authSession')
    const signed_user_hash = useJwt(authToken).decodedToken?.data?.hash
    console.log('signed user id', signed_user_hash)

    props.validateSession()

    useEffect(() => {
        const getPendingPlants = async () => {
            let result = await retrievePendingPlants(signed_user_hash)
            let filteredResult = result ? result?.filter(res => res.registration_type !== 'update') : []
            console.log(filteredResult)
            setPendingPlants(filteredResult)
            setLoadedData(true)
        }
        if (signed_user_hash)
            getPendingPlants()
    }, [signed_user_hash]);

    console.log('pending plants', pendingPlants)
    return (
        loadedData ? (
            pendingPlants?.length > 0 ?
                <div>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <div className="box">
                        <LabsPendingPlantList pendingPlants={pendingPlants} />
                    </div>
                </div>
                :
                <div>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <div className="box">
                        <Steps>
                            <Step component={StepPropagation} />
                            <Step component={StepMotherPlant} />
                            <Step component={StepNumOfShoots} />
                            <Step component={StepLaminarFlow} />
                            <Step component={StepLabAids} />
                            <Step component={StepProtocolSelection} />
                            <Step component={StepProtocolConfirmation} />
                            {/* <Step component={StepMediaType} />
            <Step component={StepExPlant} />
            <Step component={StepPlantBottle} /> */}
                            <Step component={FinalStep} signed_user_hash={signed_user_hash} />
                            <Step component={StepFinalConfirmation} />
                        </Steps>
                    </div>
                </div>
        ) : <div><p>&nbsp;</p><p>&nbsp;</p>Loading..</div>

    )
}

export default LabsBatch