import React from 'react'

const LabsRawTableBody = ({
    displayText,
    loading,
    headers,
    data,
    next,
    setSelectedData,
    type,
    tableStyle }) => {

    console.log(data)
    return (
        <>
            {
                !loading && data ?
                    data?.map((info, i) => {
                        return (
                            <tbody key={info.id}>
                                <tr>
                                    <td>{info.date_of_inoculation.slice(0, 10)}</td>
                                    <td>{info.name_of_inoculator}</td>
                                    <td>{info.lab_aid}</td>
                                    <td>{info.protocol}</td>
                                    <td>{info.mother_plant}</td>
                                    <td>{info.batch_number}</td>
                                    <td>{info.current_stage}</td>
                                </tr>
                            </tbody>
                        )

                    })
                    :
                    <tbody>
                        <tr>
                            <td>{displayText}</td>
                        </tr>
                    </tbody>
            }
        </>
    )
}

export default LabsRawTableBody
