import React, { useState, useEffect } from 'react'

import auditTrail from "../../services/auditTrail ";
import reportCallusFormation from "../../services/plants/mother/reportCallusFormation";
import reportPlantMortality from "../../services/plants/mortality/reportPlantMortality"


function StepConfirmPage(props) {

    const [transferDisplayMessage, setTransferDisplayMessage] = useState("")
    const [rootTransferDisplayMessage, setRootTransferDisplayMessage] = useState("")
    const [callusReportedDisplayMessage, setCallusReportedDisplayMessage] = useState("")
    const [dormantReportedDisplayMessage, setDormantReportedDisplayMessage] = useState("")
    const [loadingTransfer, setLoadingTransfer] = useState(false)
    const [loadingRootTransfer, setLoadingRootTransfer] = useState(false)
    const [loading, setLoading] = useState(true)

    console.log(props.state.shoots)


    const reportCallus = () => {
        return new Promise(async (resolve, reject) => {
            let counter = props.totalReportedCallusCount
            var i = 0;
            console.log('reporting callus', i, counter)

            const reportCallusRequest = async () => {
                await reportCallusFormation(
                    props.state.shoots[0].plant_registration_id)
                i++
                if (i < counter) {
                    reportCallusRequest()
                }
                else {
                    resolve(true)
                }
            }
            reportCallusRequest();
        }
        )
    }

    const reportDormant = () => {
        return new Promise(async (resolve, reject) => {
            let reportedDormant = props.totalReportedDormant
            var i = 0;

            const sumOfReport = reportedDormant.reduce(add, 0);

            function add(accumulator, a) {
                return accumulator + a;
            }

            const reportDormantRequest = async () => {
                await reportPlantMortality(
                    props.state.shoots[0])
                i++
                if (i < sumOfReport) {
                    reportDormantRequest()
                }
                else {
                    resolve(true)
                }
            }
            reportDormantRequest();
        }
        )
    }

    const handleAuditTrail = async () => {

        setLoading(true)

        if (props.totalReportedCallusCount > 0)
            await reportCallus();

        if (props.totalReportedDormant.length > 0)
            reportDormant();

        let auditData = {
            type: "plant_registration",
            user_hash: props.state.userHash,
            activity: "Confirmation Page Done: Continue Batch Registration on Step 1-2",
            data: JSON.stringify({ transferStageCollection: transferDisplayMessage, transferRootCollection: rootTransferDisplayMessage })
        }
        await auditTrail(auditData).then(() => {
            setLoading(false)
            props.next()
        })
    }
    useEffect(() => {
        if (props.state.transferCollection) {
            const messageArray = []
            props.state.transferCollection.map((collection, index) => {
                let message = `${collection.length} plant/s will be transferred from ${collection[0].pci_s !== 'R' ? `S${collection[0].pci_s}` : collection[0].pci_s} to ${collection[0].pci_s !== 'R' ? `S${parseInt(collection[0].pci_s) + 1}` : collection[0].pci_s}`;
                messageArray.push(message)
                console.log(message, messageArray)
                if (messageArray.length === props.state.transferCollection.length) {
                    setTransferDisplayMessage(messageArray)
                    setLoadingTransfer(true)
                }

                return null
            })
        }

        if (props.state.transferRootCollection) {
            const rootMessageArray = []
            props.state.transferRootCollection.map((collection, index) => {
                let rootMessage = `${collection.length} plant/s will be transferred from ${collection[0].pci_s !== 'R' ? `S${collection[0].pci_s}` : collection[0].pci_s} to R`;
                rootMessageArray.push(rootMessage)

                if (rootMessageArray.length === index + 1) {
                    setRootTransferDisplayMessage(rootMessageArray)
                    setLoadingRootTransfer(true)
                }

                return null
            })
        }

        if (props.totalReportedCallusCount > 0) {
            setCallusReportedDisplayMessage(`${props.totalReportedCallusCount} callus will be transferred to S1`)
        }

        if (props.totalReportedDormant?.length > 0) {
            setDormantReportedDisplayMessage(`The following ex plant/s will be reported as dormant: ${props.state.shoots[0].pci}`)
        }

        setLoading(false)
        // eslint-disable-next-line  
    }, [])

    return (
        <div className="container">
            <p>&nbsp;</p>
            <h1 className="title has-text-centered">Update Plant Stage</h1>
            <h4 className="title has-text-centered">Confirmation</h4>
            {!loading ?
                <div className="has-text-centered">
                    {loadingTransfer && transferDisplayMessage.map((collection, index) => {
                        return (
                            <h1 key={index}>{collection}</h1>
                        )
                    })}

                    {loadingRootTransfer && rootTransferDisplayMessage.map((collection, index) => {
                        return (
                            <h1 key={index}>{collection}</h1>
                        )
                    })}

                    <div style={{ marginTop: '1rem' }}>{callusReportedDisplayMessage}</div>
                    <div style={{ marginTop: '1rem' }}>{dormantReportedDisplayMessage}</div>
                </div>
                :
                <div>Loading..</div>
            }



            <div className="buttons">
                <button
                    className="button is-info is-light is-large"
                    onClick={handleAuditTrail}
                >
                    Confirm and proceed
        </button>
                <button className="button is-info is-light is-large" onClick={props.prev}>
                    Go Back
        </button>
            </div>
        </div>
    );
}

export default StepConfirmPage;
