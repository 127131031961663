// FinalStep.js
import React from "react";
import registerExPlantRequest from '../../services/plants/registerExPlant'
import { useJwt } from "react-jwt";
import { Cookies } from 'react-cookie';
import moment from 'moment'
import Button from '../../components/Button/Button'

const StepSelectNode = (props) => {
    const cookies = new Cookies();
    const authToken = cookies.get('authSession')
    const signed_user_hash = useJwt(authToken).decodedToken?.data?.hash

    const motherPlantId = props.state?.batchData.mother_plant_id;
    const batch = props.state?.batchData.batch;
    const stage = `0`;
    const initialXCount = `1`;
    const week = moment().format('W');

    const registerExPlantProp = (code, node) => {
        if (!props.state.exPlants) {
            let currExPlant = {
                registrationNumber: 1,
                code: code,
                node: node,
                tempPci: `M${motherPlantId}-B${batch}-X${initialXCount}-W${week}-S${stage}`,
                registrationWeek: week,
                batch: batch
            }
            props.setLatestPlant(currExPlant)
            props.setState('exPlants', [currExPlant])
        }

        else {
            let explants = props.state.exPlants
            let currExPlant = {
                registrationNumber: props.state?.exPlants?.length + 1,
                code: code,
                node: node,
                tempPci: `M${motherPlantId}-B${batch}-X${props.state?.exPlants?.length + 1}-W${week}-S${stage}`,
                registrationWeek: week,
                batch: batch
            }
            props.setLatestPlant(currExPlant)
            props.setState('exPlants', [...explants, currExPlant])
        }

        props.next();
    }
    const selectNodeType = async (node) => {
        const registerExPlant = async () => {
            return new Promise(async (resolve, reject) => {
                console.log('will start bottle registration')
                let res = await registerExPlantRequest(props.validBottleCode, props.state?.batchData?.registration_id, signed_user_hash, null, null, node)
                if (res) {
                    props.setRegisterData(res.data.data);
                    resolve(true);
                }
                console.log('explant reg result', res)
            })

        }

        let request = await registerExPlant();
        if (request)
            registerExPlantProp(props.validBottleCode, node);
    }


    return (
        <div className="container">
            <h1 className="title">Select Node Type</h1>
            <div className='buttons'>
                <ul>
                    {props.nodeTypes ?
                        props.nodeTypes.map((result, index) => {
                            return (
                                <button key={index} className="button is-info is-large"
                                    value={result.value}
                                    onClick={() => selectNodeType(result.value)}
                                >
                                    {result.value}</button>
                            )
                        }) : <div className="sections"><div className="section-header">Loading..</div></div>}
                </ul>
            </div>

            <div className="buttons">
                <Button
                    onClick={props.prev}
                    label={'Back'}
                    className={'button is-primary'}
                />
            </div>
        </div>
    );
}

export default StepSelectNode;