import axios from "axios";

const postUsers = async (mobileCountryCode, mobileNumber, firstName, lastName, organization, barangay, province, yearsFarming, userType, userEmail) => {
    console.log("DEBUG/postUsers:")
    console.log(mobileCountryCode)
    console.log(mobileNumber)
    console.log(firstName)
    console.log(lastName)
    console.log(organization)
    console.log(barangay)
    console.log(province)
    console.log(yearsFarming)
    return new Promise((resolve, reject) => {
        axios.post
            (process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_USERS_CREATE,
                {
                    mobile_country_code: mobileCountryCode,
                    mobile_number: mobileNumber,
                    first_name: firstName,
                    last_name: lastName,
                    organization: organization,
                    barangay: barangay,
                    province: province,
                    years_farming: yearsFarming,
                    type: userType,
                    email: userEmail
                },
                {
                    headers: {
                        "Authorization": "Bearer " + process.env.REACT_APP_API_SEC_BEARER,
                        "Content-Type": "application/json",
                    }
                },
            )
            .then(response => {
                console.log("handleSubmit.response:")
                console.log(response)
                resolve(response)
            })
            .catch(error => {
                console.log("handleSubmit.error:")
                console.log(error)

                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    console.log('Error', error.message);
                }

                reject(error.message)
            })
    })
}

export default postUsers