import axios from "axios";

// const createSection = async (sectionTitle, newRecord, id) => {
const updateSection = async (sectionTitle, id, imageID, filename) => {
    var updateData = {}
    if (sectionTitle === null) {
        updateData = {
            // title: sectionTitle,
            image_id: imageID,
            filename: filename
        }
    }
    else{
        updateData = {
            title: sectionTitle,
            image_id: imageID,
            filename: filename
        }
    }
    return new Promise((resolve, reject) => {
        axios.put(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_HANDBOOKS + id,
            updateData,
            {
                headers: {
                    'Authorization': 'Bearer ' + process.env.REACT_APP_API_SEC_BEARER
                }
            },
        )
            .then(response => {
                console.log(response)
                resolve(response)
            })
            .catch(error => {
                reject(error)
            });
    })
}

export default updateSection