// FinalStep.js
import React from 'react'
import { Link } from "react-router-dom";

const StepScanResult = (props) => {
    return (
        <div className="container">
            <p>&nbsp;</p>

            <h3 className="subtitle has-text-centered">This Bottle Contains The Following:</h3>
            {props.code && <p>Bottle: {props.code}</p>}
            <span>Ex Plant INFO</span>
            {props.pci && <p>PCI: {props.pci}</p>}
            {props.node && <p>Node: {props.node}</p>}



            <div className="buttons">
                <button className="button is-info is-light is-large" onClick={props.prev}>Previous</button>
                <Link to={{
                    pathname: '/labs/update-stage',
                    state: {
                        passedBarcode: props.code,
                    }
                }} className="button is-success is-large">Update Plant Stage</Link>
                <Link to={{
                    pathname: '/labs/report-mortality',
                    state: {
                        passedBarcode: props.code,
                    }
                }} className="button is-success is-large">Report Mortality</Link>
            </div>

        </div>
    );
}

export default StepScanResult;