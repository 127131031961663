import React from 'react'

const SearchInput = ({
    disabled,
    placeholder,
    setInoculationDate,
    setOldBatchNum,
    setMotherPlantSource,
    setInoculatorName,
    setNewBatchNumber,
    formClassName,
    value,
    editData,
    setEditData,
    formStyle,
    editField }) => {

    const updateData = (e) => {
        let updatedData = editData
        let value = editField === 'plant_bottle_code' ? process.env.REACT_APP_BARCODE_INITIAL + e : e
        updatedData[`${editField}`] = value
        setEditData(updatedData)
    }

    return (
        // <p className={formClassName}>
            <input
                style={formStyle}
                disabled={disabled}
                maxLength={editField === 'plant_bottle_code' ? "4" : "100"}
                className="input"
                type="text"
                placeholder={placeholder}
                defaultValue={editField === 'plant_bottle_code' && value ? value.slice(3) : value}
                onChange={(e) => {
                    setInoculationDate && setInoculationDate(e.target.value)
                    setOldBatchNum && setOldBatchNum(e.target.value)
                    setMotherPlantSource && setMotherPlantSource(e.target.value)
                    setInoculatorName && setInoculatorName(e.target.value)
                    setNewBatchNumber && setNewBatchNumber(e.target.value)
                    setEditData && updateData(e.target.value)
                }}
            ></input>
        // </p>
    )
}

export default SearchInput
