// Step1.js
import React, { useState } from 'react'
import NotificationSuccess from "../../../components/NotificationSuccess/NotificationSuccess"

function StepUpdateBottleCheck(props) {

    const [apiResponse, setApiResponse] = useState('Bottle has successfully been scanned')

    const handleBottleContent = (content) => {
        let allData = props.updateData
        allData[allData?.length - 1].content = content
        props.setUpdateData(allData);
        props.next()
    }

    return (

        <div className="container">

            <p>&nbsp;</p>
            <h1 className="title has-text-centered">Update Plant Stage</h1>
            {/* <h2 className="subtitle"></h2> */}
            <NotificationSuccess setMessage={setApiResponse} message={apiResponse} />
            <h4 className="title">What is inside the bottle?</h4>

            <br></br>
            <br></br>
            <div className="columns is-centered">
                <button className="button is-info is-large" onClick={() => {
                    // props.setBatchStatus(true)
                    // props.setState('batchData', results[0])
                    handleBottleContent('explant')
                }
                }>Ex Plants</button>
                &nbsp;&nbsp;&nbsp;
                <button className="button is-info is-large" onClick={() => {
                    // props.setBatchStatus(true)
                    // props.setState('batchData', results[0])
                    handleBottleContent('callus')
                }
                }>Callus</button>
            </div>
            <p>&nbsp;</p>
        </div>
    );
}

export default StepUpdateBottleCheck;