import React, { useState, useEffect, useRef } from 'react'
import { GoogleMap, LoadScript, Marker, Polygon } from '@react-google-maps/api';
import { StandaloneSearchBox } from '@react-google-maps/api';
const { withScriptjs } = require("react-google-maps");
// const ScriptLoaded = require("@react-google-maps/api/src/docs/ScriptLoaded");

const FarmMaps = ({ currentCoordinates,
    northCoordinates,
    eastCoordinates,
    westCoordinates,
    southCoordinates,
    current,
    north,
    east,
    west,
    south,
    plot,
    updatePlot,
    update }) => {
    const [currentPosition, setCurrentPosition] = useState({});
    const [northMarker, setNorthMarker] = useState({});
    const [eastMarker, setEastMarker] = useState({});
    const [westMarker, setWestMarker] = useState({});
    const [southMarker, setSouthMarker] = useState({});

    const [path, setPath] = useState([]);

    const search = useRef();

    useEffect(() => {

        const clearMarkers = () => {

            console.log('clearing')
            setSouthMarker({
                lat: 0,
                lng: 0
            })
            setWestMarker({
                lat: 0,
                lng: 0
            })
            setEastMarker({
                lat: 0,
                lng: 0
            })
            setNorthMarker({
                lat: 0,
                lng: 0
            })


            northCoordinates({
                lat: 0,
                lng: 0
            })
            eastCoordinates({
                lat: 0,
                lng: 0
            })
            westCoordinates({
                lat: 0,
                lng: 0
            })
            southCoordinates({
                lat: 0,
                lng: 0
            })
            setPath([
                {
                    lat: 0,
                    lng: 0
                }
            ])
        }

        const setMarkers = () => {

            console.log(currentPosition)
            const northPosition = {
                lat: currentPosition.lat + 0.0000584,
                lng: currentPosition.lng - 0.0000647
            }

            const eastPosition = {
                lat: currentPosition.lat + 0.0000644,
                lng: currentPosition.lng + 0.0000633
            }

            const westPosition = {
                lat: currentPosition.lat - 0.0000296,
                lng: currentPosition.lng - 0.0000627
            }

            const southPosition = {
                lat: currentPosition.lat - 0.0000286,
                lng: currentPosition.lng + 0.0000653
            }

            setSouthMarker(southPosition)
            setWestMarker(westPosition)
            setEastMarker(eastPosition)
            setNorthMarker(northPosition)


            northCoordinates(northPosition)
            eastCoordinates(eastPosition)
            westCoordinates(westPosition)
            southCoordinates(southPosition)
            setPath([
                eastPosition,
                northPosition,
                westPosition,
                southPosition
            ])
        }

        if (!update) {
            if (plot) {
                console.log('plotting')
                setMarkers()
            }
            else {
                clearMarkers()
            }
        }

        else if (update) {
            if (updatePlot) {
                clearMarkers()
            }
            else if (updatePlot === false) {
                setMarkers()
            }
        }

    }, [updatePlot, update, plot, currentPosition, northCoordinates, eastCoordinates, westCoordinates, southCoordinates])

    useEffect(() => {

        const error = error => {
            console.log('map error', error)
        }
        const success = position => {
            console.log('old component', position)
            const currentPosition = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            }
            setCurrentPosition(currentPosition);

            currentCoordinates(currentPosition)

        };

        if (current && north && east && west && south) {
            console.log('updating')
            setPath([
                east,
                north,
                west,
                south
            ])
            setSouthMarker(south)
            setWestMarker(west)
            setEastMarker(east)
            setNorthMarker(north)
            setCurrentPosition(current);

            currentCoordinates(current)
            northCoordinates(north)
            eastCoordinates(east)
            westCoordinates(west)
            southCoordinates(south)
        }
        else {
            console.log('fetching actual location')
            navigator.geolocation.getCurrentPosition(success, error, {
                enableHighAccuracy: true,
            }, withScriptjs);
        }

    }, [currentCoordinates, northCoordinates, eastCoordinates, westCoordinates, southCoordinates, current, north, east, west, south])

    const onMarkerDragEnd = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        setCurrentPosition({ lat, lng })
        currentCoordinates({ lat, lng })
        console.log(lat, lng)
    };

    const onNorthMarkerDragEnd = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        setPath([
            eastMarker,
            { lat, lng },
            westMarker,
            southMarker
        ])
        setNorthMarker({ lat, lng })
        northCoordinates({ lat, lng })
    };

    const onEastMarkerDragEnd = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        setPath([
            { lat, lng },
            northMarker,
            westMarker,
            southMarker
        ])
        setEastMarker({ lat, lng })
        eastCoordinates({ lat, lng })
    };

    const onWestMarkerDragEnd = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        setPath([
            eastMarker,
            northMarker,
            { lat, lng },
            southMarker
        ])
        setWestMarker({ lat, lng })
        westCoordinates({ lat, lng })
    };

    const onSouthMarkerDragEnd = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        setPath([
            eastMarker,
            northMarker,
            westMarker,
            { lat, lng },
        ])
        setSouthMarker({ lat, lng })
        southCoordinates({ lat, lng })
    };

    const mapStyles = {
        height: "70vh",
        width: "100%"
    };

    const onPlacesChanged = () => {
        let searchResult = search.current.searchBox.getPlaces();
        const lat = searchResult[0].geometry.location.lat()
        const lng = searchResult[0].geometry.location.lng()
        setCurrentPosition({ lat, lng })
        currentCoordinates({ lat, lng })
        console.log(lat, lng)
    }

    return (
        <div className="container">
            <p>&nbsp;</p>
            <LoadScript
                id="script-loader"
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                libraries={["places"]}

            >

                <StandaloneSearchBox
                    ref={search}
                    onLoad={((searchBox) => search.current.searchBox = searchBox)}
                    onPlacesChanged={onPlacesChanged}
                >
                    
                <div className="field">
                    <label className="label">Search Location</label>
                    <div className="control">
                        <input name="drainage" className="input" type="text" placeholder="Location"
                            // onChange={e => setDrainage(e.target.value)}
                        />
                    </div>
                </div>
                </StandaloneSearchBox>
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={22}
                    center={currentPosition}>
                    {
                        currentPosition.lat ?
                            <Marker
                                position={currentPosition}
                                onDragEnd={(e) => onMarkerDragEnd(e)}
                                draggable={true} /> :
                            null
                    }

                    {
                        northMarker.lat ?
                            <Marker
                                position={northMarker}
                                onDragEnd={(e) => onNorthMarkerDragEnd(e)}
                                draggable={true} /> :
                            null
                    }

                    {
                        eastMarker.lat ?
                            <Marker
                                position={eastMarker}
                                onDragEnd={(e) => onEastMarkerDragEnd(e)}
                                draggable={true} /> :
                            null
                    }

                    {
                        westMarker.lat ?
                            <Marker
                                position={westMarker}
                                onDragEnd={(e) => onWestMarkerDragEnd(e)}
                                draggable={true} /> :
                            null
                    }

                    {
                        southMarker.lat ?
                            <Marker
                                position={southMarker}
                                onDragEnd={(e) => onSouthMarkerDragEnd(e)}
                                draggable={true} /> :
                            null
                    }

                    <Polygon
                        editable
                        draggable
                        path={path}
                    />

                </GoogleMap>
            </LoadScript>


        </div>
    )
}

export default FarmMaps;