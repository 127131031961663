import axios from "axios";

const manualUpdateConfirmation = async (data) => {
    console.log("DEBUG/manualUpdateConfirmation:")

    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ADMIN_PLANTS_MANUAL_UPDATE + `confirm/${data.id}`,
            {   
                plant_bottle_code: data.plant_bottle_code
            },
            {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_API_SEC_BEARER}`
                }
            }).then(async res => {
                console.log("service.manualUpdateConfirmation():")
                console.log(res)
                var result = res.data.data
                resolve(result)
            }).catch(err => {
                console.log("service.manualUpdateConfirmation():")
                console.log(err)
                resolve(false)
            })
    })
}

export default manualUpdateConfirmation