import axios from "axios";

const updateQuiz = async (quizData) => {
    return new Promise((resolve, reject) => {
        axios
            .put(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_QUIZZES + quizData.quizID,
                {
                    title: quizData.title,
                    handbook_id: quizData.handbookID
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + process.env.REACT_APP_API_SEC_BEARER
                    }
                },
            )
            .then(response => {
                console.log("handleSubmit.response:")
                console.log(response)
                resolve(true)
            })
            .catch(error => {
                alert(error)
                console.log("login error", error);
                reject(error)
            });
    })
}

export default updateQuiz