
import React from 'react'

const Alerts = (props) => {

    props.validateSession()

    return (
        <div className="container">

            <p>&nbsp;</p>

            <h1 className="title">Alerts</h1>
            <h2 className="subtitle">View activity by user</h2>

        </div>
    )
}

export default Alerts