// FinalStep.js
import React from "react";

function StepUpdateInstructions(props) {
    console.log("props: ", props)
    return (
        <div className="content container">
            <p>&nbsp;</p>
            <p>&nbsp;</p>

            <div className="columns is-centered">
                <h1 className="title">Update Plant Stage</h1>
                <br />
            </div>
            <div>
                <h4 className="title">Scan all bottle one by one </h4>
                <h4 className="title">Input if the bottle contains Ex Plants or Callus</h4>
                <h4 className="title">Choose how many</h4>
            </div>
            <div className="buttons columns is-centered">
                <button className="button is-info is-light is-large" onClick={props.next}>Start</button>
            </div>

        </div>
    );
}

export default StepUpdateInstructions;