import React from "react";
import { Link } from "react-router-dom";
import { Cookies } from 'react-cookie';
import './Header.scss'

const Header = (props) => {

    const toggleBurgerMenu = () => {
        document.querySelector('.navbar-menu').classList.toggle('is-active');
    }

    const handleLogout = (e) => {
        console.log("handleLogout: processing...")

        const cookies = new Cookies();
        let authSession = cookies.get('authSession')

        console.log("Cookie:authSession: " + authSession)

        cookies.remove('authSession')
        localStorage.clear();
        console.log("Cookie:authSession: Cleared")

        window.location = `/?logout=true`
    }

    return (
        <div>
            <header>
                <script defer src="https://use.fontawesome.com/releases/v5.14.0/js/all.js"></script>
                <nav className="navbar is-primary" role="navigation" aria-label="main navigation">
                    <div className="navbar-brand">
                        <a className="navbar-item" href="http://manager.vanilladream.ph">
                            <img src={require('../../assets/images/placeholders/vd-logo-web.png')} alt="VD Logo" />
                        </a>

                        <a href="/#" role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={toggleBurgerMenu}>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div>

                    <div id="navbarBasicExample" className="navbar-menu">
                        <div className="navbar-start">

                            <Link to="/home" className="navbar-item" onClick={toggleBurgerMenu}>Home</Link>

                            <div className="navbar-item has-dropdown is-hoverable">
                                <Link to="/labs/dashboard" className="navbar-link" onClick={toggleBurgerMenu}>Laboratory</Link>

                                <div className="navbar-dropdown">
                                    {/* <Link to="/labs/dashboard" className="navbar-item" onClick={toggleBurgerMenu}>Dashboard</Link> */}
                                    {/* <hr className="navbar-divider" /> */}
                                    <span className="navbar-item">Overall Stats</span>
                                    <span className="navbar-item">Plant Stats</span>
                                    <hr className="navbar-divider" />
                                    <Link to="/labs/batch/create" className="navbar-item">Register New Batch</Link>
                                    <Link to="/labs/update-stage" className="navbar-item">Update Plant Stage</Link>
                                    <hr className="navbar-divider" />
                                    <Link to="/labs/manual-stage-update" className="navbar-item">Update Plant Stage Manual</Link>
                                    <hr className="navbar-divider" />
                                    <hr className="navbar-divider" />
                                    <Link to="/labs/motherplant/list" className="navbar-item">Manage Mother Plant</Link>
                                    <hr className="navbar-divider" />
                                    <span className="navbar-item">Biometics Attendance</span>
                                    <hr className="navbar-divider" />
                                    <span className="navbar-item">Weather</span>
                                    <Link to="/labs/timeattendance" className="navbar-item">Time & Attendance</Link>

                                </div>
                            </div>


                            {/* <Link to="/users" className="navbar-item" onClick={toggleBurgerMenu}>Users</Link> */}
                            <div className="navbar-item has-dropdown is-hoverable">
                                <Link to="/users" className="navbar-link" onClick={toggleBurgerMenu}>Users</Link>

                                <div className="navbar-dropdown">
                                    {/* <hr className="navbar-divider" /> */}
                                    <a href="/users/create" className="navbar-item">Create New User</a>
                                </div>
                            </div>

                            
                            {/* <Link to="/activities" className="navbar-item" onClick={toggleBurgerMenu}>Activities</Link> */}
                            <div className="navbar-item has-dropdown is-hoverable">
                                <Link to="/activities" className="navbar-link" onClick={toggleBurgerMenu}>Activities</Link>

                                <div className="navbar-dropdown">
                                    <a href="/activities/templates" className="navbar-item">Manage Scheduled Templates</a>
                                    <a href="/activities/generate/create" className="navbar-item">Generate Activities</a>
                                    <hr className="navbar-divider" />
                                    <a href="/activities/create" className="navbar-item">Create Single Activity</a>
                                </div>
                            </div>

                            <Link to="/farms" className="navbar-item" onClick={toggleBurgerMenu}>Farms & Plants</Link>

                            <Link to="/handbooks" className="navbar-item" onClick={toggleBurgerMenu}>Handbooks</Link>
                            {/* <div className="navbar-item has-dropdown is-hoverable">
                                <Link to="/handbooks" className="navbar-link" onClick={toggleBurgerMenu}>Handbooks</Link>

                                <div className="navbar-dropdown">
                                    <Link to="/handbooks/tools" className="navbar-item" onClick={toggleBurgerMenu}>View Tools</Link>
                                    <hr className="navbar-divider" />
                                    <Link to="/handbooks/section/create" className="navbar-item" onClick={toggleBurgerMenu}>Create Section</Link>
                                </div>
                            </div> */}
                            
                            <Link to="/alerts" className="navbar-item" onClick={toggleBurgerMenu}>Alerts</Link>

                            

                            {/* <div className="navbar-item has-dropdown is-hoverable">
                                <Link to="/activities" className="navbar-link" onClick={toggleBurgerMenu}>Activities2</Link>

                                <div className="navbar-dropdown">
                                    <a href="/activities/templates" className="navbar-item">Templates</a>

                                    <hr className="navbar-divider" />

                                    <a href="/#" className="navbar-item">Report an issue</a>
                                </div>
                            </div> */}
                        </div>

                        <div className="navbar-end">
                            <div className="navbar-item">
                                <div className="buttons">
                                    <button className="button is-primary" onClick={handleLogout}>
                                        Logout
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </div>
    )
}

export default Header