import React, { useState } from "react";
import NotificationError from "../../components/NotificationError/NotificationError"

function StepReportCallus(props) {

    const callusSelection = props.callusSelection;

    const [message, setMessage] = useState()
    const [isAboveTen, setIsAboveTen] = useState(false);
    const [invalid, toggleInvalid] = useState()

    const handleNumberReportedCallus = (e) => {
        e.target.value ? toggleInvalid(false) : toggleInvalid(true)
        props.setNumberCallusReported(e.target.value);
        props.jump(3)
    };

    return (
        <div className="container">
            <p>&nbsp;</p>
            <NotificationError setMessage={setMessage} message={message} />
            <h1 className="title">Report Callus</h1>
            <div className="columns is-centered">
                <div className={`column ${`is-offset-3`}`}>
                    {
                        callusSelection.map((result, index) => {
                            return (
                                <div key={index} className="columns is-variable is-3">
                                    {result.map((val) => {
                                        return (
                                            <div key={val.value} className="column is-one-third">
                                                <div className="notification is-info is-light has-text-centered">
                                                    <button
                                                        key={index}
                                                        className="button is-info is-large"
                                                        value={val.value}
                                                        onClick={() => {
                                                            props.setNumberCallusReported(val.value)
                                                            props.jump(3)
                                                        }
                                                        }
                                                    >
                                                        {val.value}
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })
                    }
                </div>
                <div className="column">
                    <div className="columns is-1">
                        <div className="column is-one-third">
                            {!isAboveTen && <div className="has-text-centered">
                                <button
                                    className="button is-info is-medium"
                                    value={'10+'}
                                    onClick={() => setIsAboveTen(true)}
                                >
                                    + Add more
                                </button>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            {
                isAboveTen && !invalid && <article className="message is-warning">
                    <div className="message-body">
                        Numeric valus only
                    </div>
                </article>
            }
            {
                isAboveTen && invalid && <article className="message is-danger">
                    <div className="message-body">
                        Numeric valus only
                    </div>
                </article>
            }

            {isAboveTen && <div className="content">
                <input type="number" className="input" name="numShoots"
                    onChange={handleNumberReportedCallus}
                />
            </div>}

            <div className="content">
                <h1>{props.state.numShoots}</h1>
            </div>

            <div className="buttons">
                <button className="button is-info is-light" onClick={() => props.jump(2)}>
                    Previous
        </button>
                <button className="button is-info is-light" onClick={props.next} disabled={!props.state.numShoots}>
                    Next
        </button>
            </div>
        </div>
    );
}

export default StepReportCallus;
