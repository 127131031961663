import React, { useState } from 'react'
import DataDisplay from '../DataDisplay/DataDisplay'
import NotificationSuccess from "../NotificationSuccess/NotificationSuccess"

const LabsManualConfirmationPage = ({ editData, finalData, successMessage }) => {

    const [apiResponse, setApiResponse] = useState(successMessage)
    return (
        <div>
            {editData &&
                <>
                    <NotificationSuccess setMessage={setApiResponse} message={apiResponse} />
                    <DataDisplay
                        label={'New Batch Number'}
                        data={editData.new_batch_no}
                    />
                    <DataDisplay
                        label={'Old Batch Number'}
                        data={editData.old_batch_no}
                    />
                    <DataDisplay
                        label={'Inoculator'}
                        data={editData?.name_inoculator_name}
                    />
                    <DataDisplay
                        label={'Current Stage'}
                        data={editData.current_stage}
                    />
                    <DataDisplay
                        label={'Mother Plant Source(New)'}
                        data={editData?.mother_plant_source_name}
                    />
                    <DataDisplay
                        label={'Barcode Number'}
                        data={editData.plant_bottle_code}
                    />
                </>}

            {finalData &&
                <>
                    <NotificationSuccess setMessage={setApiResponse} message={apiResponse} />
                    <div className="columns">
                        <div className="column">
                            <DataDisplay
                                label={'Bottle'}
                                data={finalData.plant_bottle_code}
                            />
                            <DataDisplay
                                label={'Mother Plant Source ID'}
                                data={finalData.mother_plant_source}
                            />
                            <DataDisplay
                                label={'Batch'}
                                data={finalData.new_batch_no}
                            />
                            <DataDisplay
                                label={'Ex Plant'}
                                data={finalData.ex_plants}
                            />
                            {/* <DataDisplay
                                label={'Registration Week'}
                                data={finalData.registrationWeek}
                            /> */}
                            {/* <DataDisplay
                                label={'Registration Date'}
                                data={finalData.registrationDate}
                            /> */}
                            <DataDisplay
                                label={'Next Transfer Week'}
                                data={finalData.next_transfer_week}
                            />
                        </div>

                        {/* <div className="column">
                            <h4>Ex Plant Info:</h4>
                            {finalData.pciInfo.map((explant) => {
                                return (
                                    <DataDisplay
                                        label={'PCI'}
                                        data={explant}
                                    />
                                )
                            })}
                        </div> */}
                    </div>
                </>}

        </div>
    )
}

export default LabsManualConfirmationPage
