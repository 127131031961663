import React, { useState, useEffect } from 'react'
import LabsSearchBar from '../../components/Labs/LabsSearchBar'
import LabsTableView from '../../components/Labs/LabsTableView'

import retrieveManualUpdateListFiltered from "../../services/plants/retrieveManualUpdateListFiltered"
import retrieveManualUpdateList from "../../services/plants/retrieveManualUpdateList"

const StepPlantDisplay = (props) => {

    const placeholders = ['Old Batch Number', 'Plant Source (New)', 'Inoculator', 'Date']
    const tableHeaders =
        ['', 'Date of Inoculation',
            'Name of Inoculator',
            'Lab Aid',
            'Laminar Flow',
            'Lab Protocol',
            'Mother Plant Source (New)',
            'Source of Vanilla (Plant code)',
            'Nursery Protocol',
            'Field Type',
            'Old Batch No./Sub No.',
            'New Batch number',
            'No. of Source (Young Shoots)',
            'Current Stage'
        ]
    const [inoculationDate, setInoculationDate] = useState(undefined)
    const [oldBatchNum, setOldBatchNum] = useState(undefined)
    const [motherPlantSource, setMotherPlantSource] = useState(undefined)
    const [inoculatorName, setInoculatorName] = useState(undefined)
    const [newBatchNumber, setNewBatchNumber] = useState(undefined)

    const [displayItems, setDisplayItems] = useState()

    const [loading, toggleLoading] = useState(true)

    const [displayText, setDisplayText] = useState('Loading..')

    const [totalRecordCount, setTotalRecordCount] = useState()
    const [displayCount, setDisplayCount] = useState()

    const retrieveList = async () => {
        let manualUpdateList = await retrieveManualUpdateList()
        manualUpdateList?.length > 0 ? setDisplayItems(manualUpdateList.slice(0, 10)) : setDisplayText('No data retrieved')
        setTotalRecordCount(manualUpdateList?.length)

        if (manualUpdateList?.length < 10) {
            setDisplayCount(manualUpdateList?.length)
        }
        else {
            setDisplayCount(10)
        }

        toggleLoading(false)
    }

    useEffect(() => {
        const retrieveFiltered = async () => {
            let filteredData = await retrieveManualUpdateListFiltered(inoculationDate, oldBatchNum, motherPlantSource, inoculatorName, newBatchNumber)

            if (filteredData?.length > 0) {
                setTotalRecordCount(filteredData?.length)
                setDisplayItems(filteredData)

                if (filteredData?.length < 10) {
                    setDisplayCount(filteredData?.length)
                }
                else {
                    setDisplayCount(10)
                }
            }
            else {
                console.log('qq')
                setDisplayItems(null)
                setDisplayCount(0)
                setTotalRecordCount(0)
                setDisplayText('No data retrieved')
            }

            toggleLoading(false)

        }
        if (inoculationDate || oldBatchNum || motherPlantSource || inoculatorName || newBatchNumber) {
            toggleLoading(true)
            setDisplayText('Loading...')
            retrieveFiltered();
        }
        else {
            retrieveList();
        }
    }, [inoculationDate, oldBatchNum, motherPlantSource, inoculatorName, newBatchNumber])
    return (
        <div>
            <LabsSearchBar
                inputPlaceholders={placeholders}
                setInoculationDate={setInoculationDate}
                setOldBatchNum={setOldBatchNum}
                setMotherPlantSource={setMotherPlantSource}
                setInoculatorName={setInoculatorName}
                setNewBatchNumber = {setNewBatchNumber}
            />
            <br></br>
            {!loading &&
                <h2 className = "subtitle">Currently Displaying {displayCount} of {totalRecordCount} records</h2>
            }
            <LabsTableView
                type = 'manual'
                displayText={displayText}
                setSelectedData={props.setSelectedData}
                next={props.next}
                loading={loading}
                headers={tableHeaders}
                data={displayItems} />
        </div>
    )
}

export default StepPlantDisplay
