import React from 'react';
import Modal from 'react-modal';

const ModalComponent = ({ display }) => {

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <Modal
            isOpen={true}
            style={customStyles}
            ariaHideApp={false}
        >
            <h2>{display}</h2>
        </Modal>
    )
}

export default ModalComponent
