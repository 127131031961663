import React from 'react'

const NotificationSuccess = ({setMessage, message}) => {

    return (
        <div className="notification is-success" style={!message ? { display: 'none' } : { display: 'block' }}>
            <button className="delete" onClick={() => setMessage(null)}></button>
            Success: {message}
        </div>
    )
}
export default NotificationSuccess