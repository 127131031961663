import React from 'react'
import LabsManualConfirmationPage from '../../components/Labs/LabsManualConfirmationPage'
import Button from '../../components/Button/Button'
import manualUpdateConfirmation from '../../services/plants/manualUpdateConfirmation'


const StepManualConfirmationPage = (props) => {

    const editData = props.selectedData

    const editConfirmation = async () => {
        console.log(editData)
        let confirmRequest = await manualUpdateConfirmation(editData)
        props.setFinalData(confirmRequest)
        props.next()
    }

    return (
        <div>
            <LabsManualConfirmationPage editData={editData} successMessage={'Info has been updated'} />
            <p>&nbsp;</p>
            <div className="buttons">
                <Button
                    onClick={() => {
                        editConfirmation()
                    }}
                    label={'Confirm Info'}
                    className={'button is-primary'}
                />
                <Button
                    onClick={props.prev}
                    label={'Back'}
                    className={'button is-primary'}
                />
            </div>
        </div>
    )
}

export default StepManualConfirmationPage
