import React, { useState, useEffect } from 'react'
import getActivitiesLogByUser from "../../services/activities/log/getActivitiesLogByUser"
import Moment from 'react-moment';
import './ActivitiesLog.scss'

const RetrieveByFarmUser = (props) => {

    console.log("DEBUG///XXX;", props)

    const [results, setResults] = useState([])
    const [loadedData, setLoadedData] = useState(false)
    
    const [farmId] = useState(props.location.state.farmId);
    const [userHash] = useState(props.location.state.userHash);

    useEffect(() => {
        const getLogs = async () => {
            let result = await getActivitiesLogByUser(farmId, userHash)
            setResults(result)
            setLoadedData(true)
        }

        getLogs()
    }, [farmId, userHash]);

    return (
        <div className="container">

            {/* <Button color="primary">My Bulma button</Button> */}

            <p>&nbsp;</p>

            <h1 className="title">Activities | Logs</h1>
            <h2 className="subtitle">View action logs</h2>

            <nav className="level">
                <div className="level-right">
                    {/* <p className="level-item"><a href="/#" className="button is-success">New Single Activity</a></p>
                    <p className="level-item">
                        <a href="/#" className="button is-success">Generate Activities</a>
                    </p> */}

                    {/* <p className="level-item"><strong>All</strong></p>
                    <p className="level-item"><a href="/#">Active</a></p>
                    <p className="level-item"><a href="/#">Pending</a></p>
                    <p className="level-item"><a href="/#">Completed</a></p> */}
                </div>

                <div className="level-left">
                    <div className="level-item">
                        <p className="subtitle is-5">
                            <strong>x</strong> records
                        </p>
                    </div>
                    <div className="level-item">
                        <div className="field has-addons">
                            <p className="control">
                                <input className="input" type="text" placeholder="Find activity" />
                            </p>
                            <p className="control">
                                <button className="button">
                                    Search
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </nav>

            <div className='list'>
                <ul>
                    {loadedData === true ?
                        results.map((result, index) => {
                            return (
                                <div className='list-item' key={index}>
                                    <div className="box">
                                            <article className="media">
                                                <div className="media-left">
                                                    <div className="buttons">
                                                        <a href="/#" className="button is-link">View</a>
                                                    </div>
                                                    
                                                </div>
                                                <div className="media-content">
                                                    <div className="content">
                                                        <p>
                                                            <strong>ID: {result.id}</strong>
                                                            <br />
                                                            <small>
                                                                <p>{result.remarks}</p>
                                                                Activity Id: {result.activities_id}<br />
                                                                Duration: {result.stopwatch}<br />
                                                                Created: <Moment durationFromNow ago>{result.date_created}</Moment><br />
                                                            </small>
                                                        </p>
                                                    </div>
                                                    <nav className="level is-mobile">
                                                        <div className="level-left">
                                                        </div>
                                                    </nav>
                                                </div>
                                                <div className="media-right">
                                                    <figure className="image is-64x64">
                                                        <img src="https://bulma.io/images/placeholders/128x128.png" alt="Profile" />
                                                    </figure>
                                                </div>
                                            </article>
                                        </div>

                                </div>
                            )
                        }) : <div className="sections"><div className="section-header">Loading..</div></div>}
                </ul>
            </div>



        </div>
    )
}

export default RetrieveByFarmUser
