import React, { useState, useEffect } from 'react'
import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"
import NotificationError from "../../components/NotificationError/NotificationError"
import checkBottle from '../../services/plants/bottles/getBottleByIdentifier'

function StepContinueRegistration(props) {
    const [data, setData] = useState(!props?.registerAgain && props.state.exPlants ? props.latestPlant.code : '');
    const [apiResponse, setApiResponse] = useState('')
    const [reScan, toggleRescan] = useState(false)
    const [failedScan, setFailedScan] = useState(false)
    const [scanning, toggleScanning] = useState(false)

    console.log('code', data)

    useEffect(() => {
        if (props.didGoBack) {
            if (document.getElementById('scanResultArea')) {
                document.getElementById('scanResultArea').style.display = 'block'
            }
            if (document.getElementById('barcode')) {
                document.getElementById('barcode').setAttribute('type', 'text')
                document.getElementById('barcode').focus()
            }
        }
    }, [props.didGoBack])

    useEffect(() => {
        window.addEventListener('keydown', e => {
            if (document.getElementById('scanResultArea')) {
                document.getElementById('scanResultArea').style.display = 'block'
            }
            if (document.getElementById('barcode')) {
                document.getElementById('barcode').setAttribute('type', 'text')
                document.getElementById('barcode').focus()
            }
        })
    }, [])

    const checkBottleCode = async (code) => {

        let res = await checkBottle(code)
        console.log('barcode scan result', res)
        if (res) {
            if (res[0].bottle.status === 'not-active') {
                setFailedScan(false)
                toggleScanning(false)
                props.setValidBottleCode(code)
                props.next()
            }
            else {
                setApiResponse('Barcode already used')
                setFailedScan(true)
                toggleScanning(false)
                console.log('invalid data')
            }
        }
        else {
            setApiResponse('Barcode not found')
            setFailedScan(true)
            toggleScanning(false)
            console.log('invalid data')
        }
    }

    const rescanNewCode = () => {
        checkBottleCode(props.latestPlant.code)

    }
    useEffect(() => {

        if (!props.didGoBack) {
            if (data) {
                toggleScanning(true)
                setApiResponse(null)
            }
            const delayDebounceFn = setTimeout(() => {
                if (data) {
                    checkBottleCode(data)
                }
            }, 500)

            return () => clearTimeout(delayDebounceFn)
        }
        // eslint-disable-next-line  
    }, [data, props.didGoBack])


    const divStyle = {
        display: 'none'
    };
    return (
        <div className="content container">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            {scanning && <h4>Barcode scanning, please wait...</h4>}
            {apiResponse === 'Successfully scanned barcode' ? <NotificationSuccess setMessage={setApiResponse} message={apiResponse} /> : <NotificationError setMessage={setApiResponse} message={apiResponse && `${apiResponse} [${data}]`} />}
            {
                (failedScan || props.didGoBack) &&

                <div className="columns is-centered">
                    <button className="button is-info is-large"
                        onClick={() => {
                            toggleRescan(!reScan)
                            setFailedScan(!failedScan)
                            setData('')
                            setApiResponse(null)
                            props.setGoBack(false)
                            document.getElementById('barcode').focus()
                        }
                        }
                    // disabled={stepDone}
                    >Scan Again</button>
                </div>
            }
            <h4 className="title is-4 has-text-centered">Scan Barcode Using Barcode Scanner</h4>
            <div id="scanResultArea" style={divStyle}>
                <p>Scanned Code: <input autoFocus value={data} id="barcode" onChange={(e) => setData(e.target.value)} className="input" type="hidden"></input></p>
                <div>
                    <button className="button is-info is-light is-large" onClick={() => {
                        props.prev()
                    }}>Previous</button>

                    {props.didGoBack && <button className="button is-info is-light" onClick={rescanNewCode}>Confirm</button>}
                </div>
            </div>

        </div>
    );
}

export default StepContinueRegistration;