import axios from "axios";

const retrievePendingUpdatePlants = async (userHash) => {
    console.log("DEBUG/retrievePendingPlants:")
    const URL = process.env.REACT_APP_API_ADMIN_PLANTS_BATCH_CANCEL
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_HOST + URL + "pending-update/" + userHash, {
            headers: {
                'Authorization': 'Bearer 4b58c6b1d51f0b45c5426d250324a6c49bb81c5e893b877ebe917f8bf5098a17'
            }
        }).then(async res => {
            console.log("service.retrievePendingPlants():")
            console.log(res)
            var result = res.data.data
            resolve(result)
        }).catch(err => {
            console.log("service.retrievePendingPlants():")
            console.log(err)
            resolve(false)
        })
    })
}

export default retrievePendingUpdatePlants