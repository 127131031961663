import React, { useState, useEffect } from 'react'
import getFarmUsersList from '../../services/farms/getFarmListByUser'
import deleteFarm from '../../services/farms/deleteFarm'
import { Link } from "react-router-dom";

import NotificationError from "../../components/NotificationError/NotificationError"
import NotificationSuccess from "../../components/NotificationSuccess/NotificationSuccess"

const FarmListByUser = (props) => {
    const { userHash } = props.match.params;
    const [farms, setFarms] = useState()
    const [loadedData, setLoadedData] = useState(false)
    const [apiResponse, setApiResponse] = useState("");

    const getFarmLists = async (userHash) => {
        let result = await getFarmUsersList(userHash)
        // setResults(result)
        // setLoadedData(true)
        console.log('these are the farms', result)
        setFarms(result)
        setLoadedData(true)
    }

    useEffect(() => {

        getFarmLists(userHash)
    }, [userHash]);
    return (
        <div className="container">
            <p>&nbsp;</p>
            <h1 className="title">Farms | By User</h1>
            <h2 className="subtitle">View farms by user</h2>

            {apiResponse === "success" && <NotificationSuccess setMessage={setApiResponse} message="Successfully processed your request" />}
            {apiResponse === "error" && <NotificationError setMessage={setApiResponse} message="There was an error processing your request." />}

            <nav className="level">
                <div className="level-right">
                    <p className="level-item">
                        <Link to={{
                            pathname: '/farms/create',
                            state: { userHash: userHash }
                        }} className="button is-success">New Farm</Link>
                    </p>

                    <p className="level-item"><strong>All</strong></p>
                    <p className="level-item"><a href="/#">Active</a></p>
                    <p className="level-item"><a href="/#">Disabled</a></p>
                </div>
                <div className="level-left">
                    <div className="level-item">
                        <p className="subtitle is-5">
                            <strong>{farms?.length}</strong> records
                        </p>
                    </div>
                    <div className="level-item">
                        <div className="field has-addons">
                            <p className="control">
                                <input className="input" type="text" placeholder="Find activity" />
                            </p>
                            <p className="control">
                                <button className="button">
                                    Search
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </nav>
            <div className='list'>
                <ul>
                    {loadedData === true ?
                        farms.map((farm) => {
                            return (
                                <div className='list-item' key={farm.id}>
                                    <div className="box">
                                        <article className="media">
                                            <div className="media-left">
                                                <div className="buttons">
                                                    <Link to={{
                                                        pathname: '/farms/update/' + userHash,
                                                        state: { farm }
                                                    }} className="button is-link">Update</Link>
                                                    <button className="button is-danger"
                                                        onClick={async () =>
                                                            await deleteFarm(farm.id).then(res => {
                                                                setLoadedData(false)
                                                                console.log(res)
                                                                if (res) {
                                                                    if (res.status === 200) {
                                                                        setApiResponse("success")
                                                                    } else {
                                                                        setApiResponse("error")
                                                                    }

                                                                    getFarmLists(userHash)
                                                                }
                                                            })
                                                        }
                                                    >Delete</button>
                                                </div>

                                            </div>
                                            <div className="media-content">
                                                <div className="content">
                                                    <p>
                                                        <strong>{farm.farm_name}</strong>
                                                        <br />
                                                        <small>
                                                            Square Footage: {farm.square_footage ? farm.square_footage : `N/A`}<br />
                                                                Years Operation: {farm.years_operation ? farm.years_operation : `N/A`}<br />
                                                                Soil Type: {farm.soil_type ? farm.soil_type : `N/A`}<br />
                                                                Irrigated: {farm.irrigated ? farm.irrigated : `N/A`}<br />
                                                                Drainage: {farm.drainage ? farm.drainage : `N/A`}<br />
                                                                Ownership: {farm.ownership ? farm.ownership : `N/A`}<br />
                                                                Selected: {farm.selected === 1 ? 'Yes' : 'No'}<br />
                                                                Weather: {farm.weather_current_summary ? farm.weather_current_summary : `N/A`}<br />
                                                        </small>
                                                    </p>
                                                </div>
                                                <nav className="level is-mobile">
                                                    <div className="level-left">
                                                    </div>
                                                </nav>
                                            </div>
                                            <div className="media-right">
                                                <figure className="image is-64x64">
                                                    <img src="https://bulma.io/images/placeholders/128x128.png" alt="Profile" />
                                                </figure>
                                            </div>
                                        </article>
                                    </div>

                                </div>
                            )
                        }) : <div className="sections"><div className="section-header">Loading..</div></div>}
                </ul>
            </div>
        </div>
    )

}


export default FarmListByUser